@import '../../marketplace.css';

.root {
    /* Adding empty .root class to enforce className overwrite */
    width: 600px;

    @media (--viewportMobile) {
        width: auto;
    }
}

.scrollLayer {
    background-color: #0000008f !important;
}

.modalContainer {
    /* margin: 55px 60px 55px 60px; */
}

.closeButtonClassName {
    color: #dedcdc;
}

.closeButtonClassName:enabled:hover {
    color: #fff;
}

.header {
    /* top: 281px;
    left: 660px; */
    /* width: 600px;
    height: 150px;
    opacity: 1; */
    /* top: 281px; */
}

.innerContainer {
    margin-top: 22%;
}

.headerBackground {
    position: absolute;
    top: 0;
    left: 0;
}

.title {
    font-size: 30px;
    color: var(--marketplaceColorDark);
    margin-bottom: 10px;
    margin-top: 0px;

    @media (--viewportMobile) {
        font-size: 28px;
    }
}

.description {
    font-weight: 100 !important;
    margin: 24px 0 0;
}

.inputLabel {}

.inputPlaceholder {}

.buttonContainer {}

.postalCodeWrapper {
    margin: 10px 0px 30px 0px;
}

.input {}

.button {
    flex: 1;
    border: 1px solid #ffcd05;
    height: 44px;
    font-size: 16px;
    color: #026786;
    background: #ffcd05;
    border-radius: 4px;
    cursor: pointer;
    text-transform: none;

    @media (max-width: 1024px) {
        height: 34px;
        font-size: 14px;
    }
}

.form {
    margin-top: 20px;
}