@import '../../marketplace.css';

.root {}

.titleContainer {
  color: #00a3ad;
  display: flex;
  padding: 20px !important;
  flex-direction: column;
  border: 1px solid #E6E6E6;
  border-radius: 5px;

  @media (--viewportMedium) {
    padding: 0px;
  }

  @media (--viewportMobile) {
    border: 0px;
    font-size: 24px;
    padding: 0px !important;
  }
}

.titleContainer h3 {
  text-align: left;
}

.titleStatusBox {
  /* margin: auto; */
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    margin-left: 15px;
  }
}

.statusBarVerified {
  background-color: var(--marketplaceColorDark);
  border: none;
  color: var(--matterColorLight);
  border-radius: 10px;
  padding: 5px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.statusBarPending {
  background-color: #00a3ad;
  border: none;
  color: var(--matterColorLight);
  border-radius: 10px;
  padding: 5px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}


.statusBarNotVerified {
  background-color: var(--marketplaceSecondaryColor);
  border: none;
  color: var(--matterColorDark);
  border-radius: 10px;
  padding: 5px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.thankYouPage {
  max-width: 700px;
}

.thankYouPage h2 {
  color: var(--marketplaceColorDark);
}

.thankYouPage p {
  color: var(--marketplaceColorDark);
}

.sectionTitle {
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  margin: 0 0 10px;
  color: #026786;
}

.tripPageDocumentUploadFormRoot {
  padding: 0;
}

.fromTripPageContainer {
  border: 0px;
  padding: 0px !important;
}

.cardTitle {
  font-size: 24px;
  color: #026786;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  margin: 0 0 24px;
  padding: 0 0 20px;

  @media (--viewportMobile) {
    font-size: 16px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (--viewportMobile) {
    font-size: 24px;
    border: 0px;
    margin: 0;
    padding: 0;
  }
}

.progressBar {
  @media (--viewportMobile) {
    background-color: transparent;
  }
}

.progressBarItem:first-child {
  border-radius: 5px 0px 0px 5px;
}

.progressBarItem:last-child {
  border-radius: 0px 5px 5px 0px;
}

.progressBarItem {
  @media (--viewportMobile) {
    background-color: #00a3ad;
    border-color: #00a3ad !important;
    height: 15px;
    margin-right: 0px !important;
  }
}

.progressBarItemNotSelected {
  background-color: #e2f3f4;
  border-color: #e2f3f4 !important;

  @media (--viewportMobile) {
    background-color: #e2f3f4;
    border-color: #e2f3f4 !important;
  }
}

.progressBarItemDone {
  background-color: #00a3ad !important;
  border-color: #00a3ad !important;

  @media (--viewportMobile) {
    background-color: #00a3ad;
    border-color: #00a3ad !important;
  }
}

.cardTitle .submissionStatus {
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  color: #026786;
  background: #E6E6E6;
  border-radius: 4px;
  padding: 8px;
  display: block;
  min-height: 38px;

  @media (--viewportMedium) {
    font-size: 18px;
  }

  @media (--viewportMobile) {
    font-size: 16px;
    margin-top: 15px;
  }
}

.submissionStatusVisibility {
  visibility: visible;
  opacity: 1;

  @media (--viewportMobile) {
    visibility: hidden;
    opacity: 0;
    display: none !important;
  }
}

.stepsContainer {
  position: relative;
}

.submissionStatusMobile {
  visibility: hidden;
  display: none;
  font-size: 11px;
  position: absolute;
  right: 15px;
  top: 6px;
  color: #026786;

  @media (--viewportMobile) {
    visibility: visible;
    display: block;
  }
}

.verificationPage {
  padding-top: 0 !important;
}

.verificationPageMobile {
  min-height: auto;
}
