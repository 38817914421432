.carListingSection {
    display: flex;
    width: 100%;
    padding: 100px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background-color: #F4F6FA;

    @media only screen and (max-width: 768px) {
        padding: 50px 16px;
        gap: 24px;
    }
}

.showDesktop {
    @media only screen and (max-width: 960px) {
        display: none !important;
    }
}

.showMobile {
    display: none !important;

    @media only screen and (max-width: 960px) {
        display: block !important;
    }
}

.heading {
    color: #222;
    font-family: Inter;
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.1rem;
    letter-spacing: -0.1px;
    align-self: stretch;
    margin: 0;

    @media only screen and (max-width: 960px) {
        width: 17.65rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: -0.078px;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    gap: 40px;

    @media only screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.locations {
    display: flex !important;
    flex-direction: column; 
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    font-family: 'Inter';
    padding:  0px;
    /* background-color: red; */

    @media only screen and (max-width: 960px) {
        display: flex;
        flex-direction: row !important ;
        /* width: fit-content; */
        align-items: center;
        gap: 8px;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        /* padding: 0 16px; */
        align-self: stretch;
        height: 60px;
        /* background: linear-gradient(90deg, rgba(244, 246, 250, 0.00) 58.52%, #F4F6FA 100%); */
    }
}

.location {
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    color: #7C7C7C;
    background-color: white;

    @media only screen and (max-width: 960px) {
        display: flex;
        padding: 4px 14px;
        text-align: center;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border-radius: 54px;
        flex: 0 0 auto;
        scroll-snap-align: center;
        height: fit-content;
        /* min-width: 150px; */
    }
}

.location:hover {
    transform: scale(1.02);
}

.activeLocation {
    background-color: #00A3AD;
    color: #ffffff;

   
}

.locationName {
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 38px;
    letter-spacing: -0.078px;

    @media only screen and (max-width: 960px) {
        font-size: 11px;
        line-height: 28px;
        letter-spacing: -0.078px;
    }
}

.locationCars {
    text-align: right;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.078px;

    @media only screen and (max-width: 960px) {
       display: none;
    }
}

.carList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 40px;
    width: 100%;

    @media only screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.carCard {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: flex-start; */
    /* align-self: stretch; */
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    }
}

.cardImage {
    width: 100%;
    

    @media only screen and (max-width: 960px) {
        height: 230px;
        align-self: stretch;
    }
}

.carImage {
    width: 100%;
    height: 230px;
    align-self: stretch;
    object-fit: cover;

    @media only screen and (max-width: 960px) {
        height: 230px;
        flex-shrink: 0;
        border-radius: 20px 20px 0px 0px;
    }
}

.carDetails {
    display: flex;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    @media only screen and (max-width: 960px) {
        border-radius: 0px 0px 20px 20px;
        background: #FFF;
    }
}

.carInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;

    @media only screen and (max-width: 960px) {
        /* width: 330px; */
    }
}

.carNameSection {
    display: flex;
    width: 239.067px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;

    @media only screen and (max-width: 960px) {
        flex-shrink: 0;
    }
}

.carName {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.078px;
    align-self: stretch;
    margin: 0px;
}

.carLocation {
    color: #222;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
    letter-spacing: -0.078px;
    align-self: stretch;
    margin: 0px;
    opacity: 0.6;
}

.carRating {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4px;
    width: 100%;
    height: 24px;
    font-weight: 400 !important;

    @media only screen and (max-width: 960px) {
        /* width: 48px; */
        height: 46px;
        flex-shrink: 0;
        flex-wrap: wrap;
    }
}

.starImage {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.ratingText {
    display: flex;
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.078px;
}

.tripText {
    color: #222;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.078px;
    opacity: 0.6;
}

.carPrice {
    width: 330px;
    color: #222;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px;
    letter-spacing: -0.078px;
    margin: 0px;
}

.buttonContainer {
    text-align: center;
}

.btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: 960px) {
        margin-top: 0px;
    }
}

.btnWrapper button {
    text-align: center;

    @media only screen and (max-width: 960px) {
        max-width: 100%;
        width: 100%;
    }
}

.btn {
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    padding: 12px 15px;
    text-transform: initial;
    border-radius: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 188px;
    height: 48px;
    min-height: 48px;
    width: 100%;
    gap: 4px;
    max-width: 100%;
    font-family: 'Inter', sans-serif;
    transition: 0.3s all cubic-bezier(0.18, 0.52, 0.49, 0.85);
    color: #026786;
    cursor: pointer;
}

.btnPrimary {
    background: #FFC60F;
    border: 1px solid #ffcd2d;
}

.btnPrimary:hover,
.btnPrimary:focus {
    background: #F8CE47;
}


  .cardAspectWrapper {
    background: var(--matterColorNegative); /* Loading BG color */
  }



  .cardAspectWrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* This creates a 16:9 aspect ratio */
    background: var(--matterColorNegative);
    overflow: hidden;
  }



 



  
.carCard {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

/* Slider container styles */
.carCard :global(.slick-slider) {
    width: 100%;
}

.carCard :global(.slick-list) {
    height: 100%;
    width: 100%;
}

.carCard :global(.slick-track) {
    height: 100%;
    width: 100%;
    display: flex;
}

.carCard :global(.slick-slide) {
    width: 100% !important;
}

.carCard :global(.slick-slide > div) {
    height: 100%;
    width: 100%;
}

/* Image styles */
.cardRootForImage {
    /* width: 100%;
    height: 100%;
    object-fit: cover; */
    /* display: block !important; */
}

/* ResponsiveImage container styles */
.cardRootForImage > div {
    /* width: 100%;
    height: 100%; */
}

.cardRootForImage img {
    width: 100%;
    height: 230px;
    /* object-fit: contain; */
}

/* Navigation dots */


.carCard :global(.slick-dots) {
  bottom: 16px;
  z-index: 1;
}

.carCard :global(.slick-dots li) {
  margin: 4px;
  width: 12px;
  height: 12px;
}

.carCard :global(.slick-dots li button) {
  width: 12px;
  height: 12px;
}

.carCard :global(.slick-dots li button:before) {
  color: white !important;
  background-color: white !important;
  opacity: 0.6;
  font-size: 6px;
  width: 12px;
  height: 12px;
  line-height: 16px;
}

.carCard :global(.slick-dots li.slick-active button:before) {
  color: white !important;
  background-color: white !important;
  opacity: 1;
}

.slick-dots li button {
    color: white !important;
    background-color: white !important;

}


/* Arrow navigation */


.imgContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 230px;
  }

  


.imgWrapper {
    position: relative;
    width: 100%;
    display: block;
    /* padding-bottom: 83.5%; */
    height: 230px;
    max-height: 230px;
    overflow: hidden;
  
    @media only screen and (max-width: 767px) {
      padding-bottom: 70%;
    }
  }



.noImageDisplayContainer {
    background: #e7e7e7;
  }

  .noImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e7e7e7;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 230px;
  }

  .noImageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .noImageWrapper img {
    width: auto !important;
  }
  
  .noImageText {
    @apply --marketplaceH6FontStyles;
    color: var(--matterColor);
    padding-bottom: 24px;
  
    margin-bottom: 0;
  
    @media (--viewportMedium) {
      margin-bottom: 0;
    }
  }