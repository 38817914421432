@import '../../marketplace.css';

.root {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBGMobile.jpg');
  background-image: image-set(url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBGMobile.jpg') 1x,
      url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBGMobile@2x.jpg') 2x);
  background-size: cover;
  min-height: 1024px;

  @media (--viewportLarge) {
    min-height: 875px;
  }

  @media (--viewportMLarge) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBG@2x.jpg');
    background-image: image-set(url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBG.jpg') 1x,
        url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBG@2x.jpg') 2x);
    background-size: cover;
    min-height: 750px;
  }

  @media (--viewportMobile) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBG@2x.jpg');
    background-image: image-set(url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBG.jpg') 1x,
        url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/heroBG@2x.jpg') 2x);
    background-size: cover;
    min-height: 750px;
  }
}

.content {
  padding: 20px;
}

.heroImage {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHeroMobile@2x.png');
  background-image: image-set(url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHeroMobile.png') 1x,
      url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHeroMobile@2x.png') 2x);
  background-size: contain;
  min-height: 198px;
  margin-top: calc(var(--topbarHeight) + 10px);

  @media (--viewportMedium) {
    width: 420px;
    margin: calc(var(--topbarHeight) + 10px) auto 0;
    background-size: cover;
  }

  @media (--viewportMLarge) {
    display: none;
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHero@2x.png');
    background-image: image-set(url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHero.png') 1x,
        url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHero@2x.png') 2x);
    background-size: contain;

    width: 580px;
    max-width: unset;
    min-height: 400px;

    margin: unset;
  }

  @media (--viewportXLarge) {
    width: 900px;
    height: 580px;
  }
}

.heroImageDesktop {
  display: none;

  @media (--viewportMLarge) {
    display: block;
  }
}

.heroContent {
  display: flex;
  flex-direction: column;

  @media (--viewportMLarge) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: calc(var(--topbarHeight) + 80px) auto 0;
    max-width: 1120px;
  }

  @media (--viewportXLarge) {
    max-width: 1600px;
  }
}

.flexLogo {
  width: auto;
  height: 52px;
  max-width: 146px;
}

.flexLogoSmall {
  width: auto;
  height: 24px;
  max-width: 146px;
  margin-top: 10px;

  @media (--viewportMLarge) {
    height: 28px;
  }
}

.sectionHeroTexts {
  @media (--viewportMLarge) {
    max-width: 600px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  font-size: 38px;
  line-height: 44px;
  color: var(--matterColorLight);
  letter-spacing: 0;
  margin: 10px 0 0;

  @media (--viewportMLarge) {
    font-size: 64px;
    line-height: 70px;
  }
}

.description {
  @apply --marketplaceDefaultFontStyles;
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColorLight);
  letter-spacing: 0;
  margin-top: 20px;

  @media (--viewportMLarge) {
    font-size: 30px;
    line-height: 38px;
  }
}

.findYourCarV1 {
  min-height: 48px;
  background-color: var(--marketplaceSecondaryColor);
  border-radius: 5px;
  @apply --marketplaceDefaultFontStyles;
  text-transform: none;
  color: var(--marketplaceColorDark);
  margin-top: 30px;
  margin-bottom: 40px;
  border-color: unset;

  @media (--viewportLarge) {
    max-width: 335px;
  }
}

.features {
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);

  @media (--viewportMLarge) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}

.feature {
  @apply --marketplaceDefaultFontStyles;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  color: var(--marketplaceColorDark);
  margin-bottom: 30px;

  &::before {
    content: '';
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/checkCircle.png');
    background-size: cover;
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMLarge) {
    margin-bottom: unset;
  }
}

.contactDescription {
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);
  letter-spacing: 0;
  margin: 0;

  @media (--viewportMobile) {
    margin-bottom: 30px;
  }
}

.emailInputWrapper {
  background-color: var(--matterColorLight);
  height: 48px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;

  @media (--viewportMLarge) {
    min-width: 400px;
  }

  @media (--viewportXLarge) {
    min-width: 500px;
  }
}

.emailInputWrapperActive {
  border: solid 1px var(--marketplaceSecondaryColor);
  box-sizing: border-box;
}

.emailInputWrapperError {
  border: solid 2px var(--failColor);
}

.emailInput {
  border: none;
  display: flex;
  flex-grow: 1;
  width: unset;
  margin-right: 5px;

  &:hover .emailInputWrapper {
    border: solid 1px var(--marketplaceSecondaryColor);
    background-color: red;
  }

  &:focus .emailInputWrapper {
    border: solid 1px var(--marketplaceSecondaryColor);
    background-color: red;
  }

  @media (--viewportMedium) {
    padding: unset;
  }
}

.contactBtn {
  background-color: var(--marketplaceSecondaryColor);
  width: 60px;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contactWrapper {
  @media (--viewportMLarge) {
    display: flex;
    flex-direction: row;
    max-width: 1120px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    &>div:first-child {
      flex-basis: 600px;
    }
  }

  @media (--viewportXLarge) {
    max-width: 1600px;

    &>div:first-child {
      flex-basis: 900px;
    }
  }
}

.heroContentV2 {
  margin-top: calc(var(--topbarHeight) + 40px);

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1024px;
    margin: calc(var(--topbarHeight) + 75px) auto 105px;

    &>div {
      flex-basis: 500px;
    }
  }

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
    margin: calc(var(--topbarHeight) + 75px) auto 105px;
  }
}

.findYourCarV2 {
  min-height: 48px;
  background-color: transparent;
  border-radius: 5px;
  @apply --marketplaceDefaultFontStyles;
  color: var(--marketplaceSecondaryColor);
  margin-top: 25px;

  @media (--viewportLarge) {
    border: solid 1px var(--marketplaceSecondaryColor);
    max-width: 335px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.scheduleACallWrapper {
  background-color: var(--marketplaceColor);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 30px 20px 40px;
  margin-top: 15px;

  @media (--viewportLarge) {
    display: none;
  }
}

.scheduleTitle {
  font-size: 32px;
  line-height: 36px;
  font-weight: var(--fontWeightBold);
  text-align: center;
  color: var(--matterColorLight);
}

.scheduleDescription {
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColorLight);
  margin: 20px 0;
}

.contactFieldWrapper {
  margin-bottom: 25px;
}

.contactField {
  min-height: 48px;
  background-color: var(--matterColorLight);
  border-radius: 4px;
  padding: 15px 20px;
  border: none;
  color: var(--marketplaceColorDark);

  &::placeholder {
    color: var(--marketplaceColorDark);
    opacity: 0.66;
  }
}

.contactButton {
  background-color: var(--marketplaceSecondaryColor);
  min-height: 48px;
  border-radius: 4px;
  border: none;
  text-transform: none;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);

  font-size: 18px;
  line-height: 18px;

  @media (--viewportLarge) {
    font-size: 20px;
  }
}

.scheduleDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    border-radius: 20px;
    box-shadow: 0 4px 12px;
  }
}

.errorText {
  position: unset;
  margin-top: 0;
}