@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      /* border-bottom: 4px solid var(--marketplaceColor); */
      border-radius: 0;
      text-decoration: none;
    }
  }
}

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);
  position: static;
  top: 0;
  left: 0;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: transparent;

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 21px 24px 21px 24px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 21px 36px 21px 36px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--marketplaceColor);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.noSearchBox {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;

  @media (min-width: 1024px) {
    padding-left: 0;
  }
}

.topbarSearchWithLeftPadding {
  /* padding-left: 24px; */
  padding-left: 0;
  height: var(--topbarHeightDesktop);
  display: none;

  @media (min-width: 1024px) {
    /* padding-left: 34px; */
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--matterColorBright);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxContainer {
  position: relative;
  height: 100%;
}

.inboxLabel {
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px;
  color: var(--matterColor);
  cursor: pointer;
font-size: 16px;
  &:hover {
    color: var(--matterColorDark);
  }
}

.inboxMenuContent {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 250px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  list-style-type: none;
}

.inboxContainer:hover .inboxMenuContent {
  display: block;
}
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuContainer {
  position: relative;
  height: 100%;
}
.profileMenuLabel {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px;
  cursor: pointer;
}
/* .profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
} */

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 276px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  list-style-type: none;
}
.profileMenuContainer:hover .profileMenuContent {
  display: block;
}

.profileMenuLogoutContent {
  min-width: 276px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.learnMoreMenuContent {
  min-width: 250px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  left: 0 !important;
  right: unset !important;
}

.aboutContent {
  display: none;
  position: absolute;
  top: 100%;
  left: -84px;
  min-width: 250px !important;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  list-style-type: none;
}

.aboutTab:hover .aboutContent {
  display: block;
}
/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.learnMoreLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  font-size: 16px;
  padding: 0 12px 0 12px;
  position: relative;
  color: var(--matterColorLight);
  &:hover {
    color: var(--marketplaceColorDark);
  }}
.aboutTab {
  position: relative;
}

.learnMoreLink span {
  display: block;
  margin: 24px 0px;
}

.signup,
.login {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.referralPageLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;
  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH4FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}
.hamburgerContainer {
  display: flex;
  align-items: center; 
}




.aboutContent ul {
  list-style-type: none; 
  padding: 0;
  margin: 0;
}

.aboutContent .menuItem {
  list-style-type: none; 
}
.TopbarDesktop_logoutButton{
  margin-bottom: 0px !important;
}