@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
  background: #F6F6F6;
  /* sticky result summary in mobile */
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 6px 20px 8px !important;
}

.searchResultSummary {
  @apply --marketplaceH3FontStyles;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;

  background-color: var(--matterColorBright);
  margin: 0;
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.filtersButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  height: 28px;
  min-height: 28px;
  padding: 0 18px;
  margin: 0 9px 0 0;
  border-radius: 4px;
  background: #FCFCFC;
  border: 1px solid #E6E6E6;
  box-shadow: none;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #026786;
  text-transform: capitalize;
  max-width: 120px;
    flex: 1;
    @media (--viewportMobile) {
      max-width: calc(33.333% - 9px);
    }
}
.filtersButton svg{
  margin-right: 5px;
}


.filtersButtonSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);
  background: #FCFCFC;
  border: 1px solid #E6E6E6;
  box-shadow: none;
  height: 28px;
  min-height: 28px;
  padding: 0 18px;
  margin: 0 9px 0 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #026786;
  text-transform: capitalize;
}

.sortBy {
  margin-right: 9px;
  max-width: 120px;
  flex: 1 1;
  @media (--viewportMobile) {
    max-width: calc(36.333% - 2px);
  }
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);
  background: #FCFCFC;
  border: 1px solid #E6E6E6;
  box-shadow: none;
  height: 28px;
  min-height: 28px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #026786;
  text-transform: capitalize;
  max-width: 100%;
  flex: 1;
  &:focus {
    border: 1px solid #00a3ad;
    background: rgba(0, 163, 173, 0.26);
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
}

.sortByMenuLabel svg{
  margin-right: 5px;
}

.mapIcon {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  /* background map image */
  background: #FCFCFC url(./images/map_icon216x105.png);
  background-size: cover;
  background: #FCFCFC;
  border: 1px solid #E6E6E6;
  box-shadow: none;
  border-radius: 3px;
  width: 72px;
  height: 28px;
  min-height: 28px;
  padding: 9px 22px;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #026786;
  text-transform: capitalize;
  max-width: 120px;
    flex: 1;
    @media (--viewportMobile) {
      max-width: 33.333%;
    }
}

.modalContainer {
  width: 100%;
  padding: 94px 20px;
  margin: 0;
  background: #FFFFFF;
  height: calc(100vh - 80px);
  overflow: auto;
}

.modalHeadingWrapper {
  padding-bottom: 31px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.modalHeading {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.resetAllButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  /* padding-bottom: 180px; */
  padding-bottom: 0;
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--matterColorLight);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}

.toggleSwitchClassName {
  width: 50%;
}

.filterElement {
  font-size: 16px;
  display: flex;
  /* height: 75px; */
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 0;
}

.plainClassName {
  font-size: 16px;
  margin-top: 22px;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
}

.filterCategoryElement {
  font-size: 16px;
}

.labelClassName {
  display: flex;
  font-size: 16px;
  color: var(--marketplaceColorDark);
  margin: 0;
  padding: 0;
  min-width: 100%;
  flex: 1;
}

.labelClassNameWithLimit {
  width: 202px;
  max-width: 202px;
  min-width: 202px;
}

.toggleSwitch {
  width: 52px;
  height: 30px;
}

.customSlider::before {
  width: 24px;
  height: 24px;
  bottom: 3px;
}

input:checked + .customSlider::before {
  transform: translateX(24px);
}

/* The distance between the last filter element and the bottom
edge of the mobile screen is 219px.
This rule is currently applied to the keyword filter  */
.lastMobileFilter {
  /* margin-bottom: 219px; */
  margin-bottom: 0;
}
.filtersAppliedNumbers{
  display: inline-block;
  margin-left: 4px;
}
.filtersSelected{
  border: 1px solid #00A3AD;
  background: #00A3AD26;
}
.isSorted{
  border: 1px solid #00A3AD;
  background: #00A3AD26;
}

.searchFiltersMobile {
  display: none;
  @media(max-width:767px){
    display: block;
  }
}
