@import '../../marketplace.css';

.root {
  background-color: #df492a;
  text-align: center;
  padding: 10px 20px 9px;
  @media (--viewportMobile) {
    padding: 3px 3px 2px;
  }
}

.text {
  margin: 0;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 20px;
  @media (--viewportMobile) {
    font-size: 10px;
    /* margin-bottom: 0px; */
  }
}

.spanButtonClass {
  cursor: pointer;
  background: #2a3d4b;
  /* margin: 0 16px; */
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  border: 0;

  @media (--viewportMobile) {
    /* margin: 0px;
    padding: 5px;
    margin-bottom: 4px;
    border-radius: 5px; */
    font-size: 10px;
    padding: 4px;
}
}

.button {
  cursor: pointer;
  background: #2a3d4b;
  margin: 0 16px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  border: 0;

  @media (--viewportMobile) {
    /* margin: 0px;
    padding: 5px;
    margin-bottom: 4px;
    border-radius: 5px; */
    font-size: 10px;
    padding: 4px;
  }

  &:hover {
    text-decoration: none;
    background: #364f61;
  }
}
