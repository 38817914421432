@import '../../marketplace.css';

.root {
  /* Layout */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    padding: 112px 50px 82px 50px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media screen and (min-width: 1200px) {
    padding: 112px 7vw 82px 7vw;
  }

  @media (--viewportXLarge) {
    padding-left: 25vw;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;
  text-align: center;

  @media (--viewportMedium) {
    min-height: 56px;
    padding-top: 10px;
  }

  @media (--viewportLarge) {
    text-align: left;
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }

  /* @media (--viewportMobile) { */
  @media screen and (max-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }

  /* @media (--viewportMobile) { */
  @media screen and (max-width: 1024px) {
    margin-left: 0 !important;
    height: 30px !important;
    align-items: center;
    justify-content: center;
  }
}

.customLink {
  color: var(--marketplaceColor) !important;
  font-weight: 100 !important;
  border-bottom-width: 0px !important;

  @media (--viewportLarge) {
    width: 260px !important;
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px !important;
    max-width: 250px !important;
    width: auto !important;
  }
}

.selectedClass {
  color: var(--marketplaceColorDark) !important;
  font-weight: bold !important;
}

.profileLink {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;

  display: block;
  align-self: center;
  width: auto;
  min-height: auto;
  height: 36px;
  width: 140px;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;

  flex-shrink: 0;

  margin: 10px 0px 10px 0px;
  padding: 8px;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin: 15px 0px 10px 0px;
    font-size: 24px;
    padding: 14px;
    height: 50px;
    width: 200px;
  }

  @media (--viewportLarge) {
    font-size: 30px;
    padding: 16px;
    height: 65px;
    position: static;
    display: inline-block;
    width: 100%;
    margin: 37px 0 0 0;
  }
}

.menuContainer {
  height: 54px;
  border: 1px solid #E6E6E6;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;

  @media screen and (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
  }

  @media screen and (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
}

.menuContent {
  z-index: 9999 !important;
}

.menuLabelTitleContainer {
  display: flex;
  flex-direction: row;
}

.menuLabelTitle {
  margin-right: auto;
  margin-left: 10px;
}

.menuLabelIcon {
  margin-left: auto;
  margin-right: 10px;
}

.arrowDown {
  margin-left: 10px;
}