hr {
  border: none;
  height: 1px;
  width: 100%;
  background: #E8E8E8; /* or any visible color */
  margin: 0; /* Adjust spacing if necessary */
}

.faqSection {
  width: 100%;
  height: 100%;
  padding: 100px 80px;
  background: #F4F6FA;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  display: inline-flex;

  @media only screen and (max-width: 767px) {
    padding: 50px 16px;
    gap: 24px;
  }
}

.sectionTitle {
  /* margin-bottom: 40px; */

  @media only screen and (max-width: 767px) {
    max-width: 267px;
  }
}

.sectionTitle h2 {
  font-family: Inter;
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 3.1rem;
  text-align: left;
  color: #222222;
  margin: 0;
  word-wrap: break-word;
  letter-spacing: -2px;

  @media only screen and (max-width: 767px) {
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: -0.078px;
  }
}


.faqGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  row-gap: 24px;
  column-gap: 40px; 
  width: 100%;
  height: auto;

  @media only screen and (max-width: 767px) {
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.faqItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
  background: #fff;
  padding: 24px 16px;
  border: 1px solid #E8E8E8;
  border-radius: 12px;
  cursor: pointer;
}

.faqQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.faqQuestion h4 {
  color: #222222;
  font-size: 1rem;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.4rem;
  word-wrap: break-word;
  margin: 0;
}

.answer{
  font-weight: 400 !important;
}

.faqAnswer p{
  color: #222222;
  font-size: 1rem;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 1rem;
  word-wrap: break-word;
}

.faqItem.active .faqAnswer {
  max-height: 300px;
}

.faqAnswer p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}