/* .outerContainer {
  border: solid 2px black;
} */

.currentStepHeader {
  /* border: solid 2px blue; */
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (--viewportMobile) {
    flex-direction: column-reverse;
  }
}

.currentStepName {
  /* border: solid 2px red; */
  font-size: 24px;
  color: #026786;
  font-weight: 700 !important;
}

/* .currentStepIndicator {
  border: solid 2px green;
} */

.separatorHR {
  border: 1px solid #E6E6E6;
  width: calc(100% - 40px);
}

.currentStepContent {
  /* border: solid 2px violet; */
  padding: 0px 20px;
}

.personalDetailsContainer {
  border: solid 2px black;
}

.buttonContainer {
  /* border: solid 2px black; */
  width: fit-content;
  margin: 10% 0px 20px 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  color: #026786;
  background-color: #FFCD05;
}

.phoneNumberError {
  font-size: 16px;
  color: red;
  font-weight: 500 !important;
  line-height: 18px;
  margin: 3px auto;
}