@import '../../marketplace.css';

.whyDriveLahSection {
  width: 100%;
  background-color: var(--marketplaceColorDark);
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAHost/Curve-bg-min.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.whyDriveLahSection .primaryBtn{
  background: #fecd2a;
  max-width: 300px;
  border-radius: 4px;
  text-transform: initial;
  color:var(--marketplaceColorDark);
  &:hover{
      text-decoration: initial;
  }
}



.textBox {
  width: 100%;
  padding: 50px 20px;
  margin: auto;

  @media (--viewportMedium) {
    width: auto;
    max-width: 100%;
    margin: 0 7.5vw;
    padding: 50px 0px;
  }

  @media (--viewportLarge) {

  }

  @media screen and (min-width: 1200px) {
    margin: auto;
    max-width: 1100px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceSecondaryColor);
  font-weight: 900;
}

.description {
  @apply --marketplaceDescFontStyles;
  color: var(--matterColorLight);
  max-width: 660px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 66px;
  }
}

.mobileNewLine {
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.joinNow {
  @apply --marketplaceButtonStyles;
  border-radius: 5px;
  min-height: 50px;
  padding-top: 15px;
  width: 100%;

  &:hover, &:focus {
  }

  @media (--viewportMedium) {
    width: 160px;
    padding-top: 20px;
    min-height: 65px;
  }
}