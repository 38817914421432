.customContainerClassName {
  padding: 40px 20px 40px 40px !important;
  max-width: fit-content;

  @media (--viewportLarge) {
    flex-basis: 724px;
    min-height: auto;
    height: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: left;
  letter-spacing: 0px;
  color: #0D6786;
  opacity: 1;
  text-align: center;
}

.title label {
  font-size: large;
}

.subTitle {
  text-align: left;
  letter-spacing: 0px;
  color: #4A4A4A;
  opacity: 1;
  text-align: center;
}

.subTitle label {
  font-size: small;
  font-weight: 400;
}

.card {
  border-radius: 16px;
  overflow: hidden;
  color: inherit;
  text-decoration: none;
  display: block;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.inModalTitle {
  margin-bottom: 20px;
}

.images {
  /* width: 350px; */
  display: flex;
  justify-content: center;
  /* width: 81%; */

  @media (--viewportMobile) {
    display: block;
    width: auto;
  }
}

.image {
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}

.inModal {
  margin: 0px 15px 0px 0px;
  padding: 0;
}

.image img {
  display: block;
  /* max-width: 95%; */
  width: 100%;
}

.image span {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #222;
  background: #F4F6FA;
  padding: 10px;
  font-weight: 700;
  border-radius: 0px 0px 16px 16px;
  font-size: 18px;
  top: -5px;
}

.inCenter {
  justify-content: space-between;
}
