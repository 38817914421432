@import '../../marketplace.css';

.whyDriveLahSection {
  width: 100%;
  background-color: var(--marketplaceColor);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAGuest/howToRent-bg-min.png');
  }
}

.textBox {
  max-width: 100%;
  padding: 46px 20px 20px;
  margin: auto;
  text-align: left;

  @media (--viewportMedium) {
    text-align: left;
    margin: 0 7.5vw;
    padding: 50px 0px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1100px;
    margin: auto;
  }
}

.title {
  /* @apply --marketplaceH1FontStyles;
   */
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 900;
  color: var(--matterColorLight);
  line-height: 36px;

  @media (--viewportMedium){
    font-size: 48px;
    line-height: 56px;
  }
}

.description {
  @apply --marketplaceDescFontStyles;
  color: var(--matterColorLight);
  max-width: 818px;
  margin-bottom: 46px;

  @media (--viewportMedium) {
    margin-bottom: 66px;
  }
}

.joinNowRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.joinNowRow .text{
  color:var(--matterColorLight);
}
.joinNow {
  @apply --marketplaceButtonStyles;
  border-radius: 5px;
  max-width: 150px;
  line-height: 46px;
  max-height: 46px;
  min-height: 46px;
  padding: 0;
  &:hover, &:focus {
  }

  @media (--viewportMedium) {
    width: 265px;
    min-height: 65px;
    max-height: 65px;
    line-height: 65px;
    max-width: 265px;
  }
}

.image {
  height: 150px;
  margin-top: -15px;
  margin-bottom: 15px;
  @media (--viewportMedium){
    display: none;
  }
}