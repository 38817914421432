@import '../../marketplace.css';

.root {
  padding-top: 0;
  background-color: var(--matterColorLight);
}

.trustPilotLogo {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 20px;
  margin-bottom: 20px;
}

.trustPilotLogoContent {
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0;
  justify-content: space-evenly;
  /* min-height: 130px;
  max-width: 500px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -15px;
  background-color: #ffffff;

  &>div {
    flex-basis: 50%;
    max-width: 50%;
  }

  @media (--viewportLarge) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -15px;
    background-color: var(--matterColorLight);
    padding: 20px;

    &>div {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

.trustPilotLogoContent iframe {
  
}