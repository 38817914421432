@import '../../marketplace.css';

.root {
  width: 100%;
  background-color: #1aa2ac;
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  display: none;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
    position: relative;
    /* margin-top: -42px; */
  }

  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }

  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 60px;
}

.organizationDescription {
  @apply --marketplaceTinyFontStyles;
  color: var(--marketplaceColorDark);
}

.FooterAddress {
  @apply --marketplaceTinyFontStyles;
  color: var(--marketplaceColorDark);
}

.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  margin-top: 32px;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  color: var(--marketplaceColorDark);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ourPartners {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  color: var(--marketplaceColor);
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 2;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;
  display: none;

  @media (--viewportMedium) {
    display: block;
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}

.tosAndPrivacyMobile {
  flex-basis: auto;
  display: flex;
  justify-content: space-around;
  margin-right: 5%;
  min-width: 50%;
}

.legalLink,
.rental,
.terms,
.privacy {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-size: 13px;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.rental,
.terms,
.privacy {
  width: fit-content;
  text-align: center;
  padding: 0 3px 0 3px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  justify-content: center;
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Dimensions */
  padding: 0 24px 33px 24px;

  /* Layout */
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-right: 8px;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.FooterAddressMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  flex-basis: 100px;
  align-items: center;
  text-align: center;
  padding: 0 24px 18px 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.badgeListing {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}

.badgeListing li {
  display: inline-block;
  margin-right: 15px;

  @media (max-width: 767px) {
    margin-right: 0;
    padding: 0 10px;
  }
}

.badgeListing li button {
  display: block;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}

.badgeListing li button img {
  @media (max-width: 767px) {
    display: block;
    width: 100%;
  }
}

/* .rental,.terms {
  margin-right: 10px;
} */
.footerAppDownloadSection {
  padding-bottom: 58px;
}
.footerAppDownloadSection h6 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: #f8ce47;
  text-transform: none;
  margin-bottom: 12px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.footerAppDownloadSection p {
  max-width: 415px;
  margin-left: auto;
  color: #ffffff;
  font-size: 20px;
  line-height: 1.5;
  @media (max-width: 767px) {
    max-width: 297px;
    margin: 0;
    padding-top: 27px;
  }
}
.footerAppDownloadSection p img {
  display: inline-block;
  margin-left: 10px;
}

.footerLogo {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #ffffff;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }
}
.footerLogo img {
  margin-right: 10px;
  width: auto;
  max-width: 165px;
  object-fit: contain;
  object-position: left center;
  @media (max-width: 767px) {
    max-width: 125px;
    margin-bottom: 2px;
  }
}

.footerLogo span {
  margin-top: 5px;
}

.footerSocialLinks {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footerSocialLinks a {
  display: block;
  margin: 0 0 0 16px;
  width: 32px;
  height: 32px;
  opacity: 0.6;
  @media (max-width: 767px) {
    width: 28px;
    height: 28px;
  }
}
.footerSocialLinks a:hover,
.footerSocialLinks a:focus {
  opacity: 1;
}
.bottomFooterSection {
  height: 73px;
  background: #148b94;
  display: flex;
  align-items: center;
}
