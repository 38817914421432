@import '../../marketplace.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

.container {
  display: flex;
  flex-direction: column;
}

.text {
  color: var(--marketplaceColorDark);
}

.error {
  color: var(--failColor);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.doNotCancelButton {
  background-color: var(--matterColorLight);
  border: 1pt solid var(--marketplaceColorDark);
  color: var(--marketplaceColorDark);
  text-transform: capitalize;
  flex-basis: 45%;
  font-size: 20px;
  line-height: 24px;
  border-radius: 4 px;

  &:hover {
    color: var(--matterColorLight);
  }
}

.cancelButton {
  margin-left: 20px;
  flex-basis: 45%;
  text-transform: unset;
  font-size: 20px;
  line-height: 24px;
  border-radius: 4 px;
}

.reasonText {
  color: var(--marketplaceColorDark);
  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.cancelNoticeText {
  color: var(--marketplaceColorLight);
}

.openPolicy {
  color: var(--marketplaceColorLight);
  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.reasonTitle {
  color: var(--marketplaceColorDark);
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.cancelBreakdown {
  margin-top: 30px;
}

.hide {
  display: none;
}

.modalTitle {
  font-size: 24pt;
  line-height: 32px;
  color: #0d6786;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding: 0 0 30px;

  @media (--viewportMedium) {
    font-size: 28px;
    line-height: 38px;
  }
}

.modalText {
  margin: 0;
  padding: 0 0 30px;
  font-size: 18px;
  line-height: 22px;
}

.rootForm {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.reasonSelect {
  padding: 0;
}

.reasonSelect label {
  font-size: 16px;
  line-height: 22px;
  font-weight: var(--fontWeightRegular);
}

.reasonSelect select {
  border: none;
  padding: 10px 0 15px;
  -moz-white-space: pre-wrap;
  -o-white-space: pre-wrap;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.reasonSelect select:hover,
.reasonSelect select:focus {
  border-bottom-color: #fff;
}

.selectContainer {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 5px 10px 0 15px;
  margin-bottom: 30px;
}

.selectLabel {
  font-size: 16px;
  line-height: 22px;
  font-weight: var(--fontWeightRegular);
}
