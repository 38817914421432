.outerContainer {
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  /* padding: 20px;
  margin-top: 2%; */
}

.buttonContainer {
  padding: 0px 20px;
  padding-bottom: 20px;
}

.buttonContainer > button {
  /* border: solid 2px black; */
  margin-top: 10%;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #026786;
  background-color: #FFCD05;
}