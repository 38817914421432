@import '../../marketplace.css';

.root {
  margin: 0;

  /* Clearfix */
  @apply --clearfix;
}
.sectionTestimonials {
  /* padding-top: 40px; */
  padding-bottom: 25px;
  padding-top: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
}

.sectionTestimonialsDescription {
  @media (--viewportLarge) {
    color: var(--marketplaceColorDark);
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    display: block;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    line-height: normal;
  }
}

.sectionTestimonialsTitleWrapper {
  padding: 0 30px;
  & > .sectionTestimonialsTitle {
    white-space: pre-wrap;
    margin-bottom: 10px;
  }
  @media (--viewportLarge) {
    text-align: center;
    margin: 0 auto;
    & > .sectionTestimonialsTitle {
      white-space: nowrap;
    }
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.sectionTestimonialsTitle {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  @media (--viewportLarge) {
    text-align: center;
  }
  @media (--viewportMLarge) {
    font-size: 48px;
    line-height: normal;
    text-align: center;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
    font-size: 30px;
    text-align: center;
    line-height: normal;
  }
}

.sliderWrapper {
  margin-top: 50px;
  margin: 50px auto 0;
  padding: 0 50px;
  max-width: 1240px;
  width: 100%;
  @media (--viewportLarge) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    max-width: 100%;
    margin: 0;
    margin-top: 20px;
  }
}

.desc {
  padding: 15px 0;
  text-align: center;
  @media (--viewportMobile) {
    font-size: 15px;
    line-height: normal;
  }
}
