.headingContainer>p:first-child {
  color: #222222;
  font-size: 36px;
  font-weight: 700 !important;
  line-height: 46px;
  letter-spacing: -0.08px;
  text-align: center;

  @media (--viewportMobile) {
    font-size: 24px;
    line-height: 32px;
  }
}

.headingContainer>p:nth-child(2) {
  color: #222222;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 28px;
  letter-spacing: -0.08px;
  text-align: center;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 21px;
  }
}

.slider {
  /* border: solid 2px black; */
  margin: 5% 0%;
  height: 80vh;
  display: flex;
  justify-items: center;
  align-items: center;

  @media (--viewportMobile) {
    display: none;
  }

  @media (--viewportLarge) {
    height: 60vh;
  }
}

.testimonialSlider {
  height: 100% !important;
  padding: 10px 0px;
  overflow: hidden;
  width: 100% !important;
  margin: 0 auto;
  position: relative;
  display: flex;
}

.testimonialSlider:hover div {
  animation-play-state: paused;
}

.testimonialSlider>div {
  display: flex;
  position: relative;
  align-items: center;
  animation: marquee 180s linear infinite;
  /* Change the speed as per requirements */
  justify-content: space-around;
}

.testimonialSlider>div>div {
  display: block;
  height: 80% !important;
  min-width: 100px;
  aspect-ratio: 1;
  margin: 0 1vw;

  /* Change the spacing between the each testimonial */
}

.testimonialBox {
  /* border: solid 2px green; */
  border-radius: 20px;
  width: 280px;
  height: 450px;
  text-align: center;
  position: relative;
  flex-shrink: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.customerImage {
  /* border: solid 2px grey; */
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50%;
  max-height: 80%;
  height: 80%;
}

.customerImage>img {
  border-radius: 20px 20px 0px 0px;
  ;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.testimonialBox:nth-child(odd) {
  align-self: flex-start;
}

.testimonialBox:nth-child(even) {
  align-self: flex-end;
}

.testimonialData {
  /* border: solid 2px red; */
  width: 100%;
  border-radius: 0px 0px 20px 20px;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  padding: 3% 1%;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
  max-height: 50%;
  position: absolute;
  bottom: 0;
  min-height: 20%;
  z-index: 10;
  background-color: #1AA2AC;
}

.testimonialData>p {
  /* border: solid 2px blueviolet; */
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  font-weight: bold !important;
  color: white;
}

.testimonialData>p:nth-child(1) {
  margin: 5% 2%;
}

.testimonialData>p:nth-child(2) {
  font-size: 16px;
  margin: 2% 0%;
}

.testimonialData>p:nth-child(3) {
  margin-bottom: 2%;
}

.testimonialGridContainer {
  display: none;
  @media (--viewportMobile) {
    display: block;
    position: relative;
  }
}

.testimonialGridContainer > .gridArrows {
  @media (--viewportMobile) {
    /* border: solid 2px magenta; */
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3% 5%;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }
}

.testimonialGridContainer > .gridArrows > div {
  @media (--viewportMobile) {
    font-size: xx-large;
  }
}

.testimonialGrid {
  @media (--viewportMobile) {
    /* border: solid 2px black; */
    display: flex;
    overflow: scroll;
    scroll-snap-type: x mandatory;
  }
}

.testimonialGrid > .gridArrows {
  @media (--viewportMobile) {
    /* border: solid 2px magenta; */
    position: absolute;
  }
}

.hidden {
  visibility: hidden;
}

.gridContainer {
  @media (--viewportMobile) {
    /* border: solid 2px blue; */
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
  }
}

.gridContainer>.gridItem {
  @media (--viewportMobile) {
    /* border: solid 2px aqua; */
    width: 100vw;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}