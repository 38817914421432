@import '../../marketplace.css';

.root {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/downloadAppBg.png');
  background-repeat: no-repeat;
  padding: 30px 20px;
  background-color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-bottom: 200px;
  background-size: 250%;
  background-position: 90% 100%;


  @media (--viewportMedium) {
    padding: 40px 100px;

    background-size: 200%;
    background-position: 99% 100%;
    padding-bottom: 300px;
  }

  @media (--viewportLarge) {

    background-size: cover;
    background-position: center;

    padding: 40px 120px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: 1200px) {
    padding: 40px 160px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;

  font-weight: 900;
  color: var(--marketplaceSecondaryColor);
  margin: 0px;

  @media (--viewportMedium) {
    font-size: 38px;
  }

  @media (--viewportLarge) {
    font-size: 48px;
  }
}

.subtitle {
  color: var(--matterColorLight);
  font-size: 17px;
  line-height: 20px;
  max-width: 550px;
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 25px;
    font-size: 24px;
    line-height: 30px;
  }

  @media (--viewportLarge) {
    font-size: 27px;
    line-height: 32px;
  }
}

.buttonsWrapper {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
}

.appButton {
  width: 120px;
  height: 36px;
  margin-right: 5px;
  cursor: pointer;

  @media (--viewportMedium) {
    width: 142px;
    height: 42px;
    margin-right: 15px;
  }
}