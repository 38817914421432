@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  --storyBackgroundColor: #f2f2f2;
}

.root {
  overflow: hidden;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: auto;
  /* max-height: 600px; */
  padding: 0px;

  @media (--viewportMedium) {
    min-height: 500px;
    height: calc(100vh - var(--topbarHeightDesktop));
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 1048px;
    min-height: 600px;
    height: auto;
  }

  @media screen and (min-width: 1921px) {
    max-height: 1048px;
    min-height: 600px;
    height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.contentWrapper {
  position: relative;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  /* padding-bottom: 100px; */

  @media (--viewportMedium) {
    padding-bottom: 80px;
    justify-content: center;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  //padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  /* background-color: var(--marketplaceColor); */
  color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;
  display: none;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.imageComponent {
  position: relative;
  background-color: var(--matterColorAnti);

  @media (--viewportLarge) {
    max-height: 670px;
  }
}

.imageComponentImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageComponentContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
}

.sectionImages {
  padding: 0;
  z-index: 1;
}

.sectionImageTitle {
  font-size: 32px;
  line-height: 36px;
  font-weight: var(--fontWeightBold);
  white-space: pre-wrap;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);

  align-self: flex-end;
  color: var(--matterColorLight);
  padding: 0 30px 15px;

  @media (--viewportLarge) {
    position: relative;
  }

  @media (--viewportMLarge) {
    align-self: center;
    margin-left: calc(calc(100% - 1130px) / 2);
    font-size: 100px;
    line-height: 100px;
    width: 1130px;
    padding: 0;
  }
}

.instantTitle {
  position: relative;
  padding-bottom: 10px;

  @media (--viewportLarge) {
    padding-bottom: unset;
    &:after {
      content: 'INSTANT BOOKING';
      max-width: 400px;
      width: 400px;
      height: 70px;
      font-size: 40px;
      line-height: 70px;
      background-color: var(--instantBookingLabelColor);
      text-align: center;
      border-radius: 5px;
      position: absolute;
      bottom: 12px;
      margin-left: 15px;
    }
  }
}

.instantBookingLogo {
  display: none;
  width: 100%;
  height: 40px;

  font-size: 28px;
  line-height: 40px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  background-color: var(--instantBookingLabelColor);
  text-align: center;
  border-radius: 5px;
  position: absolute;

  @media (--viewportLarge) {
    display: inline;
    max-width: 400px;
    width: 400px;
    height: 70px;
    font-size: 40px;
    line-height: 70px;
    /* padding: 15px 30px; */
    bottom: 0;
    right: 0;
  }
}

.drivelahProperties {
  margin-top: 30px;
  padding-bottom: 30px;
  background-color: var(--matterColorLight);

  @media (--viewportMLarge) {
    max-width: 1130px;
    margin: 60px auto 0;
  }
}

.sectionWhyGoesDrivelah {
  z-index: 2;
  background-color: var(--matterColorLight);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px 30px 0;
  margin-top: -8px;

  @media (--viewportLarge) {
    padding: 0;
    background-color: var(--matterColorLight);
    border-radius: 0;
  }
}

.searchFormWrapper {
  @media (--viewportLarge) {
    max-width: 1130px;
    margin: -180px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.searchForm {
  @media (--viewportLarge) {
    padding: 30px 40px 0;
    width: 890px;
    background-color: var(--matterColorLight);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.insuranceWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 25px;

  @media (--viewportLarge) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: var(--matterColorLight);
    margin-top: 0;
    flex-direction: column;
    padding: 30px 25px 0;
    height: 180px;
    width: 230px;

    & > div:nth-last-child(1) {
      margin-top: 20px;
    }
  }
}

.insuranceImage {
  height: 40px;
  width: auto;
}

.insuranceText {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  margin-bottom: 12px;

  @media (--viewportLarge) {
    font-size: 15px;
  }
}

.approvedByLogo {
  width: 100%;
  height: auto;

  @media (--viewportLarge) {
    margin-top: -10px;
  }
}

.sectionTitle {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    text-align: center;
  }

  @media (--viewportMLarge) {
    font-size: 60px;
    line-height: 72px;
    text-align: center;
  }
}

.sectionOptionTitle {
  font-size: 24px;
  line-height: 30px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColorDark);

  margin-top: 30px;

  @media (--viewportLarge) {
    text-align: center;
  }

  @media (--viewportMLarge) {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }
}

.sectionOptionDescription {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);

  margin-top: 10px;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 24px;
    max-width: 750px;
    text-align: center;
    margin: 15px auto 0;
  }
}

.sectionRealBenefits {
  background-color: var(--marketplaceColorDark);
}

.channels {
  padding: 30px;
  background-color: var(--marketplaceColorDark);
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  column-gap: 30px;
  row-gap: 30px;

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 25%);
    max-width: 80%;
    margin: 0 auto;
  }

  @media (--viewportXLarge) {
    max-width: 1400px;
  }
}

.channel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.channelImageHorizontal {
  width: 100%;
  max-width: 140px;
  height: auto;

  @media (--viewportLarge) {
    max-width: 280px;
  }
}

.channelImageVertical {
  height: 100%;
  max-height: 38px;
  width: auto;

  @media (--viewportLarge) {
    max-height: 64px;
  }
}

.benefitsSection {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/SectionBenefitMobileBG.png');
  background-size: 100% 100%;
  background-position: center;
  padding: 45px 30px;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/SectionBenefitDeskBg.png');
  }

  @media (--viewportLarge) {
    padding: 50px 0;
  }
}

.benefits {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  @media (--viewportMLarge) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
  }
}

.benefit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 25px;

  &:first-child {
    margin-top: 20px;
  }

  @media (--viewportMLarge) {
    margin-right: 30px;
  }

  @media (--viewportXLarge) {
    margin-right: 90px;
  }
}

.sectionBenefitTitle {
  color: var(--matterColorLight);
  margin-top: 0;
  text-align: center;
}

.benefitImgWrapper {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

.benefitImg {
  height: 100px;
  max-height: 100px;
  width: auto;
}

.benefitName {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);
  white-space: pre-wrap;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 36px;
    &:last-child {
      white-space: pre-wrap;
    }
  }
}

.buttonWrapper {
  padding: 40px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playVideo {
  @apply --marketplaceButtonStylesPrimary;

  background-color: var(--marketplaceColorDark);
  padding: 0;
  border-radius: 5px;
  text-transform: none;

  @media (--viewportMedium) {
    max-width: 276px;
  }
}

.readMore {
  color: var(--matterColorLight);
  font-size: 20px;
  line-height: 24px;
  margin-top: 18px;
}

.sectionCars {
  padding: 40px 0;
}

.listingCardWrapper {
  padding: 0 30px;

  &:first-child {
    padding-left: 0;
  }

  @media (--viewportLarge) {
    &:first-child {
      padding: 0 30px;
    }
  }
}

.sectionCarsTitleWrapper {
  padding: 0 30px;
}

.sliderWrapper {
  margin-top: 20px;
  @media (--viewportLarge) {
    width: 100%;
    padding: 0 50px;
  }
}

.slider {
  height: 100%;
  display: block;
  margin-top: 20px;

  /* @media (--viewportLarge) {
    max-width: calc(calc(270px * 3) + calc(30px * 3));
    margin: 0 auto;
  }

  @media (--viewportMLarge) {
    max-width: calc(calc(270px * 4) + calc(30px * 4));
  }

   @media (--viewportXLarge) {
    max-width: calc(calc(388px * 4) + calc(36px * 4));
  } */
}

.sectionCars :global(.slick-list) {
  padding: 0 30px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionCars :global(.slick-slide) {
  /* margin-left: 30px;

  @media (--viewportLarge) {
    &:first-child {
      margin-left: 40px;
    }
  }

  @media (--viewportXLarge) {
    margin-left: 36px;
  } */
}

.sectionCars :global(.slick-disabled) {
  opacity: 0.5;
}

.sectionCars :global(.slick-prev) {
  left: -25px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/prev.png');
  &::before {
    content: '';
  }
}

.sectionCars :global(.slick-next) {
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/next.png');
  &::before {
    content: '';
  }
}

.sectionCars :global(.slick-dots) {
  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionCars :global(.slick-active) {
  opacity: 1 !important;
}

.sectionSharingYourCar {
  padding: 42px 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/sharingSimple.png');
  background-size: 100% 100%;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/sharingSimpleDesktop.jpg');
    background-position: center;
  }

  @media (--viewportMLarge) {
    padding: 60px 0;
  }

  @media (--viewportXLarge) {
    padding: 60px 0 149px;
  }
}

.sectionSharingYourCarProperties {
  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (--viewportLarge) {
    max-width: 1373px;
    margin: 0 auto;
  }
}

.sectionSharingYourCarProperty {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (--viewportLarge) {
    max-width: 30%;
    align-items: center;
  }

  @media (--viewportXLarge) {
    max-width: 403px;
  }
}

.sectionSharingYourCarText {
  color: var(--matterColorLight);
  text-align: left;

  @media (--viewportLarge) {
    text-align: center;
    letter-spacing: -0.3px;
  }
}

.findOutHowMuchCost {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceSecondaryColor);
  margin-top: 10px;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.listYourCar {
  @apply --marketplaceButtonStylesPrimary;
  text-transform: none;
  border-radius: 5px;
  margin-top: 38px;

  @media (--viewportLarge) {
    max-width: 276px;
    margin: 38px auto 0;
  }
}

.youAreInControl {
  white-space: pre-wrap;
  margin-top: 35px;
  text-align: center;

  @media (--viewportLarge) {
    margin-top: 50px;
  }

  @media (--viewportXLarge) {
    margin-top: 136px;
  }
}

.options {
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1373px;
    margin: 0 auto;
  }
}

.option {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
  align-items: center;
}

.optionTitle {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightMedium);
  white-space: pre-wrap;
  color: var(--matterColorLight);
  margin-left: 20px;
}

.optionImage {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sectionFeatures {
  padding: 0 30px 50px;

  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 30px));
    column-gap: 60px;
  }

  @media (--viewportXLarge) {
    max-width: 1600px;
    width: 1600px;
    margin: 0 auto;
    padding: 0;
  }
}

.feature {
  margin-top: 50px;

  @media (--viewportLarge) {
    flex: 1;
    /* max-width: 450px; */
    margin-top: 70px;
  }

  @media (--viewportXLarge) {
    max-width: 770px;
  }
}

.featureTitleWrapper {
  @media (--viewportXLarge) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 15px;
  }
}

.featureDrivelahGoTitleWrapper {
  margin-top: 15px;
}

.featureTitle {
  font-size: 24px;
  line-height: 30px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColorDark);
  white-space: pre-wrap;
  margin-top: 10px;

  @media (--viewportLarge) {
    white-space: unset;
    font-size: 40px;
    line-height: 46px;
  }
}

.featureDescription {
  font-size: 16px;
  line-height: 19px;
  color: var(--marketplaceColorDark);
  margin-top: 10px;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (--viewportXLarge) {
    max-width: 650px;
  }
}

.featureImage {
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;

  @media (--viewportLarge) {
    max-height: 250px;
    object-fit: cover;
  }
}

.instantLogo {
  width: 230px;
  height: 36px;

  font-size: 22px;
  line-height: 36px;

  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
  text-transform: uppercase;

  background-color: var(--instantBookingLabelColor);
  border-radius: 5px;

  margin-top: 15px;

  @media (--viewportXLarge) {
    width: 230px;
    min-width: 230px;
  }
}

.findoutButton {
  @apply --marketplaceButtonStylesPrimary;

  border-radius: 5px;
  text-transform: none;
  margin-top: 10px;
  background-color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    max-width: 224px;
  }
}

.goLogo {
  width: 130px;
  height: auto;

  @media (--viewportLarge) {
    height: 57px;
  }
}

.story {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 270px;
}

.storyContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 270px;
  width: 100%;
  background-color: var(--storyBackgroundColor);
  border-radius: 10px;
  padding: 0 0 45px 0;
}

.storyContent {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  padding: 0 25px;
  text-align: center;
  min-height: 260px;

  @media (--viewportLarge) {
    min-height: 200px;
  }

  @media (--viewportMLarge) {
    min-height: 220px;
  }

  @media (--viewportXLarge) {
    min-height: unset;
  }
}

.avatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -35px;
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
}

.sectionStories {
  /* padding-top: 40px; */
  padding-bottom: 25px;

  @media (--viewportLarge) {
    padding-top: 60px;
  }
}

.sectionStories :global(.slick-list) {
  padding: 0 30px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionStories :global(.slick-slide) {
  /* margin-left: 30px;

  @media (--viewportLarge) {
    &:first-child {
      margin-left: 40px;
    }
  }

  @media (--viewportXLarge) {
    margin-left: 36px;
  } */
}

.sectionStories :global(.slick-disabled) {
  opacity: 0.5;
}

.sectionStories :global(.slick-prev) {
  left: -25px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/prev.png');
  &::before {
    content: '';
  }
}

.sectionStories :global(.slick-next) {
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/next.png');
  &::before {
    content: '';
  }
}

.sectionStories :global(.slick-dots) {
  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionStories :global(.slick-active) {
  opacity: 1 !important;
}
/*
.sectionStories :global(.slick-list) {
  padding: 0 0 0 30px;
}

.sectionStories :global(.slick-slide) {
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

.sectionStories :global(.slick-dots) {

  margin-top: 40px;

  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionStories :global(.slick-active) {
  opacity: 1 !important;
} */

.storyWrapper {
  padding: 0 20px 0 0;

  @media (--viewportLarge) {
    padding: 0 30px;
  }
}

.storyAvatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.storyName {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  text-align: center;
  margin-top: 7px;
  /* transform: translateX(-50%); */
}

.sectionHelp {
  padding: 40px 30px;
}

.sectionHelpTitle {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  white-space: pre-wrap;

  @media (--viewportLarge) {
    white-space: nowrap;
    text-align: center;
    font-size: 60px;
    line-height: 72px;
  }
}

.question {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  padding-bottom: 20px;
  border-bottom: solid 1px var(--matterColorNegative);
  margin-top: 20px;
  letter-spacing: -0.4px;
  user-select: none;
  max-width: 500px;
}

.answer {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  display: none;
  white-space: pre-wrap;
  margin-top: 20px;
  user-select: none;
  max-width: 500px;
}

.showAnswer {
  display: block;
}

.searchHelp {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--marketplaceColor);
  padding-bottom: 20px;
  margin-top: 30px;

  @media (--viewportLarge) {
    max-width: 570px;
    width: 570px;
    margin: 30px auto 0;
    padding-left: 102px;
  }
}

.searchIcon {
  width: 16px;
  height: 16px;
}

.searchHelpInput {
  border: none;
  margin-left: 10px;
  color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 30px;

  @media (--viewportLarge) {
    font-size: 32px;
    line-height: 32px;
  }
}

.sectionStoriesDescription {
  display: none;

  @media (--viewportLarge) {
    color: var(--marketplaceColorDark);
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    display: block;
  }
}

.sectionStoriesTitleWrapper {
  padding: 0 30px;

  & > .sectionTitle {
    white-space: pre-wrap;
  }

  @media (--viewportLarge) {
    text-align: center;
    margin: 0 auto;
    & > .sectionTitle {
      white-space: nowrap;
    }
  }
}

.helpQuestions {
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 100px;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 50px;
  }
}

.featureLogoWrapper {
  @media (--viewportLarge) {
    height: 60px;
  }
}

.videoWrapper {
  padding: 0;

  @media (--viewportMedium) {
    padding: 0 20px;
    /* margin-top: 40px; */
  }

  @media (--viewportMLarge) {
    margin-top: 0px;
  }
}

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.video {
  width: 280px;
  background-color: var(--matterColorAnti);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 313px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}

.sectionCarsLoading {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: var(--marketplaceColorDark);
}

.viewAllCar {
  margin: 32px auto 0;
  display: block;
  max-width: 124px;
}

.mobile {
  display: inline-block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: inline-block;
  }
}

.hourly {
  width: 230px;
  height: 40px;
  background-color: var(--marketplaceColor);
  border-radius: 6px;
  font-size: 24px;
  line-height: 40px;
  color: var(--matterColorLight);
  text-align: center;

  @media (--viewportLarge) {
    margin-top: 20px;
    width: 570px;
    height: 80px;
    font-size: 60px;
    line-height: 80px;
    border-radius: 10px;
  }
}

.hourlyTitle {
  @media (--viewportLarge) {
    margin-top: -20px;
  }
}

.sectionImageHourTitle {
  letter-spacing: -1.5px;
  padding-bottom: 5px;

  @media (--viewportLarge) {
    letter-spacing: unset;
    padding-bottom: unset;
  }
}
