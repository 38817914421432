@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;
  --TopbarSearchForm_mobilePredictionTop: calc(
    var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
      var(--TopbarSearchForm_bottomBorder)
  );
}

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--matterColorLight);
  border-radius: 40px;
  width: 1000px;
}

.root label {
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: 24px;
  background-color: transparent;
}

.mobileIcon {
  margin-right: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH1FontStyles;

  /* Layout */
  margin: 0 0 0 24px;
  padding: 1px 13px 13px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &::placeholder {
    font-size: 20px;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 3px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--marketplaceColor);
  position: absolute;
  background: transparent;
  left: 34px;
  display: none;
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceH4FontStyles;
  height: 35px;
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  /* padding-left: 10px; */

  /* padding-left: 70px; */
  line-height: 24px;
  font-size: 20px;
  border: none;
  padding: 0;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  background-color: transparent;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover + .desktopIcon svg g,
  &:focus + .desktopIcon svg g {
    stroke: var(--marketplaceColor);
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--marketplaceColorDark);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    color: var(--marketplaceColorDark);
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* .desktopIcon + .desktopIcon svg g {
  stroke: var(--marketplaceColor);
} */

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(100vh - var(--TopbarSearchForm_mobilePredictionTop));
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 560px;
  background-color: var(--matterColorLight);
  top: 50px;
  left: -30px;
  border-radius: 20px;
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 560px;

  & > ul > li {
    font-size: 20px;
    line-height: 24px;
    color: var(--marketplaceColorDark);
  }
  & > ul > li:hover {
    color: var(--marketplaceColor);
    border-left: 6px solid var(--marketplaceColor);
    background-color: #f6f6f6;
  }
}

.iconClass {
  display: none;
}

.predictionsAttribution {
  display: none;
}

.suggestionTitle {
  display: none;
}

/* .field {
  flex: 1;
  align-items: center;
  height: 80px;
  padding-left: 30px;
  padding-top: 10px;
  box-sizing: border-box;
  position: relative;

  &:hover {
    border-radius: 40px;
    background-color: #f6f6f6;
    border: solid 1px #e6e6e6;
    margin-top: -1px;
  }
}

.activeField {
  border-radius: 40px;
  background-color: #f6f6f6;
  border: solid 1px #e6e6e6;
} */

.submitBtn {
  width: 72px;
  height: 72px;
  border-radius: 36px;
  background-color: var(--marketplaceSecondaryColor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  & svg {
    width: 30px;
    height: 30px;
  }
}

.disabled {
  opacity: 0.5;
}

.startDatePanel {
  left: calc(1000px / -3);
}

.startDatePanelLongTerm {
  left: -28px !important;
}

.endDatePanel {
  left: calc(970px * -2 / 3) !important;
}

.longTermTimePanel {
  width: 100% !important;
  right: 0 !important;
}

.clearBtn {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--marketplaceColorDark);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 80;

  & > svg {
    stroke: var(--matterColorLight);
    fill: var(--matterColorLight);
  }
}

.field {
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
}
.formInput {
  flex: 0 0 50%;
  max-width: 50%;
}

.formInput input,
.formInput select {
  font-size: 18px !important;
  padding: 0 !important;
  border: 0 !important;
  color: #ffffff;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/searchPage/arrow-down-white.svg') !important;
  background-size: 16px 16px !important;
  background-position: center right !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 20px 0 0 !important;
  &::placeholder {
    color: #ffffff !important;
    opacity: 1 !important;
  }
  @media (max-width: 1024px) {
    font-size: 14px !important;
    padding: 0 20px 0 0 !important;
    border: 0 !important;
  }
}
.dateInput {
  padding: 0 10px 0 0 !important;
}
/* Location search design */
.searchIconWrapper {
  width: 60px;
  height: 60px;
  background-color: #ffcd05;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  right: 6px;
  top: 6px;
  @media (max-width: 767px) {
    width: 46px;
    height: 46px;
    right: 5px;
    top: 5px;
  }
}
.searchIconWrapper svg {
  width: 20px;
}
.locationInputRoot {
  padding: 0;
  max-width: 100%;
}
.locationInputRoot input {
  height: 20px;
  border: 0;
  width: 100%;
  border-bottom: 1px solid transparent;
  transition: none !important;
  font-size: 18px;
  color: #ffffff;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    border-bottom: 1px solid transparent;
  }
  &:focus {
    border-bottom: 1px solid transparent;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
  }
}
.locationInputRoot .locationInputIcon {
  display: none !important;
}
.locationInputPredictions {
  top: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background: #ffffff;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000024;
  border-radius: 4px;
  margin-top: 7px;
  padding: 10px;
  opacity: 1;
  visibility: visible;
  min-width: 462px;
}

.isVisible {
  opacity: 1;
  visibility: visible;
}
.locationInputPredictions .currentLocation svg {
  margin: 0 10px 0 0;
}
.locationInputPredictions ul {
  margin: 0 0 10px;
  padding: 0;
}
.locationInputPredictions ul li {
  color: #0d6786;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  line-height: normal;
}
.locationInputPredictions ul li::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  width: 0;
  height: 100%;
  background: #0d6786;
}
.locationInputPredictions ul li:hover {
  color: #0d6786;
  opacity: 0.85;
  border: 0;
}
.locationInputPredictions ul li:hover::before {
  width: 5px;
}
.locationInputPredictions .searchLabels {
  color: #b2b2b2;
  height: 40px;
  font-size: 16px;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 8px 20px;
}
.locationInputPredictions .suggestionTitle {
  font-size: 16px;
  line-height: normal;
  color: #b2b2b2;
}

.fieldOuter.fieldLocationOuter {
  flex: 0 0 calc(40% - 130px);
  max-width: calc(40% - 130px);
}
.fieldOuter {
  flex: 0 0 calc(30% - 40px);
  max-width: calc(30% - 40px);
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #ffffff;
  padding: 6px 0;
  margin-right: 30px;
}

.fieldOuter .fieldLabel {
  font-size: 14px;
  line-height: normal;
  color: #fecd2a;
  padding-right: 10px;
  min-width: 40px;
}

.buttonWrapper {
  flex: 1;
  max-width: 130px;
}
.buttonWrapper .searchQuery {
  min-width: 100px;
  height: 42px;
  min-height: 42px;
  font-size: 18px;
  border-radius: 5px;
  text-transform: capitalize;
}
.buttonWrapper .searchQuery:disabled {
  background-color: #ffcd05;
  color: #026786;
  opacity: 0.75;
}
.buttonWrapper .searchQuery:not(:disabled):hover, .buttonWrapper .searchQuery:not(:disabled):focus {
  opacity: 1;
  background-color: #ffcd05;
  background: #ffcd05;
  box-shadow: none;
}

.nonClickable{
  pointer-events: none;
  opacity: 0.75;
}
.fieldElement{
  width: 100%;
  flex: 1;
}
.locationInputPredictionAttribute{
  display: none;
}