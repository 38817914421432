@import '../../marketplace.css';

.donationPageRoot {
  padding: 0;
}

.contentWrapper {
  position: relative;
  background: #ffffff;
}

.landingContainerFluid {
  max-width: 100%;
  padding: 0 20px;
}
.landingContainer {
  max-width: 1376px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
.landingRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.alignItemsCenter {
  align-items: center !important;
}
.textCenter {
  text-align: center !important;
}
.bannerWrapper {
  position: relative;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (--viewportMobile) {
    min-height: 667px;
  }
}
.headerSection {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 50px 0;
  @media (--viewportXLarge) {
    padding: 100px 0;
  }
  @media (--viewportMobile) {
    padding: 25px 0;
  }
}
.logoWrapper {
  max-width: 100px;
  @media (--viewportXLarge) {
    max-width: 134px;
  }
  @media (--viewportMobile) {
    max-width: 75px;
  }
}
.logoWrapper img {
  max-width: 100%;
  width: 100%;
}
.bannerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.bannerContent h1 {
  font-size: 74px;
  line-height: normal;
  color: #ffffff;
  max-width: 800px;
  padding: 0 20px;
  text-align: center;
  margin: 0;
  @media (--viewportXLarge) {
    font-size: 100px;
    line-height: normal;
    max-width: 892px;
  }
  @media (--viewportMobile) {
    font-size: 42px;
  }
}

.crisisSection {
  padding: 75px 0;
  @media (--viewportXLarge) {
    padding: 50px 0;
  }
  @media (--viewportMobile) {
    padding: 40px 0;
  }
}
.crisisSection p {
  font-size: 36px;
  line-height: normal;
  font-weight: 700;
  margin: 0;
  color: #0b3561;
  text-align: center;
  @media (--viewportXLarge) {
    font-size: 45px;
  }
  @media (--viewportMobile) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.contributeSection {
  padding: 50px 0;
  @media (--viewportXLarge) {
    padding: 63px 0;
  }
  @media (--viewportMobile) {
    padding: 40px 0;
  }
}
.contributeSection .content {
  max-width: 600px;
}
.contributeSection .logoThumb {
  max-width: 425px;
  margin: 0 auto;
  @media (--viewportXLarge) {
    max-width: 572px;
  }
  @media (--viewportMobile) {
    max-width: 175px;
    margin: 0 auto 20px;
  }
}
.contributeSection h2 {
  font-size: 34px;
  line-height: normal;
  font-weight: 500;
  color: #0b3561;
  margin: 0 0 30px;
  @media (--viewportXLarge) {
    font-size: 40px;
  }
  @media (--viewportMobile) {
    font-size: 30px;
    margin: 0 0 20px;
    text-align: center;
  }
}
.contributeSection h2 span {
  color: #f2745e;
}
.contributeSection p {
  font-size: 24px;
  line-height: normal;
  font-weight: 300;
  color: #0b3561;
  margin-bottom: 20px;
  @media (--viewportXLarge) {
    font-size: 30px;
    margin-bottom: 30px;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    text-align: center;
  }
}

.makeDonationSection {
  padding: 50px 0 150px;
  @media (--viewportXLarge) {
    padding: 63px 0 290px;
  }
  @media (--viewportMobile) {
    padding: 40px 0;
  }
}
.makeDonationSection .logoThumb {
  max-width: 500px;
  margin-left: auto;
  position: relative;
  @media (--viewportXLarge) {
    max-width: 429px;
  }
  @media (--viewportMobile) {
    margin: 20px 0;
  }
}
.makeDonationSection .logoThumb span{
  font-size: 1.3vw;
  line-height: normal;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  color: #FFFFFF;
  text-align: center;
  width: 100%;
  padding-bottom: 4px;
  @media (--viewportMobile) {
    font-size: 3.3vw;
  }
}

.makeDonationSection h2 {
  font-size: 34px;
  line-height: normal;
  font-weight: 500;
  color: #0b3561;
  margin: 0 0 30px;

  @media (--viewportXLarge) {
    font-size: 40px;
  }
  @media (--viewportMobile) {
    font-size: 30px;
    margin: 0 0 20px;
    text-align: center;
  }
}
.makeDonationSection h2 span {
  color: #f2745e;
}
.makeDonationSection p {
  font-size: 24px;
  line-height: normal;
  font-weight: 300;
  color: #0b3561;
  margin-bottom: 20px;
  @media (--viewportXLarge) {
    font-size: 30px;
    margin-bottom: 30px;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    text-align: center;
  }
}

.storiesPartnersSection {
  background: #0b3561;
  padding-bottom: 118px;
  @media (--viewportMobile) {
    padding-bottom: 50px;
  }
}

.storiesSection {
  padding: 50px 0 70px;
  @media (--viewportXLarge) {
    padding: 90px 0;
  }
  @media (--viewportMobile) {
    padding: 40px 0;
  }
}
.storiesSection h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  margin: 0 0 48px;
  @media (--viewportXLarge) {
    font-size: 40px;
    margin-bottom: 74px;
  }
  @media (--viewportMobile) {
    margin: 0 0 30px;
  }
}
.storiesSection p {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  max-width: 600px;
  margin: 0 auto;
  @media (--viewportXLarge) {
    font-size: 30px;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.partnersSection {
  padding: 50px 0 70px;
  @media (--viewportMobile) {
    padding: 40px 0;
  }
}
.partnersSection h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  margin: 0 0 48px;
  @media (--viewportXLarge) {
    font-size: 40px;
    margin: 0 0 74px;
  }
  @media (--viewportMobile) {
    margin: 0 0 30px;
  }
}
.partnersList {
  display: flex;
  max-width: 477px;
  margin: 0 auto;
  justify-content: center;
}
.partnersList .partnersLogo {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding-left: 24px;
  padding-right: 24px;
  @media (--viewportMobile) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.buttonWrap{
  padding-top: 30px;
}
.donateSubmit {
  background: #f2745e;
  text-transform: capitalize;
  color: #ffffff;
  padding: 10px 30px;
  min-width: 150px;
  border-radius: 2px;
  min-height: 65px;
  font-size: 28px;
  line-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
  @media (--viewportMobile) {
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    bottom: 0;
  }
}
.modalContainer{
  flex-basis: 724px;
  min-height: auto;
  height: auto;
  padding: 60px 30px 70px 30px;
  border: 0;
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 2px;
  @media (--viewportMobile) {
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}