@import '../../marketplace.css';

.heroSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAHost/become-a-host-bg-DM.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAHost/become-a-host-bg-DM.jpg');
    background-image: image-set(
      url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAHost/become-a-host-bg-DM.jpg') 1x,
      url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAHost/become-a-host-bg-DM.jpg') 2x
    );
    flex-direction: row;
    padding: 90px;
    height: 600px;
  }

  @media (--viewportLarge) {
    height: 750px;
    justify-content: flex-end;
  }
}

.form {
}

.mobileHero {
  display: block;
  height: 150px;
  width: 100%;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAHost/become-a-host-bg-min.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchBox {
  border-radius: 5px;
  background-color: var(--matterColorLight);
  padding: 30px;

  @media (--viewportMedium) {
    width: 520px;
    height: 420px;
    padding: 30px 50px;
  }

  @media (--viewportLarge) {
  }
}

.searchBoxTitle {
  font-size: 30px;
  line-height: 40px;
  font-weight: 900;
  color: var(--marketplaceColor);
  margin-bottom: 16px;
  letter-spacing: 0;
  margin-top: 0;
  @media (--viewportMedium) {
    font-size: 36px;
  }
}

.searchBoxDesc {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColor);
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 24px;
  }
}

.fieldsWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.inputField {
  flex-basis: 100%;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    flex-basis: 45%;
  }
}

.yearField {
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: 45%;
  }
}

.milesField {
  flex-basis: 50%;

  @media (--viewportMedium) {
    flex-basis: 45%;
  }
}

.input {
  border-bottom: 3px solid var(--marketplaceColor);
}

.selectField {
  border-bottom: 3px solid var(--marketplaceColor);
}

.footerBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.breakDown {
  font-size: 40px;
  height: 40px;
  font-weight: 900;
  color: var(--marketplaceColor);
  position: relative;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  &::after {
    content: 'per day';
    font-weight: 700;
    position: absolute;
    font-size: 16px;
    line-height: 20px;
    bottom: -20px;
    display: block;
    width: auto;
    min-width: 75px;
  }

  @media (--viewportMedium) {
    font-size: 48px;
    height: 40px;
    margin-bottom: 0px;

    &::after {
      left: 0;
    }
  }
}

.listYourCar {
  @apply --marketplaceButtonStyles;
  border-radius: 5px;
  min-height: 50px;
  padding-top: 15px;

  @media (--viewportMedium) {
    width: 194px;
    min-height: 65px;
    padding-top: 20px;
  }
}

.explaination {
  position: relative;
  display: inline-block;
  /* width: 15px;
  height: 15px;
  margin-left: 5px; */
  font-size: 12px;
  color: var(--matterColorAnti);
  cursor: pointer;

  &::before {
    content: '?';
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    font-weight: bold;
    left: 0;
    border-radius: 50%;
    border: 1px solid var(--matterColorAnti);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.explaination:hover .explainationText {
  display: block;
}

.explainationText {
  display: none;
  position: absolute;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  padding: 15px 25px;
  left: -112px;
  bottom: 18px;
  margin-bottom: 25px;
  font-weight: normal;
  width: 250px;
  box-shadow: 3px 3px 10px var(--matterColorNegative);

  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}
