@import '../../marketplace.css';
.root {
  padding-top: 0;
}

.landingContainer {
  max-width: 1240px;
  margin: 0 auto;
  width: 100%;
  @media (--viewportMedium) {
    padding: 0 20px;
  }
  @media (--viewportMobile) {
    padding: 0 15px;
  }
}

.landingRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.alignItemsCenter {
  align-items: center !important;
}

.textCenter {
  text-align: center !important;
}

.mobileImage {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  @media (--viewportMedium) {
    display: none;
  }
}

.mobileImage img {
  width: 100%;
  max-width: 100%;
}

/* Banner section  */

.bannerSlideItem {
  height: 800px;
  padding-top: 90px;
  position: relative;
  @media (max-width: 1024px) {
    height: 600px;
  }
  @media (max-width: 767px) {
    height: 316px;
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute;
    background: transparent linear-gradient(0deg, #000000 0%, #00000000 100%) 0% 0% no-repeat
      padding-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    opacity: 0.5;
  }
}

.bannerSlideItem .imageComponentImageBLanding {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 10;
}

.imageComponentContentBLanding {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 12;
  @media (max-width: 767px) {
    align-items: flex-end;
    padding: 0;
  }
}

.sectionPara {
  font-size: 21px !important;
  @media (--viewportMobile) {
    color: var(--matterColorLight) !important;
  }
}

.bannerContentBlock {
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.sectionImageTitleBLanding {
  font-size: 60px;
  line-height: 1.2;
  font-weight: var(--fontWeightBold);
  white-space: pre-wrap;
  align-self: flex-end;
  color: var(--matterColorLight);
  padding: 0 0 10px;
  flex: 0 0 100%;
  max-width: 100%;
  @media (--viewportLarge) {
    position: relative;
  }
  .hourlyTitleBLanding {
    @media (--viewportLarge) {
      margin-top: -20px;
    }
  }
  @media (--viewportMLarge) {
    align-self: center;
    font-size: 60px;
    line-height: 1.2;
    width: auto;
    padding: 0;
  }
  @media (max-width: 991px) {
    font-size: 40px;
  }
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 1.125;
    padding: 0 15px 15px;
  }
  @media (max-width: 374px) {
    font-size: 24px;
    line-height: 1.125;
    padding: 0 15px 15px;
  }
}

.sectionImageTitleBLanding span {
  display: inline;
  position: relative;
  z-index: 1;
}

.sectionImageTitleBLanding span i {
  display: inline;
  position: relative;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
}

.sectionImageTitleBLanding span i::after {
  content: '';
  display: inline;
  background: #00a3ad;
  position: absolute;
  left: 0;
  height: 53px;
  width: 100%;
  display: inline;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
  @media (max-width: 767px) {
    height: 23px;
    width: 100%;
  }
}

.sectionImageTitleBLanding span i div {
  display: inline-block;
}

.searchBarMobile {
  @media (--viewportMobile) {
    display: none;
  }
}

.searchBar {
  width: 100%;
  margin-top: 10px;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 30px 0 0;
  border-radius: 0;
  border-right: 1px solid #959595;
  /* box-shadow: 0 3px 6px #00000029; */
  @media (--viewportMedium) {
    width: 983px;
  }
  @media (max-width: 767px) {
    background: var(--matterColorLight);
    margin-top: 0;
    border-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 43px 15px 0;
    box-shadow: none;
    border: 0;
  }
}

.searchBarContent {
  position: relative;
  z-index: 2;
  @media (max-width: 767px) {
    background: #18a3ad;
    padding: 30px 20px;
    margin: 0 -20px;
  }
}

.searchBarContent p {
  margin: 0 0 20px;
  font-size: 22px;
  line-height: 1.2777;
  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.searchBarContent .link {
  font-size: 20px;
  line-height: 24px;
  @media (--viewportMobile) {
    font-size: 18px;
  }
}

.signupButton:hover {
  text-decoration: none !important;
}

.signupButton button {
  margin-top: 30px;
  background: #fecd2a;
  color: #0d6786;
  font-size: 24px;
  line-height: 18px;
  border-radius: 5px;
  text-transform: initial;
  &:hover {
    background: #fecd2a;
    color: #0d6786;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 22px;
  }
}

.sectionImagesBLanding {
  padding: 0;
  z-index: 1;
}

.insuranceListingWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  flex-wrap: wrap;
  @media (--viewportLarge) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-top: 0;
    padding: 30px 0 0;
  }
  @media (max-width: 767px) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 0 0 30px;
    padding: 0;
  }
}

.trustPilotLogo {
  flex-basis: 166px;
  background: #ffffff;
  border-radius: 8px;
  padding: 12px;
  margin-right: 15px;
  @media (max-width: 767px) {
    margin-right: 0;
    padding: 0 20px 0 0;
  }
}

.comprehensiveInsuranceBlock {
  flex-basis: 166px;
  background: #ffffff;
  border-radius: 8px;
  padding: 12px;
  @media (max-width: 767px) {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

.insuranceListingWrapper .comprehensiveInsuranceBlock {
  cursor: pointer;
}

.insuranceListingImage {
  width: auto;
  height: 47px;
  @media (--viewportMobile) {
    height: auto;
  }
}

.insuranceListingText {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  margin-bottom: 6px;
  @media (--viewportLarge) {
    font-size: 15px;
  }
}

.flexCover {
  display: flex;
  align-items: baseline;
  color: var(--matterColor);
  margin-right: 25px;
}

.flexCover h4 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 900;
  letter-spacing: -0.58px;
  margin: 0;
}

.flexCover span {
  margin-left: 7px;
  font-size: 18px;
  line-height: 24px;
}

/* section grids */

.sectionGrids {
  padding: 50px 0 10px;
  @media (max-width: 767px) {
    padding: 30px 0 20px;
  }
}

.gridBox {
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.gridBoxInner {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
}

.gridBoxInner .gridThumb {
  position: relative;
  height: 0;
  padding-bottom: 50%;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-bottom: 72%;
  }
}

.gridBoxInner .gridThumb::after {
  content: '';
  position: absolute;
  width: calc(100% + 50px);
  height: 50px;
  background: #ffffff;
  top: 100%;
  left: 0;
  transform-origin: 0 0;
  transform: rotate(-5deg);
}

.gridBoxInner .gridThumb img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
}

.gridBoxInner .gridThumb .thumbContent {
  position: absolute;
  z-index: 9;
  max-width: 219px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gridHorizontal .gridBoxInner .gridThumb .thumbContent {
  width: 103px;
  height: 51px;
}

.gridHorizontal .gridBoxInner .gridThumb .thumbContent svg {
  width: 100%;
  height: 100%;
}

.gridBoxInner .gridContent {
  padding: 20px;
  @media (max-width: 767px) {
    padding: 20px 10px;
  }
}

.gridBoxInner .gridContent .title {
  font-size: 28px;
  color: #026786;
  line-height: normal;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}

.gridBoxInner .gridContent p {
  font-size: 22px;
  color: #4a4a4a;
  line-height: normal;
  margin: 0 0 10px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.gridBoxInner .gridContent ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.gridBoxInner .gridContent ul li {
  margin: 0 0 10px;
}

.gridBoxInner .gridContent ul li svg {
  vertical-align: baseline;
  display: inline-block;
  margin: 0 10px 0 0;
}

.gridBoxInner .gridContent .btnWrapper {
  padding: 20px 0 0;
  text-align: left;
}

.gridBoxInner .btnWrapper .btnMore {
  min-width: 214px;
  height: 49px;
  padding: 10px 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: normal;
  color: #026786;
  border: 1px solid #026786;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  transition: 0.3s all ease;
  text-decoration: none !important;
  @media (max-width: 767px) {
    min-width: 100%;
  }
  &:hover {
    background: #026786;
    color: #ffffff;
  }
}

.gridHorizontal .gridBoxInner {
  display: flex;
  flex-wrap: wrap;
}

.gridHorizontal .gridBoxInner .gridThumb {
  max-width: 347px;
  min-width: 347px;
  flex: 1;
  position: relative;
  overflow: hidden;
  padding-bottom: 30%;
  @media (max-width: 767px) {
    min-height: 20px;
    max-height: 100%;
    height: 0;
    padding-bottom: 72%;
    min-height: 20px;
    max-height: 100%;
    height: 0;
    width: 100%;
    min-width: 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.gridHorizontal .gridBoxInner .gridThumb::after {
  height: calc(100% + 50px);
  width: 50px;
  background: #ffffff;
  bottom: 0;
  left: auto;
  transform-origin: 0 100%;
  transform: rotate(5deg);
  right: 0;
  top: auto;
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 50px;
    background: #ffffff;
    top: 100%;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-5deg);
  }
}

.gridHorizontal .gridBoxInner .gridContent {
  flex: 1;
}

.gridHorizontal .gridContent .horizontalList {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  padding-top: 20px;
}

.gridHorizontal .horizontalList li {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 22px !important;
  }
}

.gridHorizontal .horizontalList .iconThumb {
  max-width: 50px;
  flex: 1;
}

.gridHorizontal .horizontalList .iconText {
  flex: 1;
  padding-left: 10px;
  max-width: 172px;
  @media (max-width: 767px) {
    max-width: 142px;
  }
}

.gridHorizontal .btnWrapper .btnMore {
  border: 1px solid #fecd2a;
  background: #fecd2a;
  color: #026786;
}

.gridHorizontal .gridContent .badgeListing {
  list-style: none;
  margin: 0;
  padding: 0;
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}

.gridHorizontal .gridContent .badgeListing li {
  display: inline-block;
  margin-right: 15px;
  @media (max-width: 767px) {
    margin-right: 0;
    padding: 0 10px;
  }
}

.gridHorizontal .gridContent .badgeListing li button {
  display: block;
  padding: 0;
  border: 0;
  cursor: pointer;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}

.gridHorizontal .gridContent .badgeListing li button img {
  @media (max-width: 767px) {
    display: block;
    width: 100%;
  }
}

.gridHorizontal.gridImageRight .gridBoxInner .gridThumb {
  padding-bottom: 17%;
  order: 2;
  background: #00a3ad;
  @media (max-width: 767px) {
    order: -1;
    min-height: 20px;
    max-height: 100%;
    height: 0;
    padding-bottom: 72%;
    width: 100%;
    min-width: 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.gridHorizontal.gridImageRight .gridBoxInner .gridThumb::after {
  left: 0;
  right: auto;
  transform: rotate(-5deg);
  transform-origin: 100% 100%;
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 50px;
    background: #ffffff;
    top: 100%;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-5deg);
  }
}

/* tableSection */

.tableSection .tableBackground {
  background: #18a3ad;
  position: relative;
  z-index: -1;
  @media (--viewportMedium) {
    padding-top: 100px;
    height: 400px;
  }
  @media (--viewportMobile) {
    padding-top: 30px;
    height: 190px;
  }
}

.tableSection .tableBackground .leftImg {
  display: none;
  @media (--viewportLarge) {
    display: block;
    position: absolute;
    left: 10px;
    bottom: 0;
  }
}

.tableSection .tableBackground .rightImg {
  display: none;
  @media (--viewportLarge) {
    display: block;
    position: absolute;
    right: 0;
  }
}

.tableSection .tableBackground .tableHead {
  color: var(--matterColorLight);
  @media (--viewportMedium) {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    max-width: 740px;
    margin: 0 auto 45px;
    font-weight: 800;
  }
  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 26px;
    text-align: left;
    max-width: 100%;
    margin: 0 0 20px;
  }
}

.tableSection .tableContent {
  width: 100%;
  padding: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #fecf2f;
  -webkit-box-shadow: 0px 3px 30px 0px rgb(0 0 20 / 20%);
  -moz-box-shadow: 0px 3px 30px 0px rgb(0 0 20 / 20%);
  box-shadow: 0px 3px 30px 0px rgb(0 0 20 / 20%);
  margin-top: -175px;
  background: #fff;
  overflow: auto;
  @media (--viewportMobile) {
    margin-top: -62px;
    border-radius: 8px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.tableSection table {
  width: 100%;
  min-width: 405px;
}

.tableSection table tr {
  height: 90px;
  @media (--viewportMobile) {
    height: 55px;
  }
}

.tableSection table tr:nth-child(even) {
  background-color: rgb(254 207 47 / 10%);
}

.tableSection table tr th,
.tableSection table tr td {
  border-right: 1px solid #e9e9e9;
  width: 18%;
  @media (--viewportMobile) {
    width: auto;
  }
}

.tableSection table tr th:first-child {
  width: 28%;
  @media (--viewportMobile) {
    width: auto;
  }
}

.tableSection table tr th:last-child,
.tableSection table tr td:last-child {
  border-right: none;
}

.tableSection table tr .rowTitle {
  text-align: left;
  font-size: 22px;
  line-height: 26px;
  color: #0d6786;
  padding-left: 35px;
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
  }
}

.tableSection table tr .amount {
  color: #18a3ad;
  font-size: 18px;
  line-height: 21px;
  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 14px;
  }
}

.tableSection table tr .amountColor {
  color: #ee283d;
  font-size: 18px;
  line-height: 21px;
}

.tableSection table tr .flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tableSection table tr .flexRow img {
  cursor: pointer;
  @media (--viewportMobile) {
    height: 15px;
    width: 15px;
  }
}

.tableSection table tr .flexRow .info {
  display: none;
  position: absolute;
  width: 150px;
  font-size: 12px;
  text-align: center;
  color: var(--matterColorLight);
  border-radius: 3px;
  bottom: calc(100% + 5px);
  top: auto;
  line-height: normal;
  padding: 10px;
  background: rgba(0, 0, 0, 0.75);
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: normal;
}

.literalText {
  color: #18a3ad;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  @media (--viewportMobile) {
    font-size: 12px;
  }
}

.infoIcon {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  bottom: -5px;
  right: -5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  @media (--viewportMobile) {
    width: 10px;
    height: 10px;
    font-size: 7px;
  }
}

.flexRow .info::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}

.isIconWrapper {
  position: relative;
  cursor: pointer;
}

.tableSection table tr .flexRow .isIconWrapper:hover .info {
  display: block;
}

.tableSection table tr .trust {
  padding-left: 10px;
  @media (--viewportMedium) {
    padding-left: 35px;
  }
}

.tableSection table tr .trust img {
  width: 95px;
  @media (--viewportMedium) {
    width: 144px;
  }
}

.tableSection table tr .rowHead img {
  @media (--viewportMobile) {
    width: 70px;
    height: auto;
    padding: 0 10px;
  }
}

.tableSection table tr .flexRow h6 {
  color: #18a3ad;
  font-size: 18px;
  line-height: 21px;
  margin-left: 5px;
  font-weight: normal;
  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 14px;
  }
}

/* DriveMateSection */

.landingCol {
  margin: 140px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (--viewportMobile) {
    margin: 85px 0;
  }
}

.landingCol .sectionLeftContent,
.landingCol .sectionRightContent {
  width: 100%;
  @media (--viewportLarge) {
    width: 50%;
  }
}

.landingCol .sectionLeftContent {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  @media (--viewportLarge) {
    justify-content: flex-start;
  }
}

.landingCol .sectionRightContent h2 {
  color: #0d6786;
  font-size: 60px;
  line-height: 71px;
  margin: 0 0 10px;
  font-weight: 900;
  @media (--viewportMobile) {
    font-size: 32px;
    line-height: 40px;
  }
}

.landingCol .sectionRightContent span {
  color: #0d6786;
  font-size: 22px;
  line-height: 26px;
  font-weight: 900;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 24px;
  }
}

.landingCol .sectionRightContent .paraContent {
  max-width: 100%;
  margin-top: 30px;
  @media (--viewportLarge) {
    max-width: 540px;
  }
  @media (--viewportMobile) {
    margin-top: 15px;
  }
}

.landingCol .sectionRightContent p {
  font-size: 22px;
  line-height: 28px;
  color: #4a4a4a;
  margin: 0 0 20px;
  font-weight: 100;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 24px;
  }
}

/* whyWhirl */

.whyWhirl {
  @media (--viewportMedium) {
    background: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/drivemateSection/bgImage.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.whyWhirl .mobileImage img {
  width: 100%;
}

.whyWhirl .leftContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 35px 20px;
  @media (--viewportMedium) {
    padding: 60px;
  }
  @media (--viewportLarge) {
    width: 60%;
  }
  @media (--viewportMobile) {
    background: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/drivemateSection/bgImageMobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom-right-radius: 9px;
  }
}

.whyWhirl .leftContent h3 {
  color: #0d6786;
  font-size: 28px;
  line-height: 34px;
  margin: 0 0 10px;
  font-weight: 900;
  @media (--viewportMobile) {
    line-height: 38px;
    margin-bottom: 15px;
  }
}

.whyWhirl .leftContent p {
  color: #4a4a4a;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  font-weight: 100;
  @media (--viewportMobile) {
    line-height: 18px;
    line-height: 24px;
  }
}

.whyWhirl .leftContent .contentBox {
  margin: 30px 0 20px;
  padding: 30px;
  background: rgb(255 255 255 / 60%);
  border-radius: 8px;
  max-width: 565px;
  @media (--viewportMobile) {
    padding: 30px 20px;
  }
}

.whyWhirl .leftContent .contentBox .list {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media (--viewportMobile) {
    margin-bottom: 30px;
    align-items: flex-start;
  }
}

.whyWhirl .leftContent .contentBox .list img {
  margin-right: 15px;
}

.whyWhirl .leftContent .contentBox .list p {
  color: #4a4a4a;
  font-size: 18px;
  line-height: 21px;
  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 26px;
  }
}

.whyWhirl .leftContent .video {
  max-width: 565px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fecd2f;
  border-radius: 8px;
  padding: 10px 30px;
  font-weight: 500;
  @media (--viewportMobile) {
    padding: 10px 15px;
    border-radius: 5px;
  }
}

.whyWhirl .leftContent .video .profileTitle {
  display: flex;
  align-items: center;
}

.whyWhirl .leftContent .video .profileTitle img {
  @media (--viewportMobile) {
    display: none;
  }
}

.whyWhirl .leftContent .video p {
  font-size: 24px;
  line-height: 29px;
  color: #0d6786;
  margin-left: 12px;
  font-weight: 500 !important;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 16px;
    margin-left: 0;
  }
}

/* landingGo */

.landingGo {
  margin: 160px 0 150px;
  flex-direction: column-reverse;
  @media (--viewportLarge) {
    flex-direction: row;
  }
  @media (--viewportMobile) {
    margin: 90px 0 50px;
  }
}

.landingGo .logo {
  margin-bottom: 20px;
  @media (--viewportMobile) {
    margin-bottom: 10px;
  }
}

.landingGo .logo img {
  height: 56px;
  width: auto;
  @media (--viewportMedium) {
    height: 82px;
    width: auto;
  }
}

.landingGo .sectionRightContent .paraContent {
  @media (--viewportLarge) {
    margin-bottom: 45px;
  }
}

.landingGo .sectionRightContent .learnButton {
  @media (--viewportMobile) {
    display: none;
  }
}

.landingGo .sectionRightContent .learnButton:hover {
  text-decoration: initial;
}

.landingGo .sectionRightContent .learnButton button {
  background: #fecd2a;
  min-height: 50px;
  max-width: 320px;
  border-radius: 4px;
  text-transform: initial;
}

.landingGo .sectionRightContent .learnButton button span {
  font-weight: 700;
  font-size: 18px;
  color: #026786;
}

/* makeBankSection */

.makeBankSection {
  margin-top: 90px;
  position: relative;
  @media (--viewportMedium) {
    /* background: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/drivemateSection/bankSectionImg.png');
        background-size: cover;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat; */
    margin: 140px 0 60px;
  }
}

.makeBankSection .desktopImage {
  display: none;
  @media (--viewportLarge) {
    display: block;
    position: absolute;
    z-index: -1;
  }
}

.makeBankSection .desktopImage img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.makeBankSection .leftContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  background: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/drivemateSection/bannerBgMobile.png');
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  padding-top: 14vw;
  @media (--viewportLarge) {
    padding-left: 60px;
    padding-right: 0;
    width: 80%;
    background: 0 !important;
  }
  @media (--viewportLMLarge) {
    padding-top: 13vw;
  }
  @media (--viewportXLarge) {
    padding-top: 10vw;
  }
  @media (--viewportXXLarge) {
    padding-top: 8vw;
  }
  @media (--viewportMobile) {
    padding-top: 35px;
  }
}

.makeBankSection .leftContent h3 {
  font-size: 34px;
  line-height: 44px;
  color: var(--matterColorLight);
  margin: 0 0 25px;
  max-width: 100%;
  font-weight: 900;
  @media (--viewportMobile) {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 38px;
  }
  @media (--viewportMLarge) {
    max-width: 380px;
  }
}

.makeBankSection .leftContent .paraContent {
  font-size: 22px;
  line-height: 28px;
  color: var(--matterColorLight);
  margin: 0px;
  max-width: 100%;
  font-weight: 100;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (--viewportMLarge) {
    max-width: 430px;
  }
}

.makeBankSection .leftContent .boxes {
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (--viewportMobile) {
    margin-top: 25px;
    margin-bottom: 35px;
  }
}

.makeBankSection .leftContent .boxes .box {
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 20px;
  flex: 0 0 100%;
  margin-bottom: 20px;
  background: rgb(255 255 255 / 70%) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(48px);
  @media (--viewportMedium) {
    flex: 0 0 48%;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    padding: 20px 30px;
    background: rgb(255 255 255 / 40%) 0% 0% no-repeat padding-box;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
  }
}

.makeBankSection .leftContent .boxes .box .link {
  color: #0d6786;
  font-weight: 700;
}

.makeBankSection .leftContent .boxes .box:nth-child(1),
.makeBankSection .leftContent .boxes .box:nth-child(2) {
  margin-bottom: 20px;
}

.makeBankSection .leftContent .boxes .box:last-child {
  margin-bottom: 0;
}

.makeBankSection .leftContent .boxes .box .imageContainer {
  border-radius: 100%;
  background: var(--matterColorLight);
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.makeBankSection .leftContent .boxes .box p {
  color: #4a4a4a;
  font-size: 22px;
  line-height: 26px;
  margin: 0;
  margin-left: 20px;
  width: calc(100% - 70px);
  font-weight: 100;
  @media (--viewportMobile) {
    color: var(--matterColorLight);
    word-break: break-word;
  }
}

.makeBankSection .leftContent .learnButton:hover {
  text-decoration: initial;
}

.makeBankSection .leftContent .learnButton button {
  color: #026786;
  background: #fecd2a;
  min-height: 50px;
  font-size: 18px;
  line-height: 22px;
  max-width: 320px;
  border-radius: 4px;
  text-transform: initial;
}

/* Rental categories section */

.rentalCarCategories {
  background: rgba(242, 242, 242, 1);
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.rentalCarCategoriesContainer {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}

.rentalCarCategoriesRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.columnEight {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.columnTwo {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.rentalCarCategoriesRow .columnTitle {
  font-size: 18px;
  line-height: normal;
  color: #026786;
  font-weight: 300;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}

.rentalCarCategoriesRow .columnTitle::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 92px;
  height: 1px;
  background: #18a3ad;
}

.rentalCarCategoriesRow .categoriesListing {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.rentalCarCategoriesRow .categoriesListing li {
  font-size: 16px;
  line-height: 2;
  color: #026786;
  font-weight: 300;
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.rentalCarCategoriesRow .columnTwo .categoriesListing li {
  flex: 0 0 100%;
  max-width: 100%;
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;
  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }
  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }
  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);
  @media (--viewportMedium) {
    color: var(--matterColorLight);
    top: -40px;
  }
  &:hover {
    color: var(--matterColorAnti) !important;
  }
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  @media (--viewportMedium) {
    display: block;
  }
}

.iframeVideo {
  width: 100%;
  height: 40%;
  @media (--viewportMedium) {
    height: 100%;
  }
}

.contentModal {
  height: 100%;
}

/* Banner stories */

/* Banner stories Ends */

/* Banner help */

.sectionHelpBLanding {
  padding: 40px 30px;
}

.helpQuestionsBLanding {
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 100px;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 50px;
  }
}

.sectionHelpTitleBLanding {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  white-space: pre-wrap;
  @media (--viewportLarge) {
    white-space: nowrap;
    text-align: center;
    font-size: 60px;
    line-height: 72px;
  }
}

.questionBLanding {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  padding-bottom: 20px;
  border-bottom: solid 1px var(--matterColorNegative);
  margin-top: 20px;
  letter-spacing: -0.4px;
  user-select: none;
  max-width: 500px;
}

.answerBLanding {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  display: none;
  white-space: pre-wrap;
  margin-top: 20px;
  user-select: none;
  max-width: 500px;
}

.showAnswerBLanding {
  display: block;
}

.searchHelpBLanding {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--marketplaceColor);
  padding-bottom: 20px;
  margin-top: 30px;
  @media (--viewportLarge) {
    max-width: 570px;
    width: 570px;
    margin: 30px auto 0;
    padding-left: 102px;
  }
}

.searchIconBLanding {
  width: 16px;
  height: 16px;
}

.searchHelpInputBLanding {
  border: none;
  margin-left: 10px;
  color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 30px;
  @media (--viewportLarge) {
    font-size: 32px;
    line-height: 32px;
  }
}

/* Banner help Ends */

.comprehensiveInsuranceBlock {
  padding-left: 15px;
}

.comprehensiveInsuranceBlockWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 265px;
  @media (max-width: 767px) {
    max-width: 150px;
  }
}

.comprehensiveInsuranceBlockWrapper .cIContent {
  padding-left: 10px;
  @media (--viewportMobile) {
    padding-left: 5px;
  }
}

.comprehensiveInsuranceBlockWrapper .cIContent span {
  font-size: 18px;
  line-height: 24px;
  color: #026786;
  font-weight: 500;
  display: block;
  max-width: 130px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.33;
  }
}

.comprehensiveInsuranceBlockWrapper .cIContent span strong {
  font-weight: 900;
}

.comprehensiveInsuranceBlockWrapper .cILogo {
  min-width: 82px;
  max-width: 82px;
  flex: 1;
  @media (max-width: 767px) {
    min-width: 55px;
    max-width: 55px;
  }
}

/* Modal */

.summaryContent {
  @apply --marketplaceModalBaseStyles;
  padding: 60px;
  flex-basis: 525px;
  height: 500px;
  @media (--viewportMobile) {
    padding: 30px 20px;
  }
}

.summaryContent .insuranceListWrapper {
  padding: 0;
}

.insuranceListWrapper h3 {
  font-size: 30px;
  color: #00a3ad;
  line-height: 1.2;
}

.insurancePointsList {
  padding-left: 20px;
}

.insurancePointsList li {
  font-size: 18px;
  line-height: normal;
  list-style: disc;
  margin-bottom: 10px;
  list-style-position: outside;
}

.headerPointers {
  display: block !important;
}

.headerPointers span {
  text-align: left;
}

/* New banner design */

.bannerContent .row:last-child .heroContentBrandText {
  margin-bottom: 0;
}

.bannerContent .row svg {
  @media (--viewportMobile) {
    height: 18px;
    width: 18px;
  }
}

.heroContentBrandText {
  font-size: 20px;
  line-height: 1.4;
  font-weight: normal;
  color: #4a4a4a;
  margin-bottom: 10px;
  padding-left: 10px;
  @media (max-width: 767px) {
    font-size: 20px;
    color: #ffffff;
  }
}

.sectionRow {
  display: flex;
  flex-wrap: wrap;
}

.sectionRow .sectionColumn7 {
  flex: 0 0 60%;
  max-width: 60%;
}

.sectionRow .sectionColumn5 {
  flex: 0 0 40%;
  max-width: 40%;
}

.bannerContentSection {
  max-width: 983px;
  background: transparent;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
  padding: 35px;
  overflow: hidden;
  position: relative;
}

.bannerContentSection::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/b-landing/2024/banner-image.jpg);
  background-position: center center;
  background-size: 500%;
  filter: blur(10px);
  left: 0;
  top: 0;
  z-index: -1;
}

.bannerContentSection::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.85;
  left: 0;
  top: 0;
  z-index: -1;
}

.searchBarContent {
  @media (max-width: 767px) {
    border-bottom: 1px solid #ffffff;
  }
}

.searchBarContent .row {
  display: flex;
}

.verificationContent {
  @media (--viewportMedium) {
    padding-left: 30px;
  }
  @media (--viewportMobile) {
    margin-bottom: 20px;
  }
}

.verificationContent .verificationHeading {
  font-size: 22px;
  font-weight: 900;
  color: #18a3ad;
  @media (--viewportMedium) {
    font-size: 25px;
  }
}

/* .isSectionLoggedIn{
  max-width: 675px;
}
.isSectionLoggedIn .sectionColumn7{
  flex: 0 0 100%;
  max-width: 100%;
}
.isSectionLoggedIn .searchBar{
  border-right: 0;
} */
