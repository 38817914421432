/* .stepper {
  border: solid 2px black;
  width: 50%;
  max-width: 600px;
  position: relative;
} */

.stepperHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-width: 250px;
}

.stepperProgress {
  position: absolute;
  top: 50%;
  left: 0;
  height: 4px;
  width: calc(100% - 30px); /* Adjust for padding */
  background-color: #e0e0e0;
  z-index: 0;
  transform: translateY(-50%);
}

.stepperProgressActive {
  background-color: #026786;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  transform: translateY(-50%);
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  font-size: 12pt;
  font-weight: bold;
}

.step.active {
  background-color: #026786;
  color: white;
}

.step.completed {
  background-color: #026786;
  color: white;
}

.step::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: calc(100% - 30px);
  height: 2px;
  background-color: transparent;
  z-index: -1;
  transform: translateY(-50%);
}

.step:last-child::before {
  display: none;
}

.stepperContent {
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.stepperActions {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}