@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  --storyBackgroundColor: #f2f2f2;
}

.root {
  overflow: hidden;
}
img {
  display: block;
  max-width: 100%;
}
.contentWrapper {
  position: relative;
  background: #ffffff;
}

.containerFluid {
  max-width: 100%;
  padding: 0 20px;
}
.container {
  max-width: 1640px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
.row {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.alignItemsCenter {
  align-items: center !important;
}
.textCenter {
  text-align: center !important;
}

.channelsListings {
  background: #ffffff;
}

.landingPageBanner {
  padding-top: 74px;
  background: #ffffff;

  @media (max-width: 991px) {
    padding-top: 30px;
  }
  @media (max-width: 767px) {
    padding-top: 20px;
  }
}

.landingPageBannerRow {
  margin-left: -50px;
  margin-right: -50px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.bannerSectionLeft {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.bannerSectionLeft img {
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
}
.bannerSectionLeft p {
  margin: 0 0 30px;
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}
.bannerSectionRight {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.landingPageBannerTitle {
  font-size: 44px;
  line-height: normal;
  color: #0d6786;
  font-weight: 900;
  display: block;
  max-width: 500px;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    font-size: 32px;
  }
}
.bannerSectionLeft p {
  font-size: 24px;
  line-height: normal;
  font-weight: 500;
  @media (max-width: 991px) {
    font-size: 20px;
  }
}
.imageComponent {
  position: relative;
  background-color: var(--matterColorAnti);

  @media (--viewportLarge) {
    max-height: 670px;
  }
}

.imageComponentImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageComponentContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
}

.SeoLandingForm {
  z-index: 2;
  background-color: var(--matterColorLight);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0;
  background-color: var(--matterColorLight);
  border-radius: 0;
}

.fieldLocation {
  max-width: 100%;
  position: relative;
  height: 72px;
  margin-bottom: 41px;
  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
}
.fieldLocation input {
  border: 1px solid #e6e6e6 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  height: 100%;
  border-radius: 4px;
  font-size: 24px;
  padding: 10px 90px 10px 20px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.searchFormWrapper {
  position: relative;
}

.fieldLocation .hideSearchIcon {
  display: none;
}
.searchPredictions {
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background: #00a3ad;
  opacity: 1;
  z-index: 999;
  padding-bottom: 50px;
  display: none;
  @media (max-width: 767px) {
    display: none !important;
  }
}
.searchPredictions ul {
  padding: 0;
}
.searchPredictions ul li {
  padding: 10px 20px;
}
.searchPredictions ul .suggestionTitle {
  padding: 10px 20px;
}
.fieldLocation .searchIconBoxed {
  position: absolute;
  top: 0;
  right: 0;
  background: #fecd2a;
}
.searchPredictions ul li:empty {
  display: none;
}

.insuranceWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 40px 0 30px;
  margin: 0;
  max-width: 430px;
  margin: 0 -20px;
  @media (max-width: 767px) {
    padding-top: 25px;
  }
  /* @media (--viewportLarge) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: var(--matterColorLight);
    margin-top: 0;
    flex-direction: column;
    padding: 30px 25px 0;
    height: 180px;
    width: 230px;

    & > div:nth-last-child(1) {
      margin-top: 20px;
    }
  } */
}

.insuranceImage {
  height: auto;
  width: auto;
  max-width: 130px;
}

.insuranceText {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  margin-bottom: 12px;

  @media (--viewportLarge) {
    font-size: 15px;
  }
}

.approvedByLogo {
  width: 100%;
  height: auto;
}

.landingPageBannerImage {
  border-radius: 10px;
  width: 100%;
}
.landingPageBannerImage img {
  width: 100%;
  max-width: 100%;
}

.story {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 270px;
}

.storyContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--storyBackgroundColor);
  border-radius: 10px;
  padding: 0 0 45px 0;
}

.storyContent {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  padding: 0 15px 40px;
  max-width: 375px;
  min-height: 180px;
  text-align: center;
}

.avatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -35px;
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  background: rgb(0, 163, 173);
  border: 1px solid rgb(0, 163, 173);
}

.sectionStories {
  /* padding-top: 40px; */
  background: #ffffff;
  padding-bottom: 25px;
  padding-top: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
}

.sectionStories :global(.slick-list) {
  padding: 0;
}
.sectionStories :global(.slick-dots) {
  bottom: -30px;
}

.sectionStories :global(.slick-slide) {
  /* margin-left: 30px;

  @media (--viewportLarge) {
    &:first-child {
      margin-left: 40px;
    }
  }

  @media (--viewportXLarge) {
    margin-left: 36px;
  } */
}

.sectionStories :global(.slick-disabled) {
  opacity: 0.5;
}

.sectionStories :global(.slick-prev) {
  left: -45px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/prev.png');
  &::before {
    content: '';
  }
}

.sectionStories :global(.slick-next) {
  right: -45px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/next.png');
  &::before {
    content: '';
  }
}

.sectionStories :global(.slick-dots) {
  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionStories :global(.slick-active) {
  opacity: 1 !important;
}
.sectionStories :global(.slick-active) div {
  outline: none !important;
}
.sectionStories .sliderWrapper {
  padding: 0 20px;
}

/*
.sectionStories :global(.slick-list) {
  padding: 0 0 0 30px;
}

.sectionStories :global(.slick-slide) {
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

.sectionStories :global(.slick-dots) {

  margin-top: 40px;

  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionStories :global(.slick-active) {
  opacity: 1 !important;
} */

.storyWrapper {
  padding: 0 20px 0 0;
  padding: 0 30px 0 0;
}

.storyAvatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.storyName {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  text-align: center;
  margin-top: 7px;
  /* transform: translateX(-50%); */
}

.sectionStoriesDescription {
  display: none;

  @media (--viewportLarge) {
    color: var(--marketplaceColorDark);
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    display: block;
  }
}

.sectionStoriesTitleWrapper {
  padding: 0 20px;

  & > .sectionTitle {
    white-space: pre-wrap;
    margin-bottom: 10px;
  }

  @media (--viewportLarge) {
    text-align: center;
    margin: 0 auto;
    & > .sectionTitle {
      white-space: nowrap;
    }
  }
}

.sectionTitle {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  margin-bottom: 60px;
  @media (--viewportLarge) {
    text-align: center;
  }

  @media (--viewportMLarge) {
    font-size: 48px;
    line-height: normal;
    text-align: center;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
}

.sectionOptionTitle {
  font-size: 24px;
  line-height: 30px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColorDark);

  margin-top: 30px;

  @media (--viewportLarge) {
    text-align: center;
  }

  @media (--viewportMLarge) {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }
}

.sectionOptionDescription {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);

  margin-top: 10px;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 24px;
    max-width: 750px;
    text-align: center;
    margin: 15px auto 0;
  }
}

.sliderWrapper {
  margin-top: 50px;
  margin: 50px auto 0;
  padding: 0 50px;
  max-width: 1190px;
  width: 100%;
  @media (--viewportLarge) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 0;
    max-width: 100%;
    margin: 0;
  }
  @media (max-width: 767px) {
    margin-top: 20px;
  }
}

.slider {
  height: 100%;
  display: block;
  margin-top: 20px;

  /* @media (--viewportLarge) {
    max-width: calc(calc(270px * 3) + calc(30px * 3));
    margin: 0 auto;
  }

  @media (--viewportMLarge) {
    max-width: calc(calc(270px * 4) + calc(30px * 4));
  }

   @media (--viewportXLarge) {
    max-width: calc(calc(388px * 4) + calc(36px * 4));
  } */
}

.sectionHelp {
  padding: 40px 20px;
  background: #ffffff;
}

.helpQuestions {
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 100px;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 50px;
  }
}

.sectionHelpTitle {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  white-space: pre-wrap;

  @media (--viewportLarge) {
    white-space: nowrap;
    text-align: center;
    font-size: 60px;
    line-height: 72px;
  }
}

.question {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  padding-bottom: 20px;
  border-bottom: solid 1px var(--matterColorNegative);
  margin-top: 20px;
  letter-spacing: -0.4px;
  user-select: none;
  max-width: 500px;
}

.answer {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  display: none;
  white-space: pre-wrap;
  margin-top: 20px;
  user-select: none;
  max-width: 500px;
}

.showAnswer {
  display: block;
}

.searchHelp {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--marketplaceColor);
  padding-bottom: 20px;
  margin-top: 30px;

  @media (--viewportLarge) {
    max-width: 570px;
    width: 570px;
    margin: 30px auto 0;
    padding-left: 102px;
  }
}

.searchIcon {
  width: 16px;
  height: 16px;
}

.searchHelpInput {
  border: none;
  margin-left: 10px;
  color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 30px;

  @media (--viewportLarge) {
    font-size: 32px;
    line-height: 32px;
  }
}

/* Section cars */
.sectionCars {
  max-width: 1190px;
  margin: 0 auto;
  padding: 105px 15px 60px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 60px 15px;
  }
}
.btnWrapper {
  text-align: center;
  @media (max-width: 1024px) {
    padding: 30px 0 0;
  }
}
.viewAllCar {
  width: 320px;
  height: 60px;
  border-radius: 4px;
  background: #0d6786;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
}
.listingGrid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: 767px) {
    display: block;
  }
}
.listingCardWrapper {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: 0 15px;
  margin-bottom: 50px;
  @media (max-width: 1024px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px 0 0;
    margin-bottom: 0;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 10px;
  }
}

.channels {
  padding: 22px 15px;
  max-width: 1190px;
  margin: 0 auto;
}

.channelsRow {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.channel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
}

.channelImage {
  width: auto;
  height: auto;
  max-width: 100%;
}

.channelImageVertical {
  height: 100%;
  max-height: 38px;
  width: auto;

  @media (--viewportLarge) {
    max-height: 64px;
  }
}

/* Why rent Car section */
.whyRentCarSection {
  background: #18a3ad;
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/SectionBenefitMobileBG.png');
  background-size: 100% 100%;
  background-position: center;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/SectionBenefitDeskBg.png');
  }

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.whyRentCarContainer {
  max-width: 1190px;
  margin: 0 auto;
}
.whyRentCarTitle {
  text-align: center;
  font-size: 48px;
  line-height: normal;
  color: #ffffff;
  font-weight: 900;
  margin-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 991px) {
    font-size: 36px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    text-align: left;
    margin-bottom: 30px;
  }
}
.whyRentCarListing {
  padding: 0 20px;
}
.whyRentCarListing .listings {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}
.whyRentCarListing .listings li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}
.whyRentCarListing .listings li:last-child .listingsItemContent {
  @media (max-width: 767px) {
    border-bottom: 0;
  }
}
.whyRentCarListing .listingsItemContent {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  height: 100%;
  position: relative;
}
.whyRentCarListing .listingsItemContent .listingsTitle {
  font-size: 30px;
  line-height: normal;
  color: #ffffff;
  font-weight: 900;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}
.whyRentCarListing .listingsItemContent p {
  font-size: 20px;
  line-height: normal;
  color: #ffffff;
  font-weight: 500;
  margin: 0 0 15px;
  @media (max-width: 767px) {
    font-weight: 300;
  }
}

/* Rent from other brand section */
.rentFromOtherBrands {
  background: #ffffff;
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 0;
  }
}
.rentFromOtherBrandsContainer {
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 20px;
}
.rentFromOtherBrandsTitle {
  text-align: center;
  font-size: 48px;
  line-height: normal;
  color: #026786;
  font-weight: 900;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 30px;
    text-align: left;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}
.rentFromOtherBrandsTitle span {
  display: block;
  margin-bottom: 30px;
  @media (max-width: 575px) {
    margin-bottom: 0;
  }
}
.rentFromOtherBrandsTitle p {
  text-align: center;
  font-size: 20px;
  line-height: normal;
  color: #026786;
  font-weight: 500;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 767px) {
    display: none;
  }
}
.rentFromOtherBrandsListing .listings {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.rentFromOtherBrandsListing .listings .listingsItemContent {
  position: relative;
}
.rentFromOtherBrandsListing .listings .listingsItemContent::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 18px;
  background: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/next.png');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.rentFromOtherBrandsListing .listings li {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
  color: #026786;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.rentFromOtherBrandsListing .listings li:last-child .listingsItemContent {
  @media (max-width: 575px) {
    border-bottom: 0;
  }
}
.rentFromOtherBrandsListing .listingsItemContent {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 20px 0;
  cursor: pointer;
}
.rentFromOtherBrandsListing .listingsItemContent .listingsTitle {
  font-size: 30px;
  line-height: normal;
  color: #18a3ad;
  font-weight: 900;
}
.rentFromOtherBrandsListing .listingsItemContent p {
  font-size: 20px;
  line-height: normal;
  color: #18a3ad;
  font-weight: 500;
}

/* Rent video section */
.rentVideoSection {
  background: #18a3ad;
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/SectionBenefitMobileBG.png');
  background-size: 100% 100%;
  background-position: center;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/landingpage/SectionBenefitDeskBg.png');
  }

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.rentVideoSectionContainer {
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 20px;
}
.rentVideoSectionTitle {
  text-align: center;
  font-size: 48px;
  line-height: normal;
  color: #ffffff;
  font-weight: 900;
  margin-bottom: 80px;
  @media (max-width: 991px) {
    font-size: 36px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    text-align: left;
  }
}
.rentVideoSectionTitle span {
  position: relative;
  display: block;
}
.rentVideoSectionTitle span::after {
  content: '';
  position: absolute;
  border-radius: 5px;
  width: 203px;
  height: 10px;
  background: #ffffff;
  left: 0;
  right: 0;
  top: 130%;
  margin: 0 auto;
  @media (max-width: 767px) {
    top: 150%;
  }
}
.rentVideoWrapper {
  max-width: 800px;
  margin: 0 auto;
}
.rentVideoOuter {
  position: relative;
  padding-bottom: 53%;
  height: 0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}
.rentVideoOuter img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.rentVideoPlayButton {
  position: absolute;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: rgba(74, 74, 74, 0.71);
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 767px) {
    width: 55px;
    height: 55px;
  }
}
.rentVideoPlayButton::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 39.5px 0 39.5px 66px;
  border-color: transparent transparent transparent #ffffff;
  left: 20px;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 767px) {
    left: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16.5px 0 16.5px 22px;
    border-color: transparent transparent transparent #ffffff;
  }
}
.rentVideoDescription {
  font-size: 24px;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

/* Rental categories section */
.rentalCarCategories {
  background: rgba(242, 242, 242, 1);
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.rentalCarCategoriesContainer {
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 20px;
}
.rentalCarCategoriesRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}
.columnEight {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
}
.columnTwo {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.rentalCarCategoriesRow .columnTitle {
  font-size: 18px;
  line-height: normal;
  color: #026786;
  font-weight: 300;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}
.rentalCarCategoriesRow .columnTitle::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 92px;
  height: 1px;
  background: #18a3ad;
}
.rentalCarCategoriesRow .categoriesListing {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.rentalCarCategoriesRow .categoriesListing li {
  font-size: 16px;
  line-height: 2;
  color: #026786;
  font-weight: 300;
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.rentalCarCategoriesRow .columnTwo .categoriesListing li {
  flex: 0 0 100%;
  max-width: 100%;
}

.predictionsDropDown {
  top: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background: #ffffff;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000024;
  border-radius: 4px;
  margin-top: 7px;
  padding: 10px;
  z-index: 1;
  /* opacity: 0;
  visibility: hidden; */
}
.isVisible {
  opacity: 1;
  visibility: visible;
}
.predictionsDropDown .currentLocation svg {
  margin: 0 10px 0 0;
}
.predictionsDropDown ul {
  margin: 0 0 10px;
}
.predictionsDropDown ul li {
  color: #0d6786;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  cursor: pointer;
  position: relative;
}
.predictionsDropDown ul li::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  width: 0;
  height: 100%;
  background: #0d6786;
}
.predictionsDropDown ul li:hover {
  opacity: 0.85;
}
.predictionsDropDown ul li:hover::before {
  width: 5px;
}
.predictionsDropDown .searchLabels {
  color: #b2b2b2;
  height: 40px;
  font-size: 16px;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 8px 20px;
}
.predictionsDropDown .suggestionTitle {
  font-size: 16px;
  line-height: normal;
  color: #b2b2b2;
}

/* Location landing page */
.sectionLocationCars {
  width: 100%;
}
.sectionLocationCars .columnListings {
  padding-bottom: 60px;
  background: #ffffff;
  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
}
.sectionLocationCars .sectionTitle {
  padding: 105px 0 60px;
  margin-bottom: 0;
  @media (max-width: 767px) {
    padding: 42px 0 10px;
    font-size: 30px;
  }
}
.sectionLocationCars .listingGrid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: 767px) {
    display: block;
  }
}
.sectionLocationCars .listingCardWrapper {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  margin-bottom: 50px;
  @media (max-width: 1024px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px 0 0;
    margin-bottom: 0;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 10px;
  }
}
.carsListingAndMaps {
  overflow: hidden;
}
.reusableMap {
  display: none;
}
.searchForTheLocation {
  max-width: 500px;
  height: 60px;
  border: 1px solid #fecd2a;
  background: #fecd2a;
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #026786;
  font-size: 20px;
  line-height: normal;
  text-decoration: none !important;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 18px;
    padding: 10px;
  }
}
.searchForTheLocation svg {
  margin-right: 10px;
  @media (max-width: 767px) {
    margin-right: 5px;
  }
}
.mobileMapHolder {
  @media (max-width: 767px) {
    height: 600px;
    padding: 0;
    position: relative;
  }
  &::after {
    content: '';
    @media (max-width: 767px) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
}
.locationLandingMapLayout {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 41.666667%;
  @media (max-width: 767px) {
    height: 100%;
    width: 100%;
    position: static;
  }
}

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.video {
  width: 280px;
  background-color: var(--matterColorAnti);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 313px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}
.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}

.bannerIntroSection {
  height: calc(100vh - 72px);
  min-height: 552px;
  max-height: 975px;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 991px) {
    height: auto;
    min-height: 251px;
    max-height: 975px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
}
.bannerIntroSection .container {
  @media (max-width: 991px) {
    position: static;
    opacity: 1;
    max-width: 338px;
    margin: 0 auto;
    z-index: 2;
    background: #00617B;
    left: 0;
    right: 0;
    border-radius: 4px;
    box-shadow: 0 0 15px #00000029;
    padding: 10px 20px;
    text-align: center;
    margin-top: -60px;
    order: 2;
  }
}
.bannerLogoBlock {
  max-width: 593px;
  @media (max-width: 1366px) {
    max-width: 250px;
  }
  @media (max-width: 991px) {
    max-width: 180px;
    margin: 0 auto;
  }
}
.bannerIntroContent {
  max-width: 600px;
}
.bannerIntroContent h1 {
  color: #0d6786;
  font-size: 48px;
  line-height: 1.229;
  font-weight: 900;
  letter-spacing: 0;
  margin: 0 0 25px;
  @media (max-width: 1366px) {
    font-size: 36px;
  }
  @media (max-width: 991px) {
    font-size: 28px;
    line-height: 1.25;
    color: #FFFFFF;
    margin: 0;
  }
}
.bannerIntroContent h1 p{
  display: inline-block;
  margin: 0 0 0 15px;
  vertical-align: middle;
  @media (max-width: 991px) {
    display: block;
    margin: 15px 0 0;
  }
}
.bannerIntroContent p {
  color: #4a4a4a;
  font-size: 28px;
  line-height: 1.357;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0 0 40px;
  @media (max-width: 1366px) {
    font-size: 24px;
    line-height: 1.4;
  }
  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 22px;
    color: #FFFFFF;
  }
}
.bannerIntroContent span {
  max-width: 35px;
  @media (max-width: 991px) {
    max-width: 23px;
  }
}
.bannerIntroContent span,
.bannerIntroContent strong {
  display: inline-flex;
  margin-right: 7px;
  vertical-align: middle;
}
.bannerIntroContent .approvedByPin {
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #57c965;
  font-size: 22px;
  line-height: normal;
  color: #ffffff;
  padding: 5px 17px;
  letter-spacing: 0;
  font-weight: 700;
  @media (max-width: 991px) {
    height: 21px;
    font-size: 15px;
  }
}
.bannerIntroContent .approvedByPin img {
  @media (max-width: 991px) {
    max-width: 45px;
  }
}
.bannerButton {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #fecd2a;
  font-size: 28px;
  font-weight: 900;
  color: #026786;
  padding: 7px 15px;
  width: 100%;
  border: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  @media (max-width: 991px) {
    height: 42px;
    font-size: 15px;
    font-weight: 500;
    max-width: 240px;
    margin: 0 auto 15px;
  }
}

.bannerIntroImageSection {
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
  @media (max-width: 991px) {
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    height: 0;
    padding-bottom: 56%;
  }
}
.bannerIntroImageSection img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  @media (max-width: 991px) {
    object-fit: cover;
    object-position: center center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.whyHostWithUs {
  padding: 60px 0;
  @media (max-width: 991px) {
    padding: 30px 0;
  }
}
.whyHostWithUs .container{
  max-width: 1200px;
}
.whyHostWithUs .sliderWrapper ul{
  @media (max-width: 991px) {
    bottom: auto;
    top: 180px;
  }
}
.whyHostWithUs .sliderWrapper ul li button{
  width: 10px;
  height: 10px;
}
.secTitle {
  text-align: center;
  margin-bottom: 60px;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}
.secTitle h2 {
  font-size: 44px;
  line-height: normal;
  color: #00617B;
  font-weight: 900;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 28px;
  }
}
.contentGrid {
  margin-bottom: 40px;
}
.contentBlock{
  @media (max-width: 991px) {
    max-width: 250px;
    margin: 0 auto;
  }
}
.contentGrid .contentBlockThumb {
  max-width: 245px;
  height: 175px;
  border-radius: 45px;
  margin-bottom: 55px;
  position: relative;
  @media (max-width: 991px) {
    max-width: 100%;
  }
}

.contentGrid .contentBlockThumb::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 45px;
  position: absolute;
  left: 0;
  top: 0;
  top: 10px;
  left: 10px;
  background: red;
  z-index: 1;
  display: none;
}
.contentGrid .contentBlockThumb img {
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
  border-radius: 45px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.contentGrid .contentBlockContent h4 {
  font-size: 30px;
  line-height: normal;
  font-weight: 900;
  color: #4a4a4a;
  margin-bottom: 15px;
  @media (max-width: 991px) {
    font-size: 24px;
  }
}
.contentGrid .contentBlockContent p {
  font-size: 24px;
  line-height: 1.08;
  color: #4a4a4a;
  @media (max-width: 991px) {
    font-size: 18px;
  }
}
.whyHostWithUs .btnRow .bannerButton {
  max-width: 274px;
  margin: 0 auto;
}


.bannerListing{
  padding: 0;
  margin: 0 0 50px 0;
  list-style: none;
  @media (max-width: 991px) {
    text-align: left;
    padding: 12px 0;
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    max-width: 270px;
    margin: 16px auto;
  }
}
.bannerListing li{
  display: flex;
  align-items: center;
  font-size: 25px;
  line-height: normal;
  color: #00617B;
  font-weight: 700;
  margin-bottom: 25px;
  @media (max-width: 1366px) {
    font-size: 20px;
    margin-bottom: 15px;
  }
  @media (max-width: 991px) {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 300;
  }
}
.bannerListing li .contentIcon{
  display: inline-block;
  width: 175px;
  margin-left: 7px;
  max-width: 175px;
  @media (max-width: 991px) {
    width: 100px;
    max-width: 100px;
  }
}
.bannerListing li .contentIcon img{
  display: block;
  width: 100%;
}
.bannerListing li .pointIcons{
  width: 42px;
  height: 42px;
  min-width: 42px;
  display: block;
  flex: 1;
  margin-right: 20px;
  max-width: 42px;
  @media (max-width: 1366px) {
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin-right: 15px;
    max-width: 30px;
  }
  @media (max-width: 991px) {
    width: 23px;
    height: 23px;
  }
}
.bannerListing li .pointIcons img{
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: contain;
}

.mobileBannerLogo{
  max-width: 215px;
  margin: 0 auto;
}
.helpUrlLink span{
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: middle;
}
.helpUrlLink span img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  margin-left: 7px;
}