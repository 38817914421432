@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

.page {
  padding: 0;
  padding-top: 0;
  overflow: auto;
  @media (--viewportLarge) {
    height: auto;
    padding-top: 0;
  }
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: -1;
}

.mobileTopbarContainer {
  position: unset;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 80;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: var(--SearchPage_containerHeight);
  }

}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  @media (--viewportMobile) {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.fullScale {
  flex-basis: 100%;
  max-width: 100%;
}

.error {
  color: var(--failColor);
}

.homeDeliveryText {
  color: var(--marketplaceColorDark);
  margin: 0;
  padding: 10px 20px 0;
  font-size: 18px;

  @media (--viewportLarge) {
    padding: 0 40px 20px;
    font-size: 22px;
  }

}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFilters {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0;
    margin-bottom: 0;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 60px 24px;

  @media (--viewportLarge) {
    padding: 0 40px 60px 40px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  background: #FCFCFC;
  @media (max-width: 767px) {
    padding: 30px 0 0 0;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  padding: 0 20px;
  @media (max-width: 991px) {
    padding: 30px 20px;
  }
  @media (max-width: 767px) {
    padding: 0 20px 30px;
  }
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: var(--SearchPage_containerHeight);
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.contactUs {
  position: absolute;
}

/* New design */
.filterSectionTop {
  padding: 20px 20px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.16);
  z-index: 4;
  @media (max-width: 991px) {
    padding: 30px 20px;
  }
  @media (max-width: 767px) {
    padding: 10px 20px;
  }
}
.filterSectionTop .filterSectionLeft {
  flex: 1;
}
.filterSectionTop .filterSectionRight {
  flex: 1;
  max-width: 150px;
  padding-bottom: 7px;
}
.filterSectionTop .resultsNumbers {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.35;
  min-height: 18px;
}
.filterSectionTop .resultsLocation {
  font-size: 24px;
  color: #0D6786;
  line-height: 1.35;
  font-weight: 700;
  min-height: 37px;
  @media (max-width: 767px) {
    display: none;
  }
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: static;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: normal;
  /* Layout */
  margin: 0;
  background-color: var(--matterColorBright);
}

.canCancelMessage {
  padding: 0 40px;
  margin-bottom: 30px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
  @media (max-width: 767px) {
    padding: 20px 20px 0;
    margin: 0;
  }
}
.canCancelMessage .canCancelMessageInner {
  padding: 20px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.canCancelMessageInner p {
  padding-left: 10px;
  margin: 0;
  line-height: normal;
  font-weight: 300;
}

.notResultFoundView{
  max-width: 387px;
  margin: 0 auto;
  padding: 50px 0;
  @media (max-width: 767px) {
    max-width: 250px;
  }
}
.notResultFoundView .imageThumb{
  margin-bottom: 40px;
}
.notResultFoundView h2{
  max-width: 387px;
  margin: 0 auto;
  font-size: 20px;
  line-height: normal;
  color: #0D6786;
  text-align: center;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}


.row {
  display: -webkit-flex; /* Safari */
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  align-items: center;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.column {
  padding-left: 20px;
  padding-right: 20px;
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column3 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column5 {
  flex: 0 0 40%;
  max-width: 40%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column7 {
  flex: 0 0 60%;
  max-width: 60%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column8 {
  flex: 0 0 66.666%;
  max-width: 66.666%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.mobileFilterWrapper {
  padding: 0 20px;
}
.rootClassName {
  padding: 0;
}
.clearbutton {
  display: none;
}