@import '../../marketplace.css';

.tripreceipt {
  margin: 0 auto;
  padding: 18px;
  color: #333;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px; 
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo {
  width: 100px;
  height: auto;
}

.title {
  font-weight: 700;
  font-size: 28px; 
  color: #026786;
  margin: 0;
}

.trip {
  font-weight: 500;
  font-size: 16px; 
  color: #7C7C7C;
  margin: 2px 0 0 0;
}

.printButton {
  font-weight: 500;
  color: #0AA3AD;
  background: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  text-decoration: underline;
}

p, span {
  margin: 0;
  padding: 0;
  line-height: inherit; /* Ensure both follow the same line-height */
}
/* Vehicle Info Section */
.vehicleInfo {
  margin: 12px 0; 
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.vehicleInfo h2 {
  color: #026786;
  font-size: 20px; 
  margin: 0 0 6px 0; 
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
}

.listingTitle {
  font-weight: 700;
}

.licensePlate {
  font-weight: 700;
  color: #026786;
}

.dateInfo, .bookedOn {
  font-size: 16px; 
  font-weight: 500;
  color: #4A4A4A;
  line-height: 1.5;
}


/* Charges Section */
.chargesSection {
  margin: 24px 0; 
}

.chargesSection h3 {
  color: #026786;
  font-size: 16px; 
  margin: 0 0 16px 0;
  font-weight: 700;
}

.chargeItem, .total {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 8px; 
}

.itemName, .total span:first-child {
  flex-shrink: 0;
  white-space: nowrap;
  margin-right: 8px;
  font-size: 16px; 
  color: #4A4A4A;
  font-weight: 500;
}

.dashedLine {
  flex-grow: 2;
  border-bottom: 1px dashed #707070;
  margin-bottom: 4px;
}

.itemAmount, .total span:last-child {
  flex-shrink: 0;
  margin-left: 8px;
  white-space: nowrap;
  font-size: 16px;
  color: #4A4A4A;
  font-weight: 500;
}

.total {
  padding-top: 0px;
}

.total span:first-child, .total span:last-child {
  font-weight: 700;
}

.gstNote {
  color: #7C7C7C;
  font-size: 12px; 
  text-align: right;
  padding-right: 2px;
  margin-top: -10px;
  font-weight: 500;
}

/* Payment Details Section */
.paymentBox {
  border: 2px dashed #B2B2B2;
  margin: 0 0;
  background: #FCFCFC;
  padding: 16px; 
  margin-top: -10px;
  border-radius: 2px;
}

.paymentHeader {
  color: #666;
  font-size: 16px; 
  padding: 4px 0;
  font-weight: 500;
}

.paymentContent {
  padding: 8px 0; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardDetails {
  color: #666;
  font-size: 12px; 
  font-weight: 500;
}

.amount {
  color: #333;
  font-size: 16px; 
  font-weight: 500;
}

.paymentBox.noPayment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentBox.noPayment .paymentHeader {
  margin-right: 8px;
}

.paymentBox.noPayment .amount {
  margin-left: 8px;
}

/* Final Total Section */
.finalTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0; 
  padding-top: 16px; 
  border-top: 1px solid #e6e6e6;
}

.finalTotal h2 {
  color: #026786;
  font-size: 28px; 
  font-weight: 500;
  margin: 0;
}

.finalTotal p {
  font-size: 20px; 
  font-weight: 500;
  margin: 0;
  min-width: 100px;
  text-align: right;
  color: #333;
}

/* Footer Section */
.footer {
  margin-top: 32px;
  color: #666;
  font-size: 20px;
  line-height: 1.5;
}

.footer  {
  margin: 0 0 12px 0;
  color: #1A1A1A;
  font-size: 16px; 
  font-weight: 500;
}

.companyInfo {
  margin: 8px 0; 
}

.companyInfo span {
  color: #026786;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.footer address {
  font-style: normal;
  line-height: 1.6;
  color: #7C7C7C;
  font-size: 16px;
  margin-top: 8px; 
  font-weight: 500;
}

.divider {
  border: none;
  border-top: 1px solid #e6e6e6;
  margin: 16px 0; 
}

@media print {
  .tripreceipt {
    padding: 0;
    box-shadow: none;
  }

  .printButton {
    display: none;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 70px; /* Adjust size as needed */
  }
}