@import '../../marketplace.css';

.readyToGoSection {
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.topImage {
  position: relative;
  flex-basis: 100%;
  display: flex;
  height: 300px;

  @media (--viewportMedium) {
    height: 400px;
  }

  @media (--viewportLarge) {
    height: 550px;
  }

  @media screen and (min-width: 1200px) {
    height: 650px;
  }

  @media screen and (max-width: 767px) {
    height: 250px;
  }
}

.infinityImgWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px 20px 35px 20px;
  
  @media (--viewportMedium) {
    padding: 65px 100px 65px 100px;
  }

  @media (--viewportLarge) {
    padding: 90px 150px 65px 150px;
  }

  @media (--viewportLarge) {
    padding: 90px 150px 65px 150px;
  }
}

.infinityImg {
  object-fit: contain;
  max-height: 500px;
  max-width: 100%;
  display: block;
  margin: auto;
}

.leftImageSide {
  flex-basis: 50%;
  background-color: var(--marketplaceColor);
}

.rightImageSide {
  flex-basis: 50%;
  background-color: var(--marketplaceColorDark);
}

.wrapperBox {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;

  @media (--viewportLarge) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.leftSideBox {
  flex-basis: 50%;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: right;
  
  padding: 0px 25px 40px 5px;

  @media (--viewportMedium) {
    padding: 0px 65px 40px 70px;
  }

  @media (--viewportLarge) {
    padding: 0px 65px 70px 80px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 75px 140px calc(50vw - 528px - 75px);
  }
}

.rightSideBox {
  flex-basis: 50%;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);

  padding: 0px 5px 40px 25px;

  @media (--viewportMedium) {
    padding: 0px 70px 40px 65px;    
  }

  @media (--viewportLarge) {
    padding: 0px 80px 70px 65px;    
  }
  
  @media screen and (min-width: 1200px) {
    padding: 0px calc(50vw - 528px - 75px) 140px 75px;
  }
}

.leftSideButton {
  flex-basis: 50%;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: right;

  padding: 0px 10px 40px 20px;

  @media (--viewportMedium) {
    padding: 0px 65px 50px 80px;    
  }

  @media (--viewportLarge) {
    padding: 0px 65px 70px 80px;    
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 75px 140px calc(50vw - 528px - 75px);
  }
}

.leftSideButton .text{
  @media (--viewportMobile){
    font-size:16px;
    margin: 0;
    line-height: initial;
  }
}

.rightSideButton {
  flex-basis: 50%;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);

  padding: 0px 20px 40px 10px;

  @media (--viewportMedium) {
    padding: 0px 80px 50px 65px;    
  }

  @media (--viewportLarge) {
    padding: 0px 80px 70px 65px;    
  }

  @media screen and (min-width: 1200px) {
    padding: 0px calc(50vw - 528px - 75px) 140px 75px;
  }
}

.textBox {

}

.textBoxTitle {
  color: var(--matterColorLight);
  font-size: 16px;

  @media screen and (min-width: 1200px) {
    font-size: 38px;
    line-height: 38px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.textBoxDesc {
  color: var(--matterColorLight);
  font-size: 14px;

  @media screen and (min-width: 1200px) {
    font-size: 27px;
    line-height: 32px;
  }
}

.leftSideBox.textBox {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 0;
    right: -15px;
    background-color: var(--marketplaceSecondaryColor);
    display: flex;
    font-size: 20px;
    padding: 5px;
    font-weight: bold;
    color: var(--marketplaceColorDark);
    justify-content: center;
    align-items: center;
  }

  &::after {
    content: '';
    position: absolute;
    width: 6px;
    border-left-width: 6px;
    border-left-color: var(--marketplaceSecondaryColor);  
    top: 30px;
    bottom: 0px;
    right: -3px;
    background-color: var(--marketplaceSecondaryColor);
  }

  @media (--viewportMedium) {
    &::before {
      width: 60px;
      height: 60px;
      right: -30px;
      font-size: 50px;
    }

    &::after {
      top: 60px;
      width: 8px;
      border-left-width: 8px;
      right: -4px;

    }
  }
}

.leftSideBox.textBox:first-child {

  &::before {
    content: '';
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/howitwork/logoList.png');
    background-size: contain;
    background-position: center;
    background-color: transparent;
  }

  &::after {
    content: '';
    border-left-style: dashed;
    background-color: transparent;
  }
}

.leftSideBox.textBox:nth-last-child(2) {
  &::after {
    content: '';    
    border: none;
    background-color: transparent;
  } 
}

.leftSideBox.textBox:nth-child(3)::before {
  content: '1';
}

.leftSideBox.textBox:nth-child(5)::before {
  content: '2';
}

.leftSideBox.textBox:nth-child(7)::before {
  content: '3';
}

.leftSideBox.textBox:nth-child(9)::before {
  content: '4';
}

.leftSideBox.textBox:nth-child(11)::before {
  content: '5';
}

.findACar {
  @apply --marketplaceButtonStyles;
  display: block;
  padding-top: 0;
  line-height: 50px;
  min-height: 50px;
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);
  border-radius: 5px;
  /* width: 48%; */

  &:hover, &:focus {
    background-color: var(--marketplaceSecondaryColorLight);
  }

  @media (--viewportMedium) {
    display: block;
    padding-top: 0;
    min-height: 65px;
    line-height: 65px;
    /* width: 150px; */
  }

  @media (--viewportLarge) {
    width: 254px;
    margin-left: auto;
  }
}

.listYourCar {
  @apply --marketplaceButtonStyles;
  display: block;
  padding-top: 0;
  min-height: 50px;
  line-height: 50px;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 5px;
  /* width: 48%; */

  &:hover, &:focus {
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportMedium) {
    display: block;
    min-height: 65px;
    line-height: 65px;
    padding-top: 0px;
    width: 220px;
  }

  @media (--viewportLarge) {
    width: 254px;
  }
}