@import '../../marketplace.css';

.root {
  position: relative;
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;
  max-width: 1240px;
  padding: 60px 20px 160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
  @media (max-width: 767px) {
    padding: 0 20px 150px;
    position: relative;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;
  max-width: 200px;
  min-width: 200px;

  @media (--viewportLarge) {
    /* padding: 128px 0 82px 36px; */
    padding: 0;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 128px 0 82px calc((100% - 1056px) / 2); */
    padding: 0;
  }

  @media (max-width: 767px) {
    display: none;
    width: auto;
    max-width: 100%;
    margin-top: 5px;
    border: 0;
    box-shadow: 0 0 4px rgb(0, 0, 0, 0.15);
    border-radius: 4px;
    position: absolute;
    left: 20px;
    top: 0;
    z-index: 1;
    background: #ffffff;
    right: 20px;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;
  margin-bottom: 10px;
  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 0px;
    @media (--viewportLarge) {
      padding-right: 24px;
    }
    @media (max-width: 767px) {
      padding-right: 10px;
    }
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
  @media (max-width: 767px) {
    margin: 0;
  }
}

.tab a {
  padding: 0 10px;
  margin: 0;
  height: 47px;
  width: 80%;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    height: 40px;
  }
}

.panel {
  flex-grow: 1;
  padding-bottom: 60px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  @media (--viewportMedium) {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  @media (--viewportLarge) {
    padding: 0;
    /* padding: 60px 36px 82px 59px; */
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 60px calc((100% - 1056px) / 2) 82px 59px; */
    padding: 0;
  }
  @media (max-width: 767px) {
    padding: 20px 0 0;
    border: 0;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.callUs {
  position: absolute;
  z-index: 10;

  @media (--viewportMedium) {
    top: 50px;
    right: 10px;
  }

  @media (--viewportLarge) {
    top: 5px;
    right: 15px;
  }
}

.tab .tabLink {
  display: flex;
  position: relative;
  border: 0;
}
.tab .tabLink:hover {
  color: #00a3ad;
}
.tab .tabLink::after {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 3px;
  border-radius: 5px;
  background: #00a3ad;
  visibility: hidden;
  opacity: 0;
}
.tab .tabSelectedLink {
  color: #00a3ad;
  font-weight: 900;
}
.tab .tabSelectedLink::after {
  visibility: visible;
  opacity: 1;
}
.mobileToggleMenuOuter {
  padding: 20px 20px 0;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
.mobileToggleMenu {
  height: 54px;
  padding: 7px 60px 7px 10px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 20px;
  background-image: url(https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/updatelisting/arrow-down-gray.svg);
  background-size: 16px 16px;
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
}

.navOpen {
  display: block;
  z-index: 999;
}

.tab {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    justify-content: space-between;
    padding-right: 10px;
  }
}
