@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  padding: 40px 20px 20px;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    & > div {
      flex-basis: 900px;
    }
  }
}

.title {
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
}

.description {
  font-size: 18px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  margin-top: 30px;
}

.compare {
  background-color: var(--marketplaceColor);
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
}

.compareTitle {
  font-size: 24px;
  line-break: 30px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);

  @media (--viewportLarge) {
    font-size: 32px;
    line-height: 40px;
  }
}

.flexLogo {
  height: 22px;
  width: auto;

  @media (--viewportLarge) {
    height: 33px;
    width: auto;
  }
}

.compareTable {
  width: 100%;
  margin-top: 15px;
  word-break: break-word;
  & > * {
    font-size: 14px;
    line-height: 18px;
    font-weight: var(--fontWeightSemiBold);
    color: var(--matterColorLight);

    @media (--viewportLarge) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  & > tbody > tr > td {
    padding-top: 20px;
    flex: 1;
    text-align: center;
  }

  & > tbody > tr > td:first-child {
    flex: 2;
    text-align: left;
  }
}

.total {
  font-weight: var(--fontWeightBold);
}

.smallText {
  font-size: 12px;
  line-height: 15px;
  font-weight: var(--fontRegular);
  color: var(--matterColorLight);
  margin-top: 20px;

  @media (--viewportLarge) {
    font-size: 18px;
    line-height: 24px;
    margin-top: 30px;
  }
}
