.hiwSection {
    width: 100%;
    padding: 100px 80px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media only screen and (max-width: 767px) {
        padding: 50px 16px;
        gap: 16px;
    }
}


.sectionTitle {
    margin-bottom: 40px;
    margin-left: 0px;

    @media only screen and (max-width: 767px) {
        text-align: center;
        margin-bottom: 0px;
    }
}

.showDesktop {
    @media only screen and (max-width: 768px) {
        display: none !important;
    }
}

.showMobile {
    display: none !important;

    @media only screen and (max-width: 768px) {
        display: block !important;
    }
}

.heading {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 3.1rem;
    color: #222;
    margin: 0;

    @media only screen and (max-width: 767px) {
        font-size: 1.4rem;
        line-height: 1.9rem;
    }
}

.imageWrapper {
    text-align: center;
}

.image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}



.hiwList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px 24px;

    @media only screen and (max-width: 767px) {
        margin-top: 24px;
        padding: 0;
    }
}

.scrollable {
    max-height: 500px;
    /* Restrict height to show only 3 items */
    overflow-y: scroll;
    padding-right: 10px;
    /* Add padding to avoid scrollbar overlapping content */
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #E8E8E8 #f9f9f9;
    /* For Firefox */

    /* Custom scrollbar for WebKit browsers */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f9f9f9;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E8E8E8;
        border-radius: 5px;
    }
}

.hiwItem {
    background: #FFFFFF;
    padding: 24px 16px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    border: 1px solid #E8E8E8;
}

.hiwItem:last-child {
    margin-bottom: 0px;
}

.step {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  
}

.stepNumber{
    font-size: 1rem;

    @media only screen and (max-width: 767px) {
        font-size: 0.7rem;
      }
}

.titleWrapper{
    display: flex;
    align-items: center;
    gap: 8px;
}

.stepNumberWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.6rem;
    height: 1.4rem;
    border-radius: 31px;
    background: #00a3ad;
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

.stepTitle {
    font-size: 1.4rem;
    font-weight: 600;
    color: #222222;
    margin: 24px 0px 8px;
    line-height: 1.9rem; 
    letter-spacing: -0.078px;

    @media only screen and (max-width: 767px) {
      font-size: 0.8rem;
      line-height: 1.4rem; 
    letter-spacing: -0.078px;
    margin: 0;
    }

}

.stepDescription {
    font-size: 0.9rem;
    font-weight: 400 !important;
    line-height: 1.3rem; 
    letter-spacing: -0.078px;
    color: #7C7C7C;
    margin: 0;
    @media only screen and (max-width: 767px) {
        font-size: 0.8rem;
      }

}


.buttonContainer {
    text-align: center;
}

.btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;


    @media only screen and (max-width: 767px) {
        margin-top: 8px;
    }
}

.btnWrapper button {
    text-align: center;
    width: 100%;

    @media only screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
    }
}

.btn {
    font-size: 0.8rem;
    line-height: normal;
    font-weight: 500;
    padding: 12px 15px;
    text-transform: initial;
    border-radius: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 9.4rem;
    height: 2.4rem;
    min-height: 2.4rem;
    width: auto;
    max-width: 100%;
    gap: 4px;
    font-family: 'Inter', sans-serif;
    transition: 0.3s all cubic-bezier(0.18, 0.52, 0.49, 0.85);
    color: #026786;
    cursor: pointer;
}

.btnPrimary {
    background: #FFC60F;
    border: 1px solid #ffcd2d;
}

.btnPrimary:hover,
.btnPrimary:focus {
    background: #F8CE47;
}

.modalContainer {
    @apply --marketplaceModalBaseStyles;
    min-height: 100vh;
    height: 100%;
    padding: 40px 0 0 0;

    @media (--viewportMedium) {
        padding: 0px;
        flex-basis: 576px;
        min-height: 0;
        min-width: 80vw;
        height: 48vw;
        margin-top: calc(50vh - 30vw);
        border-bottom: 0px solid transparent;
    }

    @media (--viewportLarge) {
        margin-top: calc(50vh - 25vw);
        min-width: 80vw;
        height: 48vw;
    }

    @media (--viewportMLarge) {
        margin-top: calc(50vh - 20vw);
        min-width: 66vw;
        height: 40vw;
    }
}

.modalCloseButton {
    top: 0px;
    right: 0px;
    padding: 10px;
    color: var(--matterColorDark);

    @media (--viewportMedium) {
        color: var(--matterColorLight);
        top: -40px;
    }

    &:hover {
        color: var(--matterColorAnti) !important;
    }
}

.videoContentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    @media (--viewportMedium) {
        display: block;
    }
}

.iframeVideo {
    width: 100%;
    height: 40%;

    @media (--viewportMedium) {
        height: 100%;
    }
}

.contentModal {
    height: 100%;
}

.closebtn{
    cursor :pointer;
font-weight: 700;
color: white;
padding: 10px;
border: 2px solid white;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
width: 40px;
height: 40px;
margin-bottom: 20px;
}