@import '../../marketplace.css';

.heroSection {
  width: 100%;
}

.heroImage {
  width: 100%;
  height: 40vh;
  /* max-height: 650px; */
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAGuest/shutterstock_1410313523-min.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media (--viewportMedium) {
    
  }

  @media (--viewportMLarge) {
    height: 72vh;
  }
}

.joinUsNowContainer {
  background-color: var(--marketplaceColorDark);
  width: 100%;
}

.joinUsNow {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: auto;
  margin: auto;

  @media (--viewportMedium) {
    align-items: flex-start;
    margin: 0 7.5vw;
    padding: 20px 0px;    
  }

  @media screen and (min-width: 1200px) {
    margin: auto;
    max-width: 1100px;
    flex-direction: row;
    justify-content: space-between;
    padding: 36px 0px;
  }
}

.joinUsNowText {
  font-size: 24px;
  font-weight: bold;
  color: var(--matterColorLight);
  max-width: 335px;
  text-align: center;
  font-weight: 900;
  line-height: normal ;
  margin-top: 0;
  @media (--viewportMedium) {
    font-size: 38px;
    text-align: left;
    max-width: 100%;
  }

  @media (--viewportMLarge) {
    margin-bottom: 0px;
    font-size: 48px;
  }
}

.newlineMobile {

  @media (--viewportMedium) {
    display: none;
  }
}