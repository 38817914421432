@import '../../marketplace.css';
.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
  padding: 30px 40px;
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 30px;
  }
}
.titleSection {
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
}
.title {
  color: #026786;
  margin: 0 0 10px;
  font-size: 24px;
  line-height: normal;
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
  letter-spacing: normal;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: normal;
    font-weight: 700;
  }
}

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

/* For grid design */
.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  margin-bottom: 25px;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.column {
  padding-left: 20px;
  padding-right: 20px;
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.category {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  background-color: #00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.transmissionPeople {
  flex-basis: 30%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 2;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.peopleContainer {
  margin-bottom: 82px;

  @media (--viewportMedium) {
    margin-bottom: 92px;
  }
}

.noneDisplay {
  display: none;
}

/* Input design */
.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}
.newInput input {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  background-position: calc(100% - 15px) center;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 15px 15px;
  font-size: 20px;
  margin: 0;
  resize: none;
  box-sizing: border-box;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}

/* For sticky submit button design */

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}
.stickyButtons .stickButtonsContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}
.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    background: #eff7f8;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}
.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}
.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.validationClassName {
  position: relative !important;
}
.inlineInputs {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    padding-top: 10px;
  }
}
.inlineInputs li {
  margin-bottom: 0;
  margin-right: 30px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}
.customLabel label,
.peopleContainer label,
.features legend {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
}

.keyFeatures {
  margin-bottom: 32px;
}
.keyFeatures legend {
  font-size: 20px;
  font-weight: 300;
}
.customRadioLabel legend {
  font-size: 20px;
  font-weight: 300;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--marketplaceColor);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.downloadPdf {
  background: #00a3ad0d;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  min-height: 54px;
  display: flex;
  padding: 12px 15px;
  font-size: 20px;
  line-height: normal;
  align-items: center;
  background-image: url('../../assets/download-icon.svg');
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.pageContent {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.mainContentWrapper {
  padding: 20px;
}

.mainContentWrapper p {
  font-size: 16px;
  font-weight: 500 !important;
  line-height: normal;
  margin: 0 0 15px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.mainContentWrapper p:last-child {
  margin: 0;
}

.downloadWrapper {
  padding-top: 20px;
}
