@import '../../marketplace.css';

.root {
  padding-top: var(--topbarHeight);
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--marketplaceColor);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.calenderSyncContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid var(--marketplaceColor);
  padding: 15px;
}

.googleLogoContainer {
  display: flex;
}

.googleLogo {
  align-self: center;
  padding-right: 25px;
}

.btnText,
.emailText {
  color: var(--marketplaceColor);
}

.emailText {
  margin-bottom: 5px;
}

.statusContainer .statusText {
  color: var(--successColor);
}

.disconnect {
  display: flex;
  cursor: pointer;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 50px;
  height: 50px;
}

.crossIcon {
  width: 20px;
  height: 20px;
}

.infoText {
  color: var(--matterColorNear);
}
