@import '../../marketplace.css';
html {
  scroll-behavior: smooth;
}
.landingContainer {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  position: relative;
}
.landingRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 20px;
  padding-right: 20px;
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.heroSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #00617B;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 68px 0;
  position: relative;
  @media (max-width: 991px) {
    background: transparent;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}

.form {
}

.mobileHero {
  display: block;
  height: 150px;
  width: 100%;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAHost/become-a-host-bg-min.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchBox {
  border-radius: 5px;
  background-color: #0aa3ad;
  padding: 30px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  @media (--viewportMedium) {
    max-width: 600px;
    width: 100%;
    padding: 40px;
  }
  @media (max-width: 767px) {
    padding: 30px 20px;
    border-radius: 0;
    margin: 0 -15px;
    box-shadow: none;
  }

  @media (--viewportLarge) {
  }
}

.searchBoxTitle {
  font-size: 34px;
  line-height: normal;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

.searchBoxDesc {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColor);
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 24px;
  }
}

.fieldsWrapper {
  width: 100%;
}

.inputField {
  position: relative;
}
.inputField input + div {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}
.inputField:focus {
  outline: none !important;
}
.inputField label {
  position: absolute;
  padding: 5px 10px;
  left: 0;
  top: 0;
  font-size: 12px;
  color: #ffffff;
  font-weight: 100;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.yearField {
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: 45%;
  }
}

.milesField {
  flex-basis: 50%;

  @media (--viewportMedium) {
    flex-basis: 45%;
  }
}

.input,
.selectField {
  height: 60px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 20px 10px 3px;
  font-size: 18px;
  color: #ffffff;
  border: 0;
  margin-bottom: 20px;
  font-weight: 500;
  &::placeholder {
    color: #ffffff;
    font-weight: 500;
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
.input + div {
  position: relative;
}

.input:hover,
.input:focus,
.selectField:hover,
.selectField:focus {
  border: 0 !important;
  outline: none !important;
}

.footerBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  @media (max-width: 767px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.perValue {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: -17px;
  font-weight: 300;
}
.valueInfo {
  flex: 0 0 100%;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 5px;
}
.breakDown {
  font-size: 36px;
  height: 40px;
  font-weight: 900;
  color: #fecd2a;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &::after {
    content: '/ month';
    font-weight: 100;
    position: static;
    font-size: 16px;
    line-height: 20px;
    bottom: -20px;
    display: inline-block;
    width: auto;
    min-width: 75px;
    display: none;
  }
  @media (max-width: 767px) {
    position: static;
    margin: 20px 0;
    height: auto;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (--viewportMedium) {
    font-size: 36px;
    margin-bottom: 0px;

    &::after {
      left: 0;
    }
  }
}

.listYourCar {
  @apply --marketplaceButtonStyles;
  border-radius: 5px;
  padding: 7px 15px;
  display: inline-flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-transform: none;
  max-width: 300px;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}

.explaination {
  position: relative;
  display: inline-block;
  /* width: 15px;
  height: 15px;
  margin-left: 5px; */
  font-size: 12px;
  color: var(--matterColorAnti);
  cursor: pointer;

  &::before {
    content: '?';
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    font-weight: bold;
    left: 0;
    border-radius: 50%;
    border: 1px solid var(--matterColorAnti);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.explaination:hover .explainationText {
  display: block;
}

.explainationText {
  display: none;
  position: absolute;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  padding: 15px 25px;
  left: -112px;
  bottom: 18px;
  margin-bottom: 25px;
  font-weight: normal;
  width: 250px;
  box-shadow: 3px 3px 10px var(--matterColorNegative);

  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}

.contentLeft {
  @media (max-width: 991px) {
    max-width: 334px;
    margin: 0 auto 40px;
    padding: 30px;
    background: #DEDEDE;
    border-radius: 30px;
  }
}
.contentLeft h3 {
  font-size: 38px;
  color: #ffffff;
  line-height: normal;
  @media (max-width: 991px) {
    font-size: 24px;
    color: #0D6786;
    font-weight:900;
  }
}
.contentLeft ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.contentLeft ul li {
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  display: flex;
  align-items: center;
  padding: 35px 0;
  @media (max-width: 991px) {
    padding: 15px 0;
  }
}
.contentLeft ul li p {
  font-size: 28px;
  line-height: 1.25;
  color: #ffffff;
  max-width: 460px;
  margin: 0;
  @media (max-width: 991px) {
    color: #4a4a4a;
    font-size: 16px;
  }
}
.contentLeft ul li span {
  border: 0;
  width: 65px;
  height: 60px;
  border-radius: 4px;
  border: 2px solid #ffffff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: normal;
  font-weight: 900;
  margin-right: 35px;
  @media (max-width: 991px) {
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: #0D6786;
    border-color: #0D6786;
    margin-right: 20px;
    font-size: 14px;
  }
}
