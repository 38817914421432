.extraDurationChargesRoot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00a3ad0d;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ebebeb;

  .extraDurationTitle {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 500;
  }

  .extraDurationText {
    color: #7c7c7c;
    font-size: 14px;
    font-weight: 500;
  }

  .extraDurationValue {
    color: #026786;
    font-size: 18px;
    font-weight: 900;
  }
}
