.modificationChargesRoot {
  margin-top: 20px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 15px;
  border-radius: 4px;

  @media (--viewportMobile) {
    border-top: 0;
    border-bottom: 1px solid #e6e6e6;
    margin-top: 0;
    border-radius: 0;
  }

  .head {
    display: block;
    color: #7c7c7c;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
  }

  .lineItemsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .lineItemName {
    display: block;
    color: #1a1a1a;
    font-weight: 500;
    font-size: 18px;
  }

  .lineItemValue {
    display: block;
    color: #1a1a1a;
    font-weight: 900;
    font-size: 18px;
  }

  .plus {
    color: #00a3ad;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}
