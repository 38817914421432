h6{
    margin: 0;
}

h2{
    margin: 0;
}

.weGotYourBackSection {
    width: 100%;
    height: 100%;
    padding: 100px 80px;
    background: #F4F6FA;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;

    @media only screen and (max-width: 767px) {
        padding: 50px 16px;
        gap: 24px;
    }
}

.showDesktop {
    @media only screen and (max-width: 768px) {
        display: none !important;
    }
}

.showMobile {
    display: none !important;

    @media only screen and (max-width: 768px) {
        display: block !important;
    }
}

.sectionTitle {
    margin-bottom: 40px;

    @media only screen and (max-width: 767px) {
        text-align: center;
        margin-bottom: 24px;
    }
}

.heading {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 3.1rem;
    text-align: left;
    color: #222;
    margin: 0;
    word-wrap: break-word;
    letter-spacing: -0.1rem;

    @media only screen and (max-width: 767px) {
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: -0.0039rem
    }
}

.featuresGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    column-gap: 40px; 
    width: 100%;
    height: auto;
   

    @media only screen and (max-width: 767px) {
        gap: 16px;
        grid-template-columns: repeat(1, 1fr);
    }
}

.featureCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 24px;
    padding: 1.2rem 0.8rem;
    gap: 8px;
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    @media only screen and (max-width: 767px) {
        /* flex-direction: column; */
        align-items: flex-start;
    }
}

.icon {
    width: 1.6rem;
    height: 1.6rem;
}

.featureTitle {
    color: #222222;
    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: none;
    color: #222222;
    line-height: 1.9rem;
    margin: 0;
    word-wrap: break-word;
    padding: 0;

    @media only screen and (max-width: 767px) {
        font-size: 1rem;
        line-height: 1.4rem;
    }
}

.featureDescription {
    color: #7c7c7c;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-family: Inter;
    font-weight: 400 !important;
    line-height: 1.3rem;
    margin: 0;
    word-wrap: break-word;

    @media only screen and (max-width: 767px) {
        font-size: 0.8rem;
    }
}

.buttonContainer {
    text-align: center;
}

.btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: 767px) {
        margin-top: 0px;
    }
}

.btnWrapper button {
    text-align: center;

    @media only screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
    }
}

.btn {
    font-size: 0.9rem;
    line-height: normal;
    font-weight: 600;
    padding: 12px 15px;
    text-transform: initial;
    border-radius: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 9.4rem;
    height: 2.4rem;
    min-height: 2.4rem;
    width: 100%;
    gap: 4px;
    max-width: 100%;
    font-family: 'Inter', sans-serif;
    transition: 0.3s all cubic-bezier(0.18, 0.52, 0.49, 0.85);
    color: #026786;
    cursor: pointer;
}

.btnPrimary {
    background: #FFC60F;
    border: 1px solid #ffcd2d;
}

.btnPrimary:hover,
.btnPrimary:focus {
    background: #F8CE47;
}