@import '../../marketplace.css';

.fieldRoot {}

.inputRoot {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}

.selectWrapper {
  flex-basis: calc(33% - 12px);

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.selectWrapper:not(:first-child) {
  @media screen and (max-width: 500px) {
    margin-top: 15px;
  }
}

.select {
  display: inline-block;

  /* Border */
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }
}

.notSet {
  color: var(--matterColorAnti);
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}

.dobMobileViewContainer {
  width: 100%;
}

.numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  padding: 0;

  @media (--viewportMobile) {
    font-size: 16px;
    /* margin-right: 5px; */
    color: #80B2C2;
  }
}

.numbers input {
  padding: 0;
  height: 60px;
  font-size: 18px;
  color: var(--matterColorAnti);
  /* border-radius: 4px; */

  @media (--viewportMobile) {
    width: 40px;
    height: 44px;
  }
}

.dobContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  column-gap: 10px;
}

.dobGroupContainer {
  display: flex;
  flex-direction: row;
}

.dobGroupContainer input {
  margin: 0;
}

.dobGroupContainer input:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dobGroupContainer input:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dobGroupContainer input:nth-child(n+1) {
  border: 1px solid #E6E6E6;
  border-right: 0px;
}
.dobGroupContainer input:nth-child(n+2) {
  border: 1px solid #E6E6E6;
}

.dobGroupContainer input:nth-child(n+3) {
  border: 1px solid #E6E6E6;
  border-left: 0px;
}
