@import '../../marketplace.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalTitleConfirmMobile {
  font-size: 30px;
  color: var(--marketplaceColorDark);
  margin-bottom: 10px;
  margin-top: 0;
  @media (--viewportMobile) {
    font-size: 28px;
  }
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

.helperMVLink {
  @apply --marketplaceModalHelperLink;
  color: var(--marketplaceColorDark);
  font-size: 20px;
  line-height: 32px;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.helperMVText {
  @apply --marketplaceModalHelperText;
  color: var(--marketplaceColorDark);
  font-size: 20px;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.bottomMVWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: center;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}

.invisibleIcon {
  visibility: hidden;
}

.inputCodeWrapper {
  margin: 60px 0 90px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmModalStyles {
  margin: 0;
}

.inputCode {
  width: 80px;
  margin-top: 15px;
  font-size: 20px;
  line-height: 32px;
  text-align: center;

  &::placeholder {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }
}

.phoneImage {
  width: 134px;
  height: 134px;
}

.verifyPhoneSuccess {
  font-size: 30px;
  font-weight: bold;
  color: var(--marketplaceColor);
}

.resendSuccessMessage {
  font-size: 18px;
  margin-top: 20px;
  color: var(--marketplaceColor);
}
