.instantCarRetalSection {
    display: flex;
    width: 100%;
    padding: 100px 80px;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    @media only screen and (max-width: 767px) {
        padding: 25px 16px 50px 16px;
        gap: 10px;
    }
}

.container {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    width: 100%;

    @media only screen and (max-width: 767px) {
        flex-direction: column-reverse;
        gap: 16px;
        width: 100%;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: auto;
    width: 39%;
    height: 422px;
    /* padding: 32px 24px 0px 24px; */
    border: 2px solid #E8E8E8;
    border-radius: 24px;
    overflow: hidden;

    @media only screen and (max-width: 767px) {
        width: 100%;
        height: auto;
        /* padding: 24px 16px 0px 16px; */
        gap: 24px;
        align-self: stretch;
    }
}

.textSection {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 0px 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    @media only screen and (max-width: 767px) {
        /* width: 100%; */
        padding: 24px 16px 0px 16px;
        /* gap: 24px; */
        /* align-self: stretch; */
    }
}

.title {
    color: #00A3AD;
    font-family: 'Inter', 'sans-serif';
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: -2px;
    width: 100%;
    margin: 0px;
    word-break: break-word;

    @media only screen and (max-width: 1368px) {
        font-size: 36px;
        line-height: 46px;
        letter-spacing: -0.078px;
        align-self: stretch;
        width: 100%;
    }
}

.highlight {
    color: #222;
    font-family: 'Inter', 'sans-serif';
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: -2px;

    @media only screen and (max-width: 1368px) {
        font-size: 36px;
        line-height: 46px;
        letter-spacing: -0.078px;
    }
}

.description {
    color: #222;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500 !important ;
    line-height: 26px;
    letter-spacing: -0.078px;
    margin: 0px;
}

.signupBtn {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 40px;
    background: #FFCD05;
    margin: 0px 24px;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 767px) {
        margin: 0px 16px;
    }
}



.signupBtn:hover,
.signupBtn:focus {
    background: #F8CE47;
}

.btnText {
    color: #026786;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.078px;
}

.giftBox {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
}

.promo {
    display: flex;
    width: 100%;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: #00A3AD;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 8px 20px 10px 20px;
    }
}

.promoGiftBox {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    @media only screen and (max-width: 767px) {
        display: none;
    }
}

.promoText {
    color: #FFF;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @media only screen and (max-width: 1368px) {
        font-size: 12px;
    }
}

.instantCarImage {
    display: flex;
    width: 61%;
    height: 422px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/newMarketingLandingPage/Instant-car.svg') no-repeat center;
    background-size: cover;
    background-color: #FFCD05;

    @media only screen and (max-width: 767px) {
        height: 169px;
        width: 100%;
        padding: 24px;
        align-self: stretch;
    }
}

.info {
    background: rgba(26, 162, 172, 0.14);
    color: #fff;
    font-size: 14px;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    padding: 5px 30px;
    border-radius: 0 0 16px 16px;
    margin: 0 15px;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    /*height: 50px;*/
    box-sizing: border-box;
    border: 1.2px dashed rgba(26, 162, 172, 0.4);
    border-radius: 4px;
    cursor: pointer;
  
    @media only screen and (min-width: 1024px) {
      font-size: 14px;
    }
  }


  .info span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #1AA2AC;
  }





  .clipboardContainer{
    padding: 0 28px;

    @media only screen and (min-width: 767px) {
        padding: 0px 24px;
      }
  }