@import '../../marketplace.css';

.faqSection {
  width: 100%;
}

.innerWrapper {
  padding: 34px 20px;
  width: 100%;
  margin: auto;
  padding-top: 0;

  @media (--viewportMedium) {
    padding: 34px 0px;
    width: auto;
    max-width: 100%;
    margin: 0 7.5vw;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    padding-top: 34px;
  }

  @media screen and (min-width: 1200px) {
    margin: auto;
    padding-top: 34px;
    max-width: 1100px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900; 
  /* margin-bottom: 40px; */

  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
}

.listTextBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.subBox {
  flex-basis: 100%;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (--viewportMedium) {
    flex-basis: 100%;
  }

  @media (--viewportMLarge) {
    flex-basis: 45%;
  }
}

.question {
  border-bottom: 3px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 5px;
  cursor: pointer;
}

.answer {
  font-size: 20px;
  line-height: 30px;
  background-color: var(--marketplaceColor);
  padding: 5px 15px 30px;
  color: var(--matterColorLight);
  margin-top: 2px
}