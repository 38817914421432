.modificationDatesRoot {
  margin-top: 20px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 15px;
  border-radius: 4px;

  @media (--viewportMobile) {
    border-radius: 0;
  }
}

.head {
  display: block;
  color: #7c7c7c;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
}

.datesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item {
  }

  .original {
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 700;
  }

  .updated {
    color: #026786;
    font-size: 18px;
    font-weight: 700;
  }
}
