@import '../../marketplace.css';

.staticPageWrapper {

}

.coverImage {
  width: 100%;
  max-height: 70vh;
}

.heroSection {
  width: 100%;
}

.heroImage {
  width: 100%;
  height: 40vh;  
  /* max-height: 650px; */
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/howitwork/shutterstock_1457766557 copy-min.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/howitwork/shutterstock_1457766557 copy-min.jpg');
    background-image: image-set(url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/howitwork/shutterstock_1457766557 copy-min.jpg') 1x, url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/howitwork/shutterstock_1457766557 copy-min.jpg') 2x);    
  }

  @media (--viewportLarge) {
    height: 50vh;
  }

  @media screen and (min-width: 1200px) {
    height: 45vw;
  }
}

.joinUsNowContainer {
  background-color: var(--marketplaceColorDark);
  width: 100%;
  padding: 0px;

  @media (--viewportMedium) {
    padding: 25px 0px;
  }

  @media (--viewportMLarge) {
    padding: 0px;
  } 
}

.joinUsNow {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: auto;

  @media (--viewportMedium) {
    max-width: 650px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1056px;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0px;
  }
}

.joinUsNowText {
  font-size: 20px;
  font-weight: 900;
  color: var(--matterColorLight);
  margin-bottom: 10px;
  padding: 15px;
  text-align: center;
  margin-top: 0;
  @media (--viewportMedium) {
    margin-bottom: 20px;
    font-weight: 700;
    text-align: left;
    font-size: 28px;
    padding: 20px 0px;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 0px;
    font-size: 36px;
  }
}

.bolderText {
  font-weight: 900;
  display: block;
  font-size: 36px;
  letter-spacing: normal;
  margin: 0;
  @media (--viewportMedium) {
    display: inline;
  }
}

.joinUsNowButton {
  @apply --marketplaceButtonStyles;
  display: block;
  padding-top: 12px;
  min-height: 45px;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 0px;
  /* width: 48%; */

  &:hover, &:focus {
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportMedium) {
    border-radius: 5px;
    display: block;
    padding-top: 16px;
    min-height: 60px;
    /* width: 150px; */
  }

  @media (--viewportLarge) {
    width: 254px;
  }
}