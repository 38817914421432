@import '../../marketplace.css';

.partnerInfo {
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;

@media (--viewportMedium) {
  margin: 0 0 30px;
  border: 1px solid var(--borderColorGray);
  border-radius: 4px;
}
}

.avatarinfoContainer {
  display: flex;
}

.avatarWrapperProviderDesktop {
/* display: none; */
/* flex: 0.8; */

@media (--viewportLarge) {
  display: block;
}
}

.avatarDesktop {
  display: flex;
}

.userNameContainer {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
}

.userTypeText {
  font-size: 15px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  margin: 0;
  padding: 0;

@media (--viewportMedium) {
  font-size: 16px;
  line-height: 24px;
}
}

.userNameText {
  font-size: 20px;
  line-height: 22px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding: 0;

@media (--viewportMedium) {
  font-size: 24px;
  line-height: 24px;
}
}

.contactContainer {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1pt solid var(--marketplaceColor);
  border-radius: 22pt;
  align-items: center;
}

.contactContainer img {
  transform: scaleX(-1);
}

.userTelephone {
  font-size: 16px;
  line-height: 19px;
  color: var(--marketplaceColor);
  padding: 0 0 0 5px;

@media (--viewportLarge) {
  font-size: 20px;
  line-height: 24px;
}
}
