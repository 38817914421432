@import '../../marketplace.css';
.PickUpByAdmin {
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (--viewportMedium) {
    margin: 0 0 30px;
    border: 1px solid var(--borderColorGray);
    border-radius: 4px;
  }
}
.pickUpByAdminTitle {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightSemiBold);
  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 24px;
  }
}
.pickUpByAdminContent p{
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColor);
  margin-bottom: 8px;
  font-weight: 500 !important;
}
.pickUpByAdminButton {
  background-color: #0AA3AD;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 5px 0px;
}
.pickUpByAdminButtonContent {
  color: white;
  font-size: 20px;
}

.PickUpByAdmin {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #f9f9f9;
}

.lockboxCodeDisplay {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.lockboxDigit {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 1px solid #aaa;
  border-radius: 4px;
  background: #fff;
  color: var(--matterColorRPTitle);
  font-weight: bolder;
}

.updateButton {
  color: var(--marketplaceColor);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: underline;
}

.expireTimeContent {
  background: #e4f2f5;
  padding: 8px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
}

.error {
  color: red;
}

.unlockCodeWrapper span:last-child {
  margin-right: 0;
}

.unlockCodeWrapper .unlockcode {
  @media (--viewportMobile) {
    font-size: 25px;
    padding: 10px;
  }
}

.unlockCodeWrapper {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  gap: 6px;
  margin: 10px 0;

  @media (--viewportMediumMax) {
    gap: 12px;
  }

  @media (--viewportMobile) {
    gap: 7px;
    padding: 0 5px;
  }
}

.unlockCodeWrapper span {
  color: #00A3AD;
  font-size: 32px;
  padding: 10px 15px;
  border: 1px solid #EBEBEB;
  background: white;
  flex: 0 0 auto;
  border-radius: 8px;
  font-weight: 900;
}

.unlockCodeWrapper span:last-child {
  margin-right: 0;
}

.updateCodeWrapper {
  background: #00A3AD0D;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 4px;

  p {
    color: #7C7C7C;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    margin-top: 6px;
    font-weight: 500;
  }

  a {
    text-decoration: underline;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    font-weight: 500;
  }

  @media (--viewportMediumMax) {
    margin-bottom: 0;
  }

  @media (--viewportMobile) {
    margin-bottom: 0;
  }
}


.updateCodeWrapper {
  p {
    font-size: 18px;
    line-height: 22px;
  }
}

.unlockCodeWrapper .unlockcode {
  font-size: 27px;
  padding: 10px;

  @media (--viewportMediumMax) {
    font-size: 32px;
    padding: 10px 15px;
  }

  @media (--viewportMobile) {
    font-size: 23px;
    padding: 8px;
  }
}

.unlockCodeWrapper .unlockcodeNo {
  font-size: 18px;
  padding: 9px;
  font-weight: 700;
}

.unlockCodeWrapper .defulatNumber {
  font-size: 27px;
  padding: 9px;
  font-weight: 700;
}
