@import '../../marketplace.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportLarge) {
    flex-direction: row;
    max-width: 1128px;
    margin: 0 auto 57px auto;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.txInfo {
  margin-bottom: 210px;

  @media (--viewportLarge) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 56px;
    margin-bottom: 0;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.partnerInfo {
  display: flex;
  margin: 15px 24px 0;

  @media (--viewportLarge) {
    margin: 119px 0px 0px;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;
  flex: 0.8;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

.partnerName {
  flex: 1;
  color: var(--marketplaceColorDark);
  padding-left: 5px;
  padding-right: 5px;
}

.partnerName h3 {
  margin-top: 0;

  @media (--viewportLarge) {
    margin-top: 6px;
  }
}

.partnerName div {
  font-size: 16px;
  font-weight: 500;
}

.partnerPhone {
  flex: 1;
}

.headingOrder {
  margin: 29px 24px 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 152px 0 0 0;
    padding: 0;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  }
}

.mainTitle {
  display: block;
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 1px 0 0 0;
  display: none;

  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */

  @media (--viewportLarge) {
    margin: 123px 0 0 0;
    display: block;
  }
}

.detailCard {
  @media (--viewportLarge) {
    position: sticky;
    top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 409px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    margin-right: 48px;
    /* margin-bottom: 37px; */
    margin-bottom: 10px;
  }
}

.detailCardTitle {
  margin: 0 24px 10px;
  font-size: 20px;
  color: var(--marketplaceColor);
  font-weight: 500;

  @media (--viewportLarge) {
    margin: 14px 0 0;
  }
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}


.editTripHolder {
  margin: 48px;
  cursor: pointer;
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
}

.linkAddressColor {
  color: var(--matterColor);
  font-weight: 500;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--marketplaceColor);
  font-weight: 500;

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 18px 48px;
  }
}

.bookingBreakdownLongTermTitle {
  /* Font */
  color: var(--marketplaceColor);
  font-weight: 500;

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 0px 48px;
  }
}

.dateForNextPayment {
  font-weight: 500;
  font-size: 18px;
  margin: 0 24px 10px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 37px 48px;
  }
}

.canceledByHostText {
  /* Font */
  color: var(--marketplaceColor);
  font-weight: 500;

  margin: 10px 24px 0 24px;

  @media (--viewportLarge) {
    margin: 27px 48px 18px 48px;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.breakdown {
  margin: 14px 24px 20px 24px;

  @media (--viewportMedium) {
    margin: 18px 24px 20px 24px;
  }
  @media (--viewportLarge) {
    margin: 14px 48px 37px 48px;
    margin: 24px 48px 47px 48px;
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 15px;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column-reverse;
    & button:nth-child(2) {
      margin: 8px 0 0 0;
    }
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 8px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.noCarStatusPhotoUpload {
  padding: 10px 0px;
  color: var(--marketplaceColor);
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.mobileActionButtonsPickUp {
  display: block;
  line-height: 61px !important;

  @media (--viewportLarge) {
    display: none;
  }
}
.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 48px 48px 48px;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.marginTopBox {
  widows: 100%;
  margin: 29px 24px 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 25px 24px 0 24px;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 152px 0 0 0;
  }
}

.securityPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: left;
  margin: 20px 24px 0px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin: 20px 24px 20px;
  }

  @media (--viewportLarge) {
    margin: 4px 48px 21px;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 10px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media (--viewportLarge) {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.cancellationPolicy {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 21px;
  }
}

.insuranceWrapper {
  margin: auto 24px 10px;

  @media (--viewportMedium) {
    margin: auto 24px 10px;
  }

  @media (--viewportLarge) {
    margin: 4px 48px 10px;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  display: block;
  height: calc(60vh - 193px);
  width: calc(100% - 48px);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 60vh;
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    width: calc(100% - 96px);
    height: 200px;
    margin-bottom: auto;
  }
}

.desktopInsurance,
.desktopSmallPrint {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.cancelPanel {
  margin-top: 50px;
  padding: 0px;

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0px;
    margin-top: 100px;
  }
}

.keyRulesItem {
  margin-bottom: 30px;
  font-size: 16px;
}

.addonsHolder {
  border-bottom: 1px solid var(--matterColorNegative);
  margin-bottom: 20px;
  padding: 0 24px;

  @media (--viewportMedium) {
   padding: 0;
  }
}

.addonsTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColorDark);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.smallPartnerInfo {
  display: flex;
  margin: 0 0 30px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.addonsSubTitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--marketplaceColor);
  margin-top: 0px;
  margin-bottom: 24px;
  line-height: 18px;
}

.updateSubTitle {
  color: var(--marketplaceColor);
  font-size: 36px;
}

.addressMaybe {
  color: var(--marketplaceColorDark);
  font-weight: normal;
  font-size: 16px;
}

.keyRulesTitle {
  color: var(--marketplaceColorDark);
  margin-bottom: 10px;
}

.keyRuleText {
  color: var(--marketplaceColorDark);
  font-size: 18px;
  font-weight: 300;
  text-transform: capitalize;
}

.cancelLine {
  color: var(--marketplaceColorDark);
}

.cancelLine h3 {
  font-size: 20px;
  color: var(--marketplaceColorDark);
  font-weight: 700;
  margin-bottom: 10px;
}

.cancelLine p {
  margin-top: 0px;
  line-height: 20px;
  font-size: 16px;
  color: var(--marketplaceColorDark);
}

.cancelLine p a {
  color: var(--marketplaceColor);
}

.cancelButton {
  display: block;
  width: 200px;
  height: 45px;
  min-height: 0px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  margin-left: auto;
}

.cancelText {
  font-size: 16px;
  color: var(--marketplaceColorDark);

  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.cancelTextContainer {
  display: flex;
  padding: 0 24px;
  margin-bottom: 180px;

  @media (--viewportMedium) {
    display: block;
    margin: 5px 48px 20px;
    padding: 0;
  }
}

.tooltip {
  font-size: 16px;
  position: relative;
  display: inline-block;
  flex-basis: 40%;
  color: var(--matterColorAnti);
  margin-bottom: 10px;
  transition: opacity 0.3s;
  &:hover {
    color: var(--marketplaceColorLight);
  }
  @media (--viewportMedium) {
    flex-basis: unset;
  }
}

.cancelPolicyText {
  /* margin-left: 10px; */
  font-size: 14px;
  color: var(--matterColorAnti);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: var(--matterColorNear);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 18%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--matterColorNear) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tripCode {
  font-size: 14px;

  margin: 0 24px 10px;

  @media (--viewportLarge) {
    margin: 14px 0 0;
  }
}

.updateBookingButtons {
  display: flex;
  flex-direction: column;

  & button {
    margin-bottom: 10px;
  }
}

.secondButton {
  background-color: var(--matterColorNegative);
  color: var(--matterColorAnti);

  &:hover,
  &:focus {
    background-color: var(--matterColorNear);
  }
}

.primaryButton {
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);

  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
  }
}

.reasonDisputeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.reasonDisputeTitle {
  flex-basis: 100%;
}

.reasonDisputeInputWrapper {
  flex-basis: 100%;
  margin-bottom: 40px;
}

.reasonDisputeInput {
  box-sizing: border-box;
  width: 100%;
  background-color: var(--matterColorNegative);
  padding: 20px;
}

.explainMoreWhenHostDispute {
  color: var(--marketplaceColor);
  width: 100%;
}

.tripPhotos {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px;
  width: 100%;
}

.tripPhoto {
  position: relative;
  width: 160px;
  height: 120px;
  background-color: var(--matterColorNegative);
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.tripPhoto img {
  max-width: 100%;
  max-height: 100%;
}

.checkDisputePhotoSelected {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 5;
}

.doNotDisbuteButton {
  flex-basis: 45%;
}

.disputeButton {
  flex-basis: 45%;
}

.showImgModalImgWrapper {
  max-width: 600px;
  margin: auto;
}

.showImgModalImg {
  width: 100%;
}

.rentalAgreementLink {
  color: var(--marketplaceColorDark);
  margin: 5px 48px 20px;
  font-size: 18px;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.rentalAgreementLink span {
  cursor: pointer;
}

.rentalAgreementLinkCenter {
  text-align: center;
}

.rentalAgreementLinkMobile {
  color: var(--marketplaceColorDark);
  margin: 15px 24px;
  font-size: 18px;
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.rentalAgreementLinkMobile span {
  cursor: pointer;
}

.linksWrapper {
  display: flex;
  flex-direction: row;
}

.numberOdometer {
  width: 230px;
  margin: 0 20px 20px 24px;
  @media (--viewportLarge) {
    margin: 0 0 20px;
  }
}


.smallAvatarDesktop {
@media (--viewportLarge) {
  display: flex;
  width: 48px;
  height: 48px;
}
}

.partnetTextContainer {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.partnerText {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
}

.avatarinfoContainer {
  display: flex;
}

.otherText {
  font-size: 24px;
  line-height: 24px;
  color: #026786;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.partnerName {
  flex: 1;
  color: var(--marketplaceColorDark);
  padding-left: 5px;
  padding-right: 5px;
}

.contactContainer {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1pt solid #00a3ad;
  border-radius: 22pt;
  align-items: center;
  width: 180px;
  margin-left: 30px;
}

.titleContainer {
  padding: 30px 0 30px;
  margin: 0 20px;

@media (--viewportMedium) {
  padding: 45px 0 30px;
  margin: 0;
}
}

.bookingStatusText {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  letter-spacing: 2.8px;
  font-weight: var(--fontWeightBold);
  cursor: pointer;

@media (--viewportMedium) {
  font-size: 14px;
}
}

.listingTitle {
  font-size: 28px;
  line-height: 56px;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 0;
  color: var(--marketplaceColorDark);
  letter-spacing: 0;

  @media (--viewportMedium) {
    font-size: 36px;
  }
}

.detailsContainerTop {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 28px;

  @media (--viewportMobile) {
    padding: 0 0 10px;
    border: 0;
  }
}

.detailsAspectWrapper {
  position: relative;
  /* padding-bottom: 66.6667%; 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
  flex: 1;
  max-width: 88px;
  padding-bottom: 88px;
  border-radius: 4px;
  overflow: hidden;
  @media (--viewportMobile) {
    max-width: 71px;
    padding-bottom: 71px;
  }
}
.detailsAspectWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.detailsHeadings {
  margin: 0;
  flex: 1;
  padding: 0 0 0 10px;
}

.detailsTitle {
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 1.333;
  color: #026786;
  @media (--viewportMobile) {
    margin: 0 0 5px;
    font-size: 20px;
    line-height: normal;
  }
}

.detailsSubtitle {
  @apply --marketplaceH5FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
