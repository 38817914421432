@import '../../marketplace.css';

:root {
  counter-reset: section;
}
.root {
}

.background {
  @media (--viewportLarge) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/background@1440-min.png');
    background-size: 100% auto;
    position: relative;
    min-height: 1026px;
    padding: 36px 36px 76px;
  }
}

.contentWrapper {
  background-color: var(--matterColorLight);
  width: 100%;
  height: 100%;
  padding: 100px 30px 84px;

  @media (--viewportLarge) {
    width: 690px;
    height: 814px;
    margin-top: 68px;
    margin-left: 156px;
    padding: 70px 70px 90px;
    position: relative;
  }
}

.goLogo {
  display: block;
  height: 54px;
  width: 125px;

  @media (--viewportLarge) {
    height: 98px;
    width: 218px;
  }
}

.title {
  color: var(--marketplaceColorDark);
  font-size: 24px;
  line-height: 29px;
  font-weight: var(--fontWeightBold);
  margin-top: 20px;

  @media (--viewportLarge) {
    font-size: 35px;
    line-height: 42px;
  }
}

.description,
.lastDescription {
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
  white-space: pre-wrap;
}

.button {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColorDark);
  padding: 13px 38px;
  min-height: 50px;
  max-width: 315px;
  border-radius: 6px;
  margin-top: 30px;
  text-transform: unset !important;

  @media (--viewportMedium) {
    max-width: 418px;
  }

  @media (--viewportLarge) {
    position: absolute;
    bottom: 90px;
    left: 70px;
  }
}

.loadingWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yellowButton {
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);

  &:hover,
  &:focus {
    color: var(--matterColorLight);
  }
}

.title {
  margin: 23px 0 19px 0;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
  }
}

.term .description::before {
  counter-increment: section;
  content: '' counter(section) '. ';
}

.accept {
  margin-top: 100px;
}

.decline {
  margin-top: 30px;
  text-align: center;
  color: var(--matterColorNear);
}

.declineWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
