@import '../../marketplace.css';

.introSection {
  background-color: var(--marketplaceColor);
}

.textBoxs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  padding: 40px 20px;
  justify-content: center;
  flex-wrap: wrap;
  
  @media (--viewportMedium) {
    max-width: 100%;
    padding: 60px 0px;
    margin: 0 7.5vw;
  }

  @media (--viewportLarge) {
    flex-direction: row;
  }

  @media screen and (min-width: 1200px) {
    margin: auto;
    max-width: 1100px;
  }
}

.textBox {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  @media (--viewportLarge) {
    width: 47%;
  }

  @media screen and (min-width: 1200px) {
    width: 450px;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorLight);
  font-weight: 900;
}

.description {
  @apply --marketplaceDescFontStyles;
  color: var(--matterColorLight);
}

.defaultButton {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColorDark);
  border-radius: 5px;
  font-weight: 700;
  color: var(--matterColorLight);
  box-sizing: border-box;
  min-height: 50px;
  height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0px;
  margin: auto;

  &:hover, &:focus {
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 30px;
    width: 343px;
    min-height: 65px;
  }

  @media (--viewportLarge) {
  }

  @media (--viewportMLarge) {
    font-size: 30px;
    width: 343px;
    min-height: 65px;
  }
}

.insuranceLogo {
  width: 338px;
  height: 102px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.newline {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}