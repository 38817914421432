@import '../../marketplace.css';
/* @import "react-datepicker/dist/react-datepicker.css"; */
.content {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 10px;
  @media (--viewportMedium) {
    /* margin: 32px auto 0 auto; */
    /* width: 100%;
    max-width: 564px; */
  }

  @media (--viewportLarge) {
    /* margin: 0; */
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  color: #026786;
  line-height: 29px;
  text-align: left;
  margin: 0 0 10px;
  font-size: 24px;
  line-height: normal;
  padding: 20px;
  /* border-bottom: 1px solid #e6e6e6; */
  letter-spacing: normal;

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 27px;
    font-weight: 700;
  }
  /* margin-top: 4px;
  margin-bottom: 0px;
  color: var(--marketplaceColor);
  font-size: 24px;


  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 0px;
  } */
}

.description {
  color: var(--marketplaceColor);
}

.payoutContainer {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-top: 20px;
}

.payoutHeading {
  /* border: 1px solid #e6e6e6; */
    @media (max-width: 767px) {
      padding: 0;
      border: 0;
    }
  }

  .payoutHeadingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid #e6e6e6; */
    /* padding: 10px; */
    border-radius: 4px;
    /* margin: 10px; */
  }

  .payoutTitle {
    color: #026786;
    line-height: 29px;
    text-align: left;
    margin: 0 0 10px;
    font-size: 24px;
    line-height: normal;
    padding: 20px;
    /* border-bottom: 1px solid #e6e6e6; */
    letter-spacing: normal;
  
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 27px;
      font-weight: 700;
    }
  }

  .downlaodIcon {
    font-size: 24px;
    color: #007bff; /* Change the color as needed */
    cursor: pointer;
    margin: 0 0 10px;
    padding: 20px;
    text-align: left;
  }
  

  .payoutCardContainer {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    /* margin-top: 20px; */
  }


  .carCard {
    display: flex;
    /* border: 1px solid #ccc; */
    /* border-radius: 5px; */
    /* margin: 10px; */
    /* padding: 10px; */
    padding: 20px;
    /* background-color: #fff; */
    border: 1px solid #e6e6e6;
    @media (--viewportMobile) {
      padding: 10px;
     }
    /* border-radius: 4px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  .carDetails {
    flex: 2;
    /* padding: 10px; */
    display: flex;
    @media (--viewportMobile) {
     flex: 4
    }
  }
  
  .carImage {
    /* flex: 1; */
    /* padding: 10px; */
    /* height: 76px;
    width: 76px; */
    height: 76px;
    width: 76px;
    max-height: 76px;
    max-width: 76px;
    @media (--viewportMobile) {
      height: 61px;
      width: 61px;
    }
  }
  
  .carImage img {
    height: 97px;
    width: 97px;
    max-height: 97px;
    max-width: 97px;
    border-radius: 4px;
    object-fit: cover;
    @media (--viewportMobile) {
      height: 61px;
      width: 61px;
    }
    max-width: none;
    /* height: auto; */
  }
  
  .carInfo {
    flex: 1;
    padding-left: 10px;
    /* padding: 10px; */
  }
  
  .carInfo p {
    margin: 0;
    font-size: 18px;
  }
  
  .carPrice {
    flex: 1;
    padding: 10px;
    text-align: end;
    font-size: 20px;
    color: #1A1A1A;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: end;
    @media (--viewportMobile) {
      font-size: 14px;
      padding: 8px;
    }
    @media (--viewportSmallMobile) {
      font-size: 12px;
      padding: 5px;
    }
  }
  
  .carPrice p {
    margin: 0;
    font-size: 18px;
 
  }


  .statusDate {
    flex: 1;
    font-size: 16px;
    display: flex;
    color: #7C7C7C;
    padding-left: 5%;
    @media (--viewportMobile) {
      font-size: 10px;
      font-weight: 500;
    }
  }

  .statusContainer {
    padding-left: 5%;
  }

  .statusContainer > span {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
    padding: 5px 6px;
    @media (--viewportMobile) {
      font-size: 12px;;
      display: inline-block;
      margin-top: 5px;
    }
  }

  .divContainer {
    background: rgba(0, 163, 173, 0.15); /* Use rgba for background color with desired opacity */
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    @media (--viewportMobile) {
      font-size: 10px;
    }
  }
  
  .divText {
    text-align: center;
    color: #00A3AD;
    opacity: 1;
  }


  .status {
    position: relative;
    color: #1A1A1A;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: center;
    background: #00A3AD;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    @media (--viewportMobile) {
      font-size: 10px;
    }
  }
  
  .status::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00A3AD;
    opacity: 0.15; /* Adjust the opacity as needed */
    border-radius: inherit;
  }
  /* .paidOn {
    padding-left: 10px;

  } */

  .bankName {
    font-size: 18px;
    color: #1A1A1A;
    font-weight: 700;
    padding-left: 5%;
    @media (--viewportMobile) {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .carPlateDates {
    display: flex;
    font-size: 16px;
    color: #7C7C7C;
    padding-left: 5%;
    /* font-weight: 700; */

    @media (--viewportMobile) {
      display: flex;
      font-size: 10px;
      color: #7C7C7C;
      font-weight: 500;
    }
  }

  .carPlateNumber {
    padding-left: 10px;
    @media (--viewportMobile) {
      padding-left: 5px;
    }
  }

  .carPlateNumber::before {
    content: '•';
    color: #000;
    margin-right: 5px;
  }

  .loader {
    border: 5px solid #f3f3f3;
    align-self: center;
    margin-top: 15vh;
    border-radius: 50%;
    border-top: 5px solid var(--marketplaceColor);
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  .loadMore {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 20px;
    color: #0D6786;
    text-decoration: underline
    
  };

  .pageContent {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
  }
  .mainContentWrapper {
    padding: 20px;
  }
  
  .mainContentWrapper p {
    font-size: 16px;
    font-weight: 500 !important;
    line-height: normal;
    margin: 0 0 15px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
  }
  .mainContentWrapper p:last-child {
    margin: 0;
  }
  
  
  .modalClass {
    background: #FFFFFF 10% 10% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    opacity: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-bottom: none;
  
  }
  
  .closeTextModal {
    display: none;
  }
  
  .modalContainer {
    @apply --marketplaceModalBaseStyles;
    /*min-height: 100vh;*/
    /*height: 100%;*/
  
    @media (--viewportMedium) {
      flex-basis: 576px;
      min-height: auto;
      height: auto;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  
    @media (--viewportLarge) {
      flex-basis: 724px;
      min-height: auto;
      height: auto;
      padding: 40px 40px 50px 40px;
    }
  
    @media (--viewportMLarge) {
      margin-top: 207px;
      margin-bottom: 206px;
    }
  }
  
  .modalContentClassName {
    margin: 30px;
  }
  

  .modalHeadingTitle {
    text-align: left;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0;
    color: #0D6786;
    opacity: 1;
  }
  
  .modalContent {
    text-align: left;
    /*font: normal normal normal 18px/22px Museo Sans Rounded 300;*/
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0px;
    color: #4A4A4A;
    opacity: 1;
  }

  .dateSelectors {
    display: flex;
    align-items: center;
  }

  .datePickerClass {
    border: 1px solid  #E6E6E6;
    padding: 15px;
    flex: 1;
    display: flex;
  }

  .customDatepicker {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex: 0 0 50%;
  }

  .datePickerClass input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    font-size: 20px;
    padding: 0;
    color :#B2B2B2;
  }
  
  .datePickerClass input:hover,
.datePickerClass input:focus {
  border: none;
  outline: none;
  /* Add your desired styles for hover and focus states */
}

.customDatepickerEnd {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  display: flex;
  flex: 1;
}

.emtyList {
  padding-top: 10px;
    padding-bottom: 133px;
    padding-left: 5px;
}