@import '../../marketplace.css';


.root {
  list-style-type: none;
  & p {
    @apply --marketplaceH4FontStyles;
    color: #111111;
    letter-spacing: normal;
    font-weight: 500 !important;
  }
  & * {
    color: #111111;
    letter-spacing: normal;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }
  & p > a{
    color: var(--marketplaceColorDark);
  }
  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;
    color:var(--marketplaceColor);

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}

.root .mainTitle {
  text-align: center;
  font-weight: bold;
}

.root .sectionTitle {
  font-weight: bold;
}

.line {
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 14px;
}

.line::first-letter {
  text-transform: uppercase;
}

.root h1, .root h2, .root h3, .root h4, .root h5, .root h6{
  font-weight: 900 !important;
  margin: 0 0 25px;
}
.root h1{
  font-size: 28px;
  line-height: normal;
  font-weight: 900;
}
.root h2{
  font-size: 22px;
  line-height: normal;
  font-weight: 900;
}
.tableOuter{
  padding-left: 25px;
}
.root table td{
  padding: 0 0 20px 0;
  vertical-align: top;
}
.root p{
  margin: 0 0 15px 0;
}
.root ul, .root ol{
  padding: 0;
  margin: 0 0 15px 0;
}
.root ul li, .root ol li{
  font-size: 16px;
  line-height: 24px;
}

.alphabetListing{
  counter-reset: list;
}
.alphabetListing > li{
  list-style: none;
  position: relative;
  padding-left: 40px;
  margin-bottom: 7px;
}
.alphabetListing > li::before{
  counter-increment: list;
  content: "(" counter(list, lower-alpha) ") ";
  position: absolute;
  left: 0;
  font-weight: 700;
}
.romanListing{
  counter-reset: list;
}
.romanListing > li{
  list-style: none;
  position: relative;
  padding-left: 40px;
  margin-bottom: 7px;
}
.romanListing > li::before{
  counter-increment: list;
  content: "(" counter(list, lower-roman) ") ";
  position: absolute;
  left: 0;
  font-weight: 700;
}
.padLeft{
  padding-left: 20px;
}

.table-of-contents {
  list-style-type: none;
  padding: 0;
}

.table-of-contents {
  text-align: left;
}

.table-of-contents ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: auto 30px;
  gap: 10px;
}


.mainHeading {
  font-size: 35px;
  font-weight: 700;
  color: var(--marketplaceColorDark) !important;

  @media (max-width: 767px){
    font-size: 20px;
    font-weight: 700;
  }
}

.headingColour {
  font-size: 25px;
  font-weight: 800;
  color: var(--marketplaceColorDark) !important;

  @media (max-width: 767px){
    font-size: 15px;
    font-weight: 700;
  }
}
