/* p {
    font-weight: 600 !important;
  } */

.customerReviewSection {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 100px 80px;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    background: #FFF;

    @media only screen and (max-width: 767px) {
        padding: 50px 16px;
        gap: 24px;
    }
}

.reviewContainer {
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    align-items: center;
    /* justify-content: center; */
    gap: 0;
    margin: 0;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.carImage {
    width: 45.2%;
    height: 29rem;
    border-radius: 53.519px 0 0 53.519px;
    object-fit: cover;

    @media only screen and (max-width: 767px) {
        width: 100%;
        height: 16rem;
        align-self: stretch;
        border-radius: 14px 14px 0 0;
    }
}

.reviewContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    height: 28.95rem;
    width: 54.8%;
    padding: 2.675rem;
    border-radius: 0 53.519px 53.519px 0;
    background: #00A3AD;

    @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 1.2rem;
        align-self: stretch;
        border-radius: 0 0 14px 14px;
        height: auto;
    }
}





.stars {
    display: flex;
    font-size: 1rem;
}

.star {
    width: 2.22rem;
    height: 2.22rem;
    flex-shrink: 0;

    @media only screen and (max-width: 767px) {
        width: 1.24rem;
        height: 1.24rem;
    }
}

.reviewText {
    color: #FFF;
    text-align: center;
    font-family: "Inter";
    /* Added fallback for font */
    font-size: 1.6rem;
    font-weight: 700 !important;
    line-height: 2rem;
    letter-spacing: -0.0039rem;
    margin: 17px 0px 35px;

    @media only screen and (max-width: 767px) {
        font-size: 0.9rem;
        font-weight: 500 !important;
        line-height: 1.3rem;
        margin-bottom: 14px;
    }
}

.reviewAuthor {
    color: #FFF;
    font-family: Inter;
    font-size: 1.6rem;
    font-weight: 700 !important;
    line-height: 2rem;
    letter-spacing: -0.078px;
    margin: 0;

    @media only screen and (max-width: 767px) {
        font-size: 0.8rem;
        font-weight: 600 !important;
        line-height: 1.2rem;
    }
}