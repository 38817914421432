@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* padding: 11px 24px 0 24px; */
}

.form {
  flex-grow: 1;
  padding: 30px 40px;
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 30px;
  }
}

.titleSection {
  padding: 20px 40px;
  border-bottom: 1px solid #e6e6e6;
  
  @media (max-width: 767px) {
    padding: 20px 0;
  }
}

.title {
  color: #026786;
  margin: 0 0 10px;
  font-size: 28px;
  line-height: normal;
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;

   @media (max-width: 767px) {
    font-size: 28px;
    line-height: normal;
    font-weight: 700;
  }
}

.quoteHeading {
  padding-bottom: 20px;
}

.titleSection p {
  font-size: 20px;
  font-weight: 500 !important;
  line-height: normal;
  margin: 0 0 15px;
  
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.titleSection p:last-child {
  margin: 0;
}

.titleSection a {
  font-size: 20px;
  color: #18A3AD;
  text-decoration: underline;
  font-weight: 700;
  line-height: 32px;

  @media (--viewportMobile) {
   font-size: 18px; 
  }
}

.insuredByWrapper p {
  margin: 0 0 1px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  margin-top: 18px;

  @media (--viewportMobile) {
    justify-content: space-between;
  }
}

.logoWrapper span {
  font-size: 18px;
  color: #B2B2B2;

  @media (--viewportMobile) {
    font-size: 14px;
  }
}

.logoWrapper img:nth-of-type(1) {
  max-width: 73px;
  margin: 0 20px;

  @media (--viewportMobile) {
    max-width: 56px;
    margin: 0;
    margin-right: 20px;
  }
}

.logoWrapper img:nth-of-type(2) {
  max-width: 139px;

  @media (--viewportMobile) {
    max-width: 106px;
  }
}

.BookingInsurenceWrapper {
  h3 {
    color: #026786;
    font-size: 22px;
    font-weight: 700;

    @media (--viewportMobile) {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.listWrapper {
  display: flex;
  margin-top: 6px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.BookingInsurenceWrapper button {
  padding: 14px 18px;
  color: #FFFFFF;
  background: #026786;
  font-size: 20px;
  display: block;
  margin-top: 20px;
  border-radius: 4px;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.containerClassName {
  padding: 0px!important;
  max-width: 460px !important;
  
  @media (--viewportMobile) {
    position: absolute !important;
    bottom: 0px;
    border-radius: 8px 8px 0px 0px !important;
    max-width: 100% !important;
  }
}


.root {
  position: relative;
}

.label {
  display: flex;
  align-items: center;
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}


.resultContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin: 10px 0px;
  padding: 10px 0px;
  line-height: 13px;

}
.quoteListingWrapper {
  p {
    margin: 0;
    line-height: 24px;
    font-weight: 500 !important;
    font-size: 18px;
  }
}

.quoteListingWrapper p:nth-of-type(2) {
  margin-top: 10px;
}

.optionWrapper {
  ul {
    margin: 0;
  }

  label {
    padding: 0px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
  }
}

input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.insurenceBtn {
  font-size: 18px;
  text-transform: capitalize;

  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.toggleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.priceText {
  font-size: 28px;
  font-weight: 900;
  line-height: 32px;
  color: #4A4A4A;
  display: block;
}

.monthText {
  color: #7C7C7C;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.bookingInsurenceValue {
  h1 {
    margin: 0px;
    text-align: center;
  }

  span {
    display: block;
    font-size: 12px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 8px;
    color: #4A4A4A;
  }
}

.descriptionText {
  color: #4A4A4A;
  font-size: 20px ;
  font-weight: 500 !important;
  padding-left: 4px;

  @media (--viewportMobile) {
    font-size: 18px;
  }
}

.headingText {
  color: #4A4A4A;
  font-size: 16px ;
  font-weight: 700 !important;
}

.modalLogoWrapper {
  height: 72px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (--viewportMobile) {
    height: 106px;
  }
}

.modalLogo {
  padding-bottom: 25px;
}

.modalLogoWrapper img:nth-of-type(2) {
  @media (--viewportMobile) {
    max-width: 201px;
  }
}

.headerText {
  color: #4A4A4A;
  font-size: 20px ;
  font-weight: 700 !important;

  @media (--viewportMobile) {
    font-size: 16px;
    margin-top: 25px;
  }
}


.infoItem {
  display: flex;

  p {
    margin: 0px;
    font-weight: 500 !important;
    line-height: 28px;

    @media (--viewportMobile) {
      line-height: 24px;
    }
  }
}

.infoList {
  padding: 0px 20px;
}

.contentWrapper {
  padding: 0px 20px 10px 20px;
}

.radioButton {
  accent-color:'#00A3AD';
}


/* For sticky submit button design */

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}
.stickyButtons .stickButtonsContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}

.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
 
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    background: #eff7f8;
    display: none;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
 
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}

.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;
 
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}

.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.boderBottom {
  border-bottom: 1px solid #e6e6e6;
  accent-color: '#00A3AD'
}

.mainText {
  padding: 0;
  border-bottom: 0;
  font-weight: 700;  
  font-size: 32px;
  @media (--viewportMobile) {
    font-weight: 900;  
    font-size: 28px;
  }
}

.logoImgWrapper {
  display: flex;
}

.planHeading {
  font-size: 20px;
  color: #026786;
  font-weight: 500 !important;
  line-height: 26px;
  margin-top: 22px;

  @media (--viewportMobile) {
    margin-top: 20px;
  }
}

.boldText {
  font-weight: 900;
}

.getQuoteBtn {
  font-weight: 700 !important;
}

.planWrapper {
  padding-bottom: 10px;
}

.instructionsSection {
  border-bottom: unset;
}

