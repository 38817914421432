@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 6px;
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.lineItemError {
  @apply --marketplaceH4FontStyles;

  /* Since the line items have different font sizes in the label and the
   value columns, we have to balance the line to align to the
   baseline. */
  margin: -1px 0 0 0;
  color: var(--failColor);

  @media (--viewportMedium) {
    margin: -1px 0 8px 0;

    &:last-of-type {
      /* Last item should not have bottom margin that goes to the bottom of
         the component */
      margin-bottom: 0;
    }
  }
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
}

.receiptContainer {
  border: 2px dashed #ebebeb;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  background-color: #fcfcfc;
  cursor: pointer;
}

.receiptContent {
  flex: 1;
  cursor: pointer;
}

.receiptContentText {
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.receiptContent p {
  margin: 0;
  font-size: 14px;
  line-height: normal;
  color: #7c7c7c;
  @media (--viewportMobile) {
    font-size: 12px;
  }
}

.receiptIcon {
  margin-left: 10px;
  height: 19px;
  width: 19px;
  @media (--viewportMobile) {
    height: 15px;
    width: 15px;
  }
}

.depositContainer {
  display: flex;
  margin-top: 10px;
  border: 1px dashed #ffcd05;
  flex-direction: column;
  background-color: #ffcd050d;
  border-radius: 8px;
  padding: 10px;
  padding-top: 0px;
}
.depositPara {
  margin: 0;
}

.depositPara p {
  display: flex;
  @apply --marketplaceH4FontStyles;
  font-weight: 500 !important;
  font-size: 16px;
  letter-spacing: 0px;
  margin: 0;
}

.itemLabel {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
}

.itemTotalLabel {
  color: #026786;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
}

.itemTotalValue {
  color: #026786;
  font-size: 18px;
  font-weight: 900;
}

.totalLine {
  background-color: #ebebeb;
  color: #ebebeb;
}

.bookingPeriodText {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
  font-size: 20px;
  padding: 20px 0;
}

.bookingUpdatedPeriodText {
  @apply --marketplaceSmallFontStyles;
  font-size: 16px;
  padding: 20px 0;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
}

.creditsLineItem {
  color: var(--marketplaceColorLight);
  @apply --marketplaceH4FontStyles;

  /* Since the line items have different font sizes in the label and the
   value columns, we have to balance the line to align to the
   baseline. */
  margin: -1px 0 0 0;

  @media (--viewportMedium) {
    margin: -1px 0 8px 0;

    &:last-of-type {
      /* Last item should not have bottom margin that goes to the bottom of
         the component */
      margin-bottom: 0;
    }
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.itemValue {
  @apply --marketplaceH4FontStyles;
  font-weight: 700 !important;
  margin: 0 0 0 10px;

  @media (--viewportMedium) {
    font-weight: 700 !important;
    margin: 0 0 0 10px;
    font-size: 20px;
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.divider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 20px 0;

  border: none;
  background-color: var(--matterColorNegative);
}

.totalLabel {
  font-weight: var(--fontWeightNormal);
  color: #026786;
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 4px;
  color: #026786;
  @media (--viewportMedium) {
    margin: 2px 0 0 10px;
    font-size: 20px;
  }
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.tobePaid {
  padding-top: 20px;
}

.explaination {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  color: var(--matterColorAnti);
  cursor: pointer;

  &::before {
    content: '?';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 1px solid var(--matterColorAnti);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.explaination:hover .explainationText {
  display: block;
}

.explainationText {
  display: none;
  position: absolute;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  font-weight: normal;
  line-height: 16px;
  padding: 15px 25px;
  left: 50%;
  bottom: 0px;
  margin-bottom: 25px;
  width: 275px;
  box-shadow: 3px 3px 10px var(--matterColorNegative);
  right: auto;
  transform: translateX(-50%);

  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}

.mobileExplainationText {
  position: fixed;
  left: 0;
  top: 0;
  padding: 20px;
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.mobileExplainationText .bottomArrow {
  content: ' ';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: var(--matterColorLight) transparent transparent transparent;
}
.mobileExplainationText .explainationText {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  position: relative;
  transform: none;
  width: 100%;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  color: #4a4a4a;
}
.mobileExplainationText .explainationText::after {
  display: none;
}
.mobileExplainationText.tooltipHidden {
  opacity: 0;
  visibility: hidden;
}

.plus {
  margin-left: 10px;
  color: #00a3ad;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    text-decoration: underline;
  }
}
.explanationBreakdownContainer {
  border: 1px dashed #ebebeb;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;

  .lineItem {
    margin-top: 5px !important;
  }
}

.explanationContainerTitle {
  background-color: #ffffff;
  color: #026786;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 12px;
  transform: translateY(-50%);
}

.paidBanner {
  background-color: #00a3ad;
  color: #ffffff;
  padding: 4px;
  border-radius: 2px;
  margin-left: 10px;
}

.explanationValue {
  font-size: 15px;
}
.hide {
  opacity: 0;
  height: 0;
}
.show {
  opacity: 1;
  height: 100%;
}
.gstInclusionLine {
  /* border: solid 2px red; */
  font-size: 16px;
  text-align: right;
}

.initialPaymentContainer {
  margin-bottom: 7px;
  position: relative;
}

.dates {
  color: #4a4a4a;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.item {
  margin-right: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.paid {
  margin-left: 7px;
  border-radius: 4px;
  background: #3d9626;
  height: 20px;
  flex-shrink: 0;
  padding: 3px 9px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.datesContainer {
  display: flex;
  align-items: center;
  font-weight: 500 !important;
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  justify-content: space-between;

  .dates {
    color: #4a4a4a;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (--viewportMedium) {
      font-size: 16px;
    }
  }
}

.modifyDatesLabel {
  font-size: 15px;
}

.modalTitle {
  color: #0d6786;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
