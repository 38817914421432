@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;

}

/* .listingWrapper  > :nth-child(2) {
  border:none;
} */

/* Step title design */
.form {
  flex-grow: 1;
  padding: 30px 40px;
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 30px;
  }
}

.titleSection {
  padding: 30px 40px;
  border-bottom: 1px solid #E6E6E6;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
}

.title {
  color: #026786;
  margin: 0 0 10px;
  font-size: 32px;
  line-height: normal;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: normal;
    font-weight: 700;
  }
}

.titleSection p {
  font-size: 16px;
  font-weight: 500 !important;
  line-height: normal;
  margin: 0 0 15px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.titleSection p:last-child {
  margin: 0;

  @media (--viewportMobile) {
    margin: 10px 0px;
  }
}


.column {
  padding-left: 40px;
  padding-right: 40px;

  @media (--viewportMobile) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.building {
  flex-basis: 60%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 6;
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    margin-top: 0px;
  }
}

/* Input design */
.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}
.newInput input {
  height: 54px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  &:hover,
  &:focus {
    border: 1px solid #E6E6E6;
  }
}

.newInput select option {
  color: #4a4a4a;;
}

.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  padding-top: 0px;

  @media (--viewportMobile) {
    margin-bottom: 2px;
  }
}


.displayFlex {
  display: flex;

  @media (--viewportMobile) {
    display: block;
  }
}

.addAnotherDeviceBtn {
  height: 44px;
  min-height: 44px;
  width: 100%;
  display: flex;
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  max-width: 335px;
  margin-left: 40px;

  @media (--viewportMobile) {
    margin-left: 0px;
    max-width: 100%;
  }
}

.deviceHeading {
  padding-left: 40px;
  font-size: 22px;
  color: #4A4A4A;
  font-weight: 500;

  @media (--viewportMobile) {
    padding-left: 0px;
  }
}

.mb15 {
  margin-bottom: 15px;
}

.imgUploadWrapper {
  margin: 15px 0 15px 0;
  display: flex;
  @media (--viewportMobile) {
    flex-direction: column;
  }
}

.imgUploadWrapper .uploadButton {
  position: relative;
  width: 335px;
  text-align: center;
  cursor: pointer;
  height: 160px;
  border-radius: 4px;
  background: #e6e6e6;
  font-size: 16px;
  border: 1px solid #E6E6E6;
  background: #FCFCFC;
  /* margin-left: 40px; */
  opacity: 1;

  @media (--viewportMobile) {
    /* margin-left: 0px; */
    width: 100%;
  }
}

.uploadInput {
  display: none;
}

.deviceSection  {
  border-top: 0.5px solid #E6E6E6;
}

.UploadTitle {
  color: #4A4A4A;
  font-size: 16px;
  margin: 0px;
  /* margin-left: 40px; */
  @media (--viewportMobile) {
    /* margin-left: 2px; */
  }
}

.errorText {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}

/* StickyButtons  */
.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}

.stickyButtons .stickButtonsContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}

.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    background: #eff7f8;
    display: none;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}

.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}

.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.uploadText {
  text-decoration: underline;
  text-transform: capitalize;
  color: #026786;
  font-size: 16px;
}


.imageWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.uploadButton {
  width: 100px;
  height: auto;
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255,255,255,0.7);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
}


.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  text-decoration: underline;
  color: var(--marketplaceColorDark);
  margin-top: 0;
  margin-bottom: 0;
  text-decoration: none;
  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.newInput select {
  height: 54px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  color: #4A4A4A;
  background: white;
  background-position: calc(100% - 15px) center;
  background-size: 16px 16px;
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  cursor: auto;
}

.newInput select:hover,
.newInput select:focus {
  border: 1px solid #E6E6E6;
}


select:required:invalid {
  color: #B2B2B2;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}

.isBYODToggle {
  /* border: solid 2px blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isBYODToggle > label {
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  color: #4a4a4a;
  padding-top: 0px;
}

.isBYODToggle > p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500 !important;
  color: #4a4a4a;
  padding-top: 0px;
}

.infoSection {
  color: var(--matterColorNear);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 20px 40px;

  @media (--viewportMobile) {
    padding: 20px 0px;
  }
}

.learnMore {
  margin-bottom: 10px;
  color: #4A4A4A;
  font-size: 20px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}