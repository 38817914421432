@import '../../marketplace.css';

.breakdown > hr {
  border: solid 1px #E6E6E6;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 6px;
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.itemLabel {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
  font-size: 15px;
}

.itemValue {
  @apply --marketplaceH4FontStyles;
  margin: 0 0 0 10px;
  font-size: 20px;
  font-weight: 700 !important;

  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.gstInclusionLine {
  /* border: solid 2px red; */
  font-size: 16px;
  text-align: right;
}