@import '../../marketplace.css';

:root {
  --SectionHeroBackground: {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/MasterCardLandingPage@1x.jpg');
    background-image: image-set(url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/MasterCardLandingPage@1x.jpg') 1x, url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/MasterCardLandingPage@2x.jpg') 2x);
    /* Add loading color for the div */
    background-color: var(--matterColor);

    /* Cover the whole screen with the background image */
    background-size: cover;

    /* Align the image within the container */
    background-position: center center;
  }
}

.root {
  position: relative;
}

.sectionHero {
  @apply --SectionHeroBackground;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  min-height: 400px;

  padding: 18px 18px 20px;

  @media (--viewportLarge) {
    height: min(100vh, 768px);
    justify-content: center;
  }
}

.masterCardLogo {
  position: absolute;
  width: 50px;
  height: 30px;
  top: 14px;
  right: 16px;

  @media (--viewportLarge){
    top: 18px;
    right: 40px;
  }
}

.heroTitle {
  font-weight: var(--fontWeightBold);
  font-size: 28px;
  line-height: 30px;
  margin: 0 auto;
  text-align: center;
  max-width: 340px;
  color: var(--marketplaceSecondaryColor);

  white-space: pre-wrap;

  @media (--viewportMedium) {
    white-space: nowrap;
    font-size: 40px;
    line-height: 42px;
    max-width: unset;
  }
}

.description {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  color: var(--matterColorLight);
  margin-top: 15px;
  text-align: center;

  @media (--viewportLarge) {
    max-width: 810px;
    font-size: 24px;
    line-height: 29px;
  }
}

.descriptionHighlight {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 29px;
    font-weight: var(--fontWeightMedium);
  }
}

.sectionOffers {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    max-width: 952px;
    width: 952px;
    position: absolute;
    top: max(calc(min(100vh, 768px) - 250px), 575px);
    left: calc(50% - 952px / 2);
  }
}

.sectionOffersHeading {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 63px;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorLight);

  @media (--viewportLarge){
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.drivelahMasterCardLogo {
  width: 250px;
  height: 36px;
}

.sectionOffersContent {
  padding: 18px;
  display: flex;
  flex-direction: column;
  background-color: var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: row;
    padding-right: 38px;
  }
}

.left {
  @media (--viewportLarge) {
    flex: 1;
    /* padding-right: 38px; */
    padding: 0 32px;
    max-width: 325px;
  }
}

.right {
  @media (--viewportLarge) {
    flex: 2;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.sectionOffersTitle {
  font-size: 28px;
  line-height: 30px;
  margin: 0 auto 12px;
  text-align: left;
  width: 100%;
  white-space: pre-wrap;
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    font-size: 40px;
    line-height: 42px;
  }
}

.sectionOffersDescription {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    margin-top: 15px;
  }
}

.joinDriveLahMobile {
  @apply --marketplaceButtonStylesPrimary;
  width: 100%;
  height: 50px;
  min-height: 50px;
  background-color: var(--marketplaceColorDark);
  border-radius: 5px;
  text-transform: none;
  font-size: 28px;
  line-height: 28px;
  padding: 11px 0;
  margin-top: 15px;

  @media (--viewportLarge){
    display: none;
  }
}

.joinDriveLah {
  @apply --marketplaceButtonStylesPrimary;
  width: 100%;
  height: 50px;
  min-height: 50px;
  background-color: var(--marketplaceColorDark);
  border-radius: 5px;
  text-transform: none;
  font-size: 28px;
  line-height: 28px;
  padding: 11px 0;
  margin-top: 15px;
  position: absolute;
  max-width: 260px;
  bottom: -25px;
  left: calc(50% - 130px);
  display: none;

  @media (--viewportLarge){
    display: block;
  }
}

.offer {
  margin-top: 16px;

  @media (--viewportLarge) {
    &:first-child {
      margin-top: 0;
    }

    display: flex;
    flex-direction: row;
  }
}

.offerIndex {
  width: 95px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 24px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);
  background-color: var(--marketplaceColorDark);
  border-radius: 5px;

  @media (--viewportLarge){
    width: 110px;
    min-width: 110px;
  }
}

.offerInfo {
  @media (--viewportLarge) {
    max-width: 450px;
    margin-left: 12px;
  }
}

.offerTitle {
  font-size: 28px;
  line-height: normal;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
}

.offerDescription {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}

.youMayEnjoy {
  margin-top: 15px;
}

.youMayEnjoyHighlight {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
}

.youMayEnjoyExample {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}

.termOfConditionApply {
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  color: var(--matterColor);

  margin-top: 18px;
}

.sectionTermOfPromotion {
  padding: 18px;

  @media (--viewportLarge) {
    max-width: 960px;
    margin: max(15%, 70px) auto 0;
    padding-bottom: 100px;
  }

  @media (--viewportMLarge) {
    margin: 120px auto 0;
  }
}

.termOfPromotionTitle {
  font-size: 24px;
  line-height: 32px;
  color: var(--marketplaceColorDark);
  text-align: left;
}

.termOfPromotionDescription {
  font-size: 16px;
  line-height: 22px;
  color: var(--matterColor);
  margin-top: 22px;
  position: relative;

  padding-left: 12px;

  &:before {
    position: absolute;
    content: '•';
    font-size: 12px;
    line-height: 22px;
    color: var(--matterColorDark);
    left: 0;
  }
}

