@import './marketplaceFonts.css';
/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   * Usage example: .root { @apply --marketplaceH1FontStyles; }
   */
  /* Full screen Background image located in root-folder/src/assets */
  --backgroundImage: {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
    /* background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
      url('../../assets/background@1440-min.png'); */
    background-image: url('../../assets/landingpage/background@1440x1.jpg');
    background-image: image-set(url('../../assets/landingpage/background@1440x1.jpg') 1x, url('../../assets/landingpage/background@1440x2.jpg') 2x);
    /* Add loading color for the div */
    background-color: var(--matterColor);
    /* Cover the whole screen with the background image */
    background-size: cover;
    /* Align the image within the container */
    background-position: center center;
    @media (--viewportLarge) {
      /* border-radius: 40px; */
      /* border: solid 36px var(--matterColorBright); */
    }
  }
  ;
  /* Full screen Authentication Background image located in root-folder/src/assets */
  --authBackgroundImage: {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
    /* background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url('../../assets/background-auth@1440-min.jpg'); */
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url('../../assets/auth/background-img.png');
    /* Add loading color for the div */
    background-color: var(--matterColor);
    /* Cover the whole screen with the background image */
    background-size: cover;
    /* Align the image within the container */
    background-position: center center;
    @media (--viewportLarge) {
      border-radius: 40px;
      border: solid 36px var(--matterColorBright);
    }
  }
  /* ================ Colors ================ */
  --marketplaceColor: #00a3ad;
  --marketplaceColorLight: #59d5df;
  --marketplaceColorDark: #026786;
  --marketplaceSecondaryColor: #ffcd05;
  --marketplaceSecondaryColorLight: #ffff52;
  --marketplaceSecondaryColorDark: #c79d00;
  --marketplaceSecondaryColorFake: #fdc300;
  --marketplaceButtonColor: var(--marketplaceSecondaryColor);
  --marketplaceButtonHoverColor: var(--marketplaceSecondaryColorDark);
  --socialIconColor: var(--marketplaceColorDark);
  --socialIconColorHover: var(--matterColor);
  --inputBorderBottomColor: var(--marketplaceColorDark);
  --inputBorderBottomColorFocus: var(--marketplaceColor);
  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %23c0392b;
  --successColor: #2ecc71;
  --successColorPrimary: #508CCA;
  --successColorDark: #239954;
  --successColorLight: #f0fff6;
  --failColor: #ff0000;
  --failColorLight: #fff0f0;
  --attentionColor: #ffaa00;
  --actionNeededColorPrimary: #00A3AD;
  --actionNeededColorSecondary: #FFCC07;
  --attentionColorLight: #fff7f0;
  --bannedColorLight: var(--failColor);
  --bannedColorDark: var(--failColor);
  --matterColorDark: #000000;
  --matterColor: #4a4a4a;
  --matterColorNear: #b9b9b9;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fcfcfc;
  --matterColorLight: #ffffff;
  --matterColorRPTitle: rgb(0, 163, 173);
  --matterColorRPContent: #066887;
  --matterColorRPGrey: #f5f5f5;
  --matterColorRPCopyReferralCode: rgba(153, 153, 153);
  /* --matterColorRPCopyReferralCode: red; */
  --matterColorRPButton: #ffcd04;
  --customBlueColor: #F2FAFB;
  --customDarkBlueColor: #2680EB;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);
  --instantBookingLabelColor: #9013fe;
  --borderColorGray: #e6e6e6;
  --backgroundBlueCustom: #F2FAFB;

  /* ================ Spacing unites ================ */
  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;
  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  /* ================ Z-index base levels ================ */
  /* topbar is positioned slightly above content */
  --zIndexTopbar: 80;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;
  /* ================ Border radius ================ */
  --borderRadius: 2px;
  --borderRadiusMobileSearch: 3px;
  /* ================ Transition styles ================ */
  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;
  /* ================ Topbar heights ================ */
  --topbarHeight: 60px;
  --topbarHeightDesktop: 72px;
  /* ================ Modal default padding ================ */
  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;
  /* ================ LocationAutocompleteInput bottom attribution padding ================ */
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  --locationAutocompleteBottomPadding: 30px;
  /* ================ Clearfix solution ================ */
  --clearfix {
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
  /* ================ Links ================ */
  --marketplaceLinkStyles: {
    /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;
    /* Borders */
    border: none;
    /* Colors */
    color: var(--marketplaceColor);
    /* Text size should be inherited */
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  ;
  /* ================ Buttons ================ */
  --marketplaceButtonFontStyles: {
    font-family: 'sofiapro', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.3px;
    @media (--viewportMedium) {
      font-size: 28px;
      line-height: 28px;
      /* TODO: Make sure button text aligns with the baseline */
    }
  }
  ;
  --marketplaceButtonStyles: {
    @apply --marketplaceButtonFontStyles;
    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 59px;
    margin: 0;
    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 20px 0 0 0;
    /* Borders */
    border: none;
    border-radius: var(--borderRadius);
    /* Colors */
    background-color: var(--marketplaceButtonColor);
    color: var(--marketplaceColorDark);
    /* Text */
    text-align: center;
    text-decoration: none;
    /* text-transform: lowercase; */
    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;
    &:hover, &:focus {
      outline: none;
      background-color: var(--marketplaceButtonHoverColor);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }
    @media (--viewportMedium) {
      min-height: 65px;
    }
  }
  --marketplaceButtonStylesPrimary: {
    @apply --marketplaceButtonStyles;
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    &:hover, &:focus {
      background-color: var(--marketplaceColorDark);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
    }
  }
  --marketplaceButtonStylesSecondary: {
    @apply --marketplaceButtonStyles;
    background-color: var(--marketplaceSecondaryColor);
    color: var(--marketplaceColorDark);
    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
    padding: 18px 0 0 0;
    border-width: 1px;
    border-style: solid;
    border-color: var(--matterColorNegative);
    &:hover, &:focus {
      background-color: var(--marketplaceSecondaryColorDark);
      border-color: var(--matterColorAnti);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      border-color: var(--matterColorNegative);
    }
  }
  /* ================ Inputs ================ */
  --marketplaceInputStyles: {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;
    /* Borders */
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--marketplaceColorDark);
    border-radius: 0;
    &::placeholder {
      color: var(--matterColorAnti);
    }
    /* Effects */
    transition: border-bottom-color var(--transitionStyle);
    &:hover, &:focus {
      border-bottom-color: var(--marketplaceColor);
      outline: none;
    }
    @media (--viewportMedium) {
      padding: 4px 0 10px 0;
    }
  }
  --marketplaceSelectStyles: {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;
    /* Unset user agent styles */
    appearance: none;
    /* Borders */
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--marketplaceColor);
    border-radius: 0;
    /* Background */
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
    background-size: 16px 16px;
    background-position: center right;
    /* Effects */
    cursor: pointer;
    transition: border-bottom-color var(--transitionStyle);
    &:hover, &:focus {
      border-bottom-color: var(--matterColor);
      outline: none;
    }
    @media (--viewportMedium) {
      padding: 4px 0 10px 0;
    }
  }
  /* ================ Modals ================ */
  --marketplaceModalRootStyles: {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
    flex-grow: 1;
    /* AuthenticationPage's root uses flexbox */
    display: flex;
    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
    }
  }
  --marketplaceModalFormRootStyles: {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    height: 100%;
  }
  --marketplaceModalBaseStyles: {
    flex-grow: 1;
    /* Create context so the close button can be positioned within it */
    position: relative;
    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);
    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;
    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      /* min-height: 568px; */
      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
      border-radius: 8px;
      /*border-bottom: 8px solid var(--marketplaceColor);*/
    }
  }
  --marketplaceModalInMobileBaseStyles: {
    flex-grow: 1;
    /* Create context so the close button can be positioned within it */
    position: relative;
    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;
    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }
  /* Modal title */
  --marketplaceModalTitleStyles: {
    font-weight: var(--fontWeightSemiBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);
    color: var(--marketplaceColorDark);
    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
  }
  /* Modal message */
  --marketplaceModalParagraphStyles: {
    margin: 18px 0 0 0;
    @media (--viewportMedium) {
      margin: 24px 0 0 0;
    }
  }
  /* Bottom wrapper for the modal */
  --marketplaceModalBottomWrapper: {
    text-align: center;
    margin-top: 60px;
    align-self: stretch;
    @media (--viewportMedium) {
      margin-top: 96px;
    }
  }
  /* Adjust the bottomWrapper's margin to match modals without the helper text */
  --marketplaceModalBottomWrapperText: {
    margin-top: -24px;
    padding-bottom: 8px;
    @media (--viewportMedium) {
      padding-bottom: 0;
    }
  }
  --marketplaceModalHelperText: {
    @apply --marketplaceH5FontStyles;
    color: var(--matterColorAnti);
    margin: 0;
    @media (--viewportMedium) {
      margin: 0;
    }
  }
  --marketplaceModalHelperLink: {
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);
    margin: 0;
    @media (--viewportMedium) {
      margin: 0;
    }
  }
  /* Modal Close icon */
  --marketplaceModalCloseStyles: {
    /* Position inside modal */
    position: fixed;
    top: 0;
    right: 0;
    /* Some content (like map) is positioning itself on top of close button without z-index handling */
    z-index: calc(var(--zIndexModal) + 1);
    /* Safari didn't know how to baseline-align svg icon and text */
    display: flex;
    align-items: flex-start;
    width: auto;
    /* Sizing (touch area) */
    padding: 24px;
    margin: 0;
    border: 0;
    @media (--viewportMedium) {
      padding: 27px 30px;
      position: absolute;
    }
    /* Colors */
    background-color: transparent;
    color: var(--matterColor);
    cursor: pointer;
    &:enabled:hover {
      background-color: transparent;
      color: var(--matterColorDark);
      box-shadow: none;
      text-decoration: none;
    }
    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    }
    &:disabled {
      background-color: transparent;
    }
  }
  --marketplaceModalIconStyles: {
    height: 48px;
    margin-bottom: 12px;
    @media (--viewportMedium) {
      height: 64px;
      margin-bottom: 23px;
    }
  }
  --marketplaceModalCloseText: {
    /* Font */
    @apply --marketplaceH6FontStyles;
    margin: -2.5px 0 0 0;
    @media (--viewportMedium) {
      margin-top: -1px;
    }
  }
  --marketplaceModalCloseIcon: {
    display: inline-block;
    margin-left: 8px;
    padding: 2px 0 4px;
    box-sizing: content-box;
  }
  --marketplaceModalErrorStyles: {
    @apply --marketplaceH4FontStyles;
    margin-top: 24px;
    color: var(--failColor);
  }
  --marketplaceModalHighlightEmail: {
    font-weight: var(--fontWeightBold);
  }
  --marketplaceModalPasswordMargins: {
    /* Leave space between the input and the button below when the
    viewport height is small */
    margin-top: 24px;
    @media (--viewportMedium) {
      margin-top: 32px;
    }
  }
  --submitButtonDisabled: {
    cursor: initial;
    color: var(--matterColorNegative);
  }
  --submitWarning: {
    color: var(--failColor);
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -20px;
    text-align: center;
  }
}

/* ================ Custom media queries ================ */

@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportXSmall (min-width: 250px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportMediumMax (max-width: 1023px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportMLarge (min-width: 1200px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportLMLarge (min-width: 1366px);
@custom-media --viewportXLarge (min-width: 1920px);
@custom-media --viewportXXLarge (min-width: 2560px);
@custom-media --viewportMobile (max-width: 767px);
