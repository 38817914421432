@import '../../marketplace.css';

.readyToGoSection {
  width: 100%;
  /* background-color: var(--marketplaceSecondaryColorFake); */
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/howitwork/readyToGoMobile.png');
  background-size: 100vw 66vw;
  background-position: top;
  height: calc(64vw + 54px);

  @media (--viewportMedium) {
    height: auto;
    padding: 45px 30px;
    background-size: cover;
    background-position: center;
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/howitwork/readyToGo.png');
  }

  @media (--viewportLarge) {
    padding: 45px calc((100vw - 900px) / 2);
  }

  @media (--viewportMLarge) {
    padding: 45px calc((100vw - 1056px) / 2);
  }

  @media screen and (min-width: 1920px) {
    height: auto;
    padding: 45px calc((100vw - 1056px) / 2);
  }
}

.readyToGoSectionSmall {
  @media screen and (min-width: 400px) and (max-width: 592px) {
    height: calc(64vw + 30px);
  }
}

.readyToGoSectionLogin {
  @media (max-width: 399.98px) {
    height: calc(64vw + 106px); 
  }
  
  @media screen and (min-width: 400px) and (max-width: 592px) {
    height: calc(64vw + 80px);     
  }
}

.textBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.textBoxTitle {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceSecondaryColor);
  font-weight: 900;
  margin-top: 0px;
  padding: 40px 20px 0px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    padding: 0px;
    margin-bottom: 40px;
  }
}

.textBoxDesc {
  @apply --marketplaceDescFontStyles;
  color: var(--matterColorLight);
  max-width: 90%;
  margin-bottom: 0px;
  padding: 0px 20px 80px;


  @media (--viewportMedium) {
    padding: 0px;
    margin-bottom: 30px;    
  }

  @media screen and (min-width: 1200px) {
    margin: unset;
    max-width: 586px;    
    margin-bottom: 40px;
  }

}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-top: 80px; */

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 0px;
    /* margin-bottom: 100px; */
  }
}

.buttons p{
  color:#fff;
}

.readyToGoJoinNow {
  @apply --marketplaceButtonStylesSecondary;
  border: 0px solid transparent;
  border-radius: 0px;
  width: 100%;
  min-height: 50px;

  &:hover, &:focus {
    background-color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    width: 130px;
    min-height: 65px;
    border-radius: 5px;
  }
}

.defaultButton {
  @apply --marketplaceButtonStylesPrimary;
  border-radius: 5px;
  font-weight: 700;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  box-sizing: border-box;
  min-height: 50px;
  height: 50px;
  padding-top: 15px;
  border-radius: 0px;

  &:hover {
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportMedium) {
    border-radius: 5px;
    padding-top: 20px;
    font-size: 30px;
    width: 235px;
    height: 65px;
  }

  @media (--viewportMLarge) {
    font-size: 30px;
    width: 235px;
    height: 65px;
  }
}

.secondaryButton {
  @apply --marketplaceButtonStylesSecondary;
  color: var(--marketplaceColorDark);
  border: 0px solid transparent;
  min-height: 50px;
  height: 50px;
  border-radius: 0px;

  @media (--viewportMedium) {
    border-radius: 5px;
    font-size: 30px;
    width: 235px;
    height: 65px;
    margin-right: 24px;
  }

  @media (--viewportMLarge) {
    font-size: 30px;
    width: 235px;
    height: 65px;
    margin-right: 24px;
  }
}