@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  --storyBackgroundColor: #f2f2f2;
}

.root {
  overflow: hidden;
}

.contentWrapperBWrapper {
  position: relative;
}

.landingContainer {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
.landingRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.alignItemsCenter {
  align-items: center !important;
}
.textCenter {
  text-align: center !important;
}

/* Banner section  */

.bannerSlideItem {
  height: 700px;
  position: relative;
  @media (max-width: 1024px) {
    height: 600px;
  }
  @media (max-width: 767px) {
    height: 316px;
  }
  &::after {
    content: '';
    position: absolute;
    background: transparent linear-gradient(0deg, #000000 0%, #00000000 100%) 0% 0% no-repeat
      padding-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    opacity: 0.5;
  }
}
.bannerSlideItem .imageComponentImageBLanding {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 10;
}

.imageComponentContentBLanding {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 12;
  @media (max-width: 767px) {
    align-items: flex-end;
  }
}
.bannerContentBlock {
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.sectionImageTitleBLanding {
  font-size: 60px;
  line-height: 1.2;
  font-weight: var(--fontWeightBold);
  white-space: pre-wrap;

  align-self: flex-end;
  color: var(--matterColorLight);
  padding: 0 0 10px;
  flex: 0 0 100%;
  max-width: 100%;
  @media (--viewportLarge) {
    position: relative;
  }

  .hourlyTitleBLanding {
    @media (--viewportLarge) {
      margin-top: -20px;
    }
  }

  @media (--viewportMLarge) {
    align-self: center;
    font-size: 60px;
    line-height: 1.2;
    width: auto;
    padding: 0;
  }
  @media (max-width: 991px) {
    font-size: 40px;
  }
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 1.125;
    padding: 0 0 15px;
  }
}

.sectionImageTitleBLanding span {
  display: inline;
  position: relative;
  z-index: 1;
}
.sectionImageTitleBLanding span i {
  display: inline;
  position: relative;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
}
.sectionImageTitleBLanding span i::after {
  content: '';
  display: inline;
  background: #00a3ad;
  position: absolute;
  left: 0;
  height: 53px;
  width: 100%;
  display: inline;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
  @media (max-width: 767px) {
    height: 23px;
    width: 100%;
  }
}

.searchBar {
  width: 100%;
  margin-bottom: 30px;
  flex: 0 0 100%;
  max-width: 100%;
  width: 660px;
  padding: 30px;
  background: var(--matterColorLight);
  border-radius: 8px;
  box-shadow: 0 3px 6px #00000029;
  @media (max-width: 767px) {
    box-shadow: none;
    background: none;
    padding: 0;
    width: 100%;
    margin-bottom: 40px;
  }
}

.searchBarContent p {
  margin: 0 0 25px;
  font-size: 22px;
  line-height: 1.2777;
  @media (max-width: 767px) {
    display: none;
  }
}

.searchPlaceholder {
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}
.searchBarOuter {
  position: relative;
}
.sectionImagesBLanding {
  padding: 0;
  z-index: 1;
}
.searchIconWrapper {
  width: 60px;
  height: 60px;
  background-color: #ffcd05;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  right: 6px;
  top: 6px;
  @media (max-width: 767px) {
    width: 46px;
    height: 46px;
    right: 5px;
    top: 5px;
  }
}
.searchIconWrapper svg {
  width: 20px;
}

.fieldLocationBLanding {
  height: 72px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  background: #ffffff;
  @media (max-width: 767px) {
    height: 56px;
  }
}
.fieldLocationBLanding input {
  height: 72px;
  border: 0;
  width: 100%;
  padding: 15px 100px 15px 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: none !important;
  @media (max-width: 767px) {
    height: 56px;
    padding: 15px 60px 15px 15px;
  }
  &:hover{
    @media (min-width: 768px) {
      border: 1px solid transparent !important;
    }
  }
  &:focus{
    @media (min-width: 768px) {
      border: 1px solid #ffcd05 !important;
    }
  }
}
.fieldLocationBLanding .hideSearchIconBLanding {
  display: none !important;
}
.fieldLocationBLanding .searchPredictionsBLanding {
  display: none;
}
.predictionsDropDown {
  top: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background: #ffffff;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000024;
  border-radius: 4px;
  margin-top: 7px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
}
.isVisible {
  opacity: 1;
  visibility: visible;
}
.predictionsDropDown .currentLocation svg {
  margin: 0 10px 0 0;
}
.predictionsDropDown ul {
  margin: 0 0 10px;
}
.predictionsDropDown ul li {
  color: #0d6786;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  cursor: pointer;
  position: relative;
}
.predictionsDropDown ul li::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  width: 0;
  height: 100%;
  background: #0d6786;
}
.predictionsDropDown ul li:hover {
  opacity: 0.85;
}
.predictionsDropDown ul li:hover::before {
  width: 5px;
}
.predictionsDropDown .searchLabels {
  color: #b2b2b2;
  height: 40px;
  font-size: 16px;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 8px 20px;
}
.predictionsDropDown .suggestionTitle {
  font-size: 16px;
  line-height: normal;
  color: #b2b2b2;
}

.attributionClassName{
  margin-top: 15px;
}

.insuranceListingWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 25px;

  @media (--viewportLarge) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: var(--matterColorLight);
    margin-top: 0;
    padding: 30px 0 0;
  }
  @media (max-width: 767px) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--matterColorLight);
    margin: -20px 0 0;
    padding: 30px 25px 0;
  }
}

.insuranceListingImage {
  width: auto;
}

.insuranceListingText {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  margin-bottom: 12px;

  @media (--viewportLarge) {
    font-size: 15px;
  }
}

.approvedByCompanyLogo {
  width: 100%;
  height: auto;
}

/* Banner section Ends */

/* Banner channel */
.channelOuter {
  padding: 22px 15px;
  max-width: 1240px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 22px 15px 40px;
  }
}

.channelsGridRow {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: 767px) {
    padding: 0 15px;
    justify-content: space-between;
  }
}

.channelItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: pointer;
  &:nth-child(1){
    @media (max-width: 767px) {
      flex: 0 0 30%;
      max-width: 30%;
    }  
  }
  &:nth-child(2){
    @media (max-width: 767px) {
      flex: 0 0 10%;
      max-width: 10%;
    }  
  }
  &:nth-child(3){
    @media (max-width: 767px) {
      flex: 0 0 30%;
      max-width: 30%;
    }  
  }
  &:nth-child(4){
    @media (max-width: 767px) {
      flex: 0 0 30%;
      max-width: 30%;
    }  
  }
  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
  @media (max-width: 767px) {
    height: 40px;
    padding: 0 5px;
    flex: 2;
  }
}

.channelItemImage {
  width: auto;
  height: auto;
  max-width: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

/* Banner channel Ends */

/* Section cars */
.carsListingsTitleWrapper {
  text-align: center;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}
.carsListingsTitleWrapper .carsListingsSectionTitle {
  font-size: 48px;
  line-height: normal;
  font-weight: 900;
  color: #0d6786;
  @media (max-width: 767px) {
    text-align: left;
    font-size: 24px;
    line-height: normal;
    margin-bottom: 0;
  }
}
.carsListings {
  max-width: 1240px;
  margin: 0 auto;
  padding: 105px 20px 60px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 30px 20px;
  }
}
.carsListings .sliderWrapper {
  padding: 0;
}
.btnWrapper {
  text-align: center;
  @media (max-width: 1024px) {
    margin-top: 25px;
  }
}
.viewAllCar {
  width: 320px;
  height: 60px;
  border-radius: 4px;
  background: #fecd2a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #0d6786;
  border: 1px solid #fecd2a;
  text-decoration: none !important;
  font-weight: 900;
  &:hover {
    opacity: 0.75;
  }
  @media (max-width: 1024px) {
    height: 48px;
    background-color: #0d6786;
    border: 1px solid #0d6786;
    color: #FFFFFF;
    font-weight: 500;
  }
}
.carsListingsGrid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: 767px) {
    display: block;
  }
}
.carsListingsCardWrapper {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: 0 15px;
  margin-bottom: 50px;
  display: none;
  &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3){
    display: block;
  }
  @media (max-width: 1024px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px 0 0;
    margin-bottom: 0;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* section cars Ends */

/* section grids */
.sectionGrids {
  padding: 50px 0 10px;
  @media (max-width: 767px) {
    padding: 30px 0 20px;
  }
}
.gridBox {
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}
.gridBoxInner {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
}
.gridBoxInner .gridThumb {
  position: relative;
  height: 0;
  padding-bottom: 50%;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-bottom: 72%;
  }
}
.gridBoxInner .gridThumb::after {
  content: '';
  position: absolute;
  width: calc(100% + 50px);
  height: 50px;
  background: #ffffff;
  top: 100%;
  left: 0;
  transform-origin: 0 0;
  transform: rotate(-5deg);
}
.gridBoxInner .gridThumb img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
}
.gridBoxInner .gridThumb .thumbContent {
  position: absolute;
  z-index: 9;
  max-width: 219px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}
.gridHorizontal .gridBoxInner .gridThumb .thumbContent {
  width: 103px;
  height: 51px;
}
.gridHorizontal .gridBoxInner .gridThumb .thumbContent svg {
  width: 100%;
  height: 100%;
}
.gridBoxInner .gridContent {
  padding: 20px;
  @media (max-width: 767px) {
    padding: 20px 10px;
  }
}
.gridBoxInner .gridContent .title {
  font-size: 28px;
  color: #026786;
  line-height: normal;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}
.gridBoxInner .gridContent p {
  font-size: 22px;
  color: #4a4a4a;
  line-height: normal;
  font-weight: 500;
  margin: 0 0 10px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.gridBoxInner .gridContent ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.gridBoxInner .gridContent ul li {
  margin: 0 0 10px;
}
.gridBoxInner .gridContent ul li svg {
  vertical-align: baseline;
  display: inline-block;
  margin: 0 10px 0 0;
}
.gridBoxInner .gridContent .btnWrapper {
  padding: 20px 0 0;
  text-align: left;
}
.gridBoxInner .btnWrapper .btnMore {
  min-width: 214px;
  height: 49px;
  padding: 10px 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: normal;
  color: #026786;
  border: 1px solid #026786;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  transition: 0.3s all ease;
  text-decoration: none !important;
  @media (max-width: 767px) {
    min-width: 100%;
  }
  &:hover {
    background: #026786;
    color: #ffffff;
  }
}
.gridHorizontal .gridBoxInner {
  display: flex;
  flex-wrap: wrap;
}
.gridHorizontal .gridBoxInner .gridThumb {
  max-width: 347px;
  min-width: 347px;
  flex: 1;
  position: relative;
  overflow: hidden;
  padding-bottom: 30%;
  @media (max-width: 767px) {
    min-height: 20px;
    max-height: 100%;
    height: 0;
    padding-bottom: 72%;
    min-height: 20px;
    max-height: 100%;
    height: 0;
    width: 100%;
    min-width: 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.gridHorizontal .gridBoxInner .gridThumb::after {
  height: calc(100% + 50px);
  width: 50px;
  background: #ffffff;
  bottom: 0;
  left: auto;
  transform-origin: 0 100%;
  transform: rotate(5deg);
  right: 0;
  top: auto;
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 50px;
    background: #ffffff;
    top: 100%;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-5deg);
  }
}
.gridHorizontal .gridBoxInner .gridContent {
  flex: 1;
}
.gridHorizontal .gridContent .horizontalList {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  padding-top: 20px;
}
.gridHorizontal .horizontalList li {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 22px !important;
  }
}
.gridHorizontal .horizontalList .iconThumb {
  max-width: 50px;
  flex: 1;
}
.gridHorizontal .horizontalList .iconText {
  flex: 1;
  padding-left: 10px;
  max-width: 172px;
  @media (max-width: 767px) {
    max-width: 142px;
  }
}
.gridHorizontal .btnWrapper .btnMore {
  border: 1px solid #fecd2a;
  background: #fecd2a;
  color: #026786;
}
.gridHorizontal .gridContent .badgeListing {
  list-style: none;
  margin: 0;
  padding: 0;
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}
.gridHorizontal .gridContent .badgeListing li {
  display: inline-block;
  margin-right: 15px;
  @media (max-width: 767px) {
    margin-right: 0;
    padding: 0 10px;
  }
}
.gridHorizontal .gridContent .badgeListing li button {
  display: block;
  padding: 0;
  border: 0;
  cursor: pointer;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}
.gridHorizontal .gridContent .badgeListing li button img {
  @media (max-width: 767px) {
    display: block;
    width: 100%;
  }
}
.gridHorizontal.gridImageRight .gridBoxInner .gridThumb {
  padding-bottom: 17%;
  order: 2;
  background: #00a3ad;
  @media (max-width: 767px) {
    order: -1;
    min-height: 20px;
    max-height: 100%;
    height: 0;
    padding-bottom: 72%;
    width: 100%;
    min-width: 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.gridHorizontal.gridImageRight .gridBoxInner .gridThumb::after {
  left: 0;
  right: auto;
  transform: rotate(-5deg);
  transform-origin: 100% 100%;
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 50px;
    background: #ffffff;
    top: 100%;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-5deg);
  }
}
/* section grids Ends */

/* Banner stories */

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 270px;
}

.testimonialsContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--storyBackgroundColor);
  border-radius: 10px;
  padding: 0 0 45px 0;
}

.testimonialsContent {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  padding: 0 15px 40px;
  max-width: 375px;
  min-height: 232px;
  text-align: center;
}

.testimonialsAvatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -35px;
}

.testimonialsAvatar {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
}

.sectionTestimonials {
  /* padding-top: 40px; */
  padding-bottom: 25px;
  padding-top: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
}

.sectionTestimonials :global(.slick-list) {
  padding: 0;
}
.sectionTestimonials :global(.slick-dots) {
  bottom: -30px;
}

.sectionTestimonials :global(.slick-slide) {
  /* margin-left: 30px;

  @media (--viewportLarge) {
    &:first-child {
      margin-left: 40px;
    }
  }

  @media (--viewportXLarge) {
    margin-left: 36px;
  } */
}

.sectionTestimonials :global(.slick-disabled) {
  opacity: 0.5;
}

.sectionTestimonials :global(.slick-prev) {
  left: -45px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/prev.png');
  &::before {
    content: '';
  }
}

.sectionTestimonials :global(.slick-next) {
  right: -45px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/next.png');
  &::before {
    content: '';
  }
}

.sectionTestimonials :global(.slick-dots) {
  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionTestimonials :global(.slick-active) {
  opacity: 1 !important;
}
.sectionTestimonials :global(.slick-active) div {
  outline: none !important;
}
/*
.sectionTestimonials :global(.slick-list) {
  padding: 0 0 0 30px;
}

.sectionTestimonials :global(.slick-slide) {
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

.sectionTestimonials :global(.slick-dots) {

  margin-top: 40px;

  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionTestimonials :global(.slick-active) {
  opacity: 1 !important;
} */

.testimonialsWrapper {
  padding: 0 20px 0 0;
  padding: 0 30px 0 0;
}

.testimonialsAvatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.testimonialsName {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  text-align: center;
  margin-top: 7px;
  /* transform: translateX(-50%); */
}

.sectionTestimonialsDescription {
  display: none;

  @media (--viewportLarge) {
    color: var(--marketplaceColorDark);
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    display: block;
  }
}

.sectionTestimonialsTitleWrapper {
  padding: 0 30px;

  & > .sectionTestimonialsTitle {
    white-space: pre-wrap;
    margin-bottom: 10px;
  }

  @media (--viewportLarge) {
    text-align: center;
    margin: 0 auto;
    & > .sectionTestimonialsTitle {
      white-space: nowrap;
    }
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.sectionTestimonialsTitle {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  @media (--viewportLarge) {
    text-align: center;
  }

  @media (--viewportMLarge) {
    font-size: 48px;
    line-height: normal;
    text-align: center;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

.sliderWrapper {
  margin-top: 50px;
  margin: 50px auto 0;
  padding: 0 50px;
  max-width: 1240px;
  width: 100%;
  @media (--viewportLarge) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    max-width: 100%;
    margin: 0;
    margin-top: 20px;
  }
}

.slider {
  height: 100%;
  display: block;
  margin-top: 20px;

  /* @media (--viewportLarge) {
    max-width: calc(calc(270px * 3) + calc(30px * 3));
    margin: 0 auto;
  }

  @media (--viewportMLarge) {
    max-width: calc(calc(270px * 4) + calc(30px * 4));
  }

   @media (--viewportXLarge) {
    max-width: calc(calc(388px * 4) + calc(36px * 4));
  } */
}
/* Banner stories Ends */

/* Banner help */

.sectionHelpBLanding {
  padding: 40px 30px;
}

.helpQuestionsBLanding {
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 100px;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 50px;
  }
}

.sectionHelpTitleBLanding {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  white-space: pre-wrap;

  @media (--viewportLarge) {
    white-space: nowrap;
    text-align: center;
    font-size: 60px;
    line-height: 72px;
  }
}

.questionBLanding {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  padding-bottom: 20px;
  border-bottom: solid 1px var(--matterColorNegative);
  margin-top: 20px;
  letter-spacing: -0.4px;
  user-select: none;
  max-width: 500px;
}

.answerBLanding {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  display: none;
  white-space: pre-wrap;
  margin-top: 20px;
  user-select: none;
  max-width: 500px;
}

.showAnswerBLanding {
  display: block;
}

.searchHelpBLanding {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--marketplaceColor);
  padding-bottom: 20px;
  margin-top: 30px;

  @media (--viewportLarge) {
    max-width: 570px;
    width: 570px;
    margin: 30px auto 0;
    padding-left: 102px;
  }
}

.searchIconBLanding {
  width: 16px;
  height: 16px;
}

.searchHelpInputBLanding {
  border: none;
  margin-left: 10px;
  color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 30px;

  @media (--viewportLarge) {
    font-size: 32px;
    line-height: 32px;
  }
}
/* Banner help Ends */

.videoWrapper {
  padding: 0;

  @media (--viewportMedium) {
    padding: 0 20px;
    /* margin-top: 40px; */
  }

  @media (--viewportMLarge) {
    margin-top: 0px;
  }
}

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.video {
  width: 280px;
  background-color: var(--matterColorAnti);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 313px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.locationLoader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locationLoader span {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00a3ad;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}


/* Rental categories section */
.rentalCarCategories {
  background: rgba(242, 242, 242, 1);
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.rentalCarCategoriesContainer {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}
.rentalCarCategoriesRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}
.columnEight {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
}
.columnTwo {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.rentalCarCategoriesRow .columnTitle {
  font-size: 18px;
  line-height: normal;
  color: #026786;
  font-weight: 300;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}
.rentalCarCategoriesRow .columnTitle::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 92px;
  height: 1px;
  background: #18a3ad;
}
.rentalCarCategoriesRow .categoriesListing {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.rentalCarCategoriesRow .categoriesListing li {
  font-size: 16px;
  line-height: 2;
  color: #026786;
  font-weight: 300;
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.rentalCarCategoriesRow .columnTwo .categoriesListing li {
  flex: 0 0 100%;
  max-width: 100%;
}