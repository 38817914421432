@import '../../marketplace.css';

.root {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: -10px;
  color: var(--failColor);
  overflow: hidden;
  /*position: absolute;*/
  line-height: 16px;


  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}


.inline {
  top: 100%;
}
