@import '../../marketplace.css';

.buttonContainer {
  /* border: solid 2px black; */
  width: fit-content;
  margin-top: 10%;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  color: #026786;
  background-color: #FFCD05;
}