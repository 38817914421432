.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);
  position: static;
  top: 0;
  left: 0;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: transparent;

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

.outerContainer {
  max-width: 750px;
  margin: 0 auto 100px 15%;
  
  @media (--viewportMobile) {
    margin: 0 auto 100px auto;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (--viewportMobile) {
    padding: 0 20px;
  }
}

.header>p {
  /* border: solid 2px blue; */
  font-size: 36px;
  line-height: 56px;
  font-weight: 900 !important;
  color: #026786;

  @media (--viewportMobile) {
    font-size: 28px;
    line-height: 34px;
  }
}

.subHeader {
  /* border: solid 2px blue; */
  font-size: 20px;
  line-height: 24px;
  font-weight: 500 !important;
  color: #4A4A4A;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 23px;
    padding: 0 20px;
  }
}

.header .helpBtn {
  clear: both;
  display: block;
}

.header .needHelpBtn {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 42px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 4px;
  color: #026786;
  opacity: 1;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid #026786;
  @media (--viewportMobile) {
    width: 106px;
    height: 32px;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 5px;
  }
}

.header .needHelpBtn img {
  padding-right: 5px;
  width: 25px;
  height: 30px;
  @media (--viewportMobile) {
    width: 17px;
    height: 20px;
  }
}

.unpaidInvoicesContainer {
  /* margin: 0.5%;
  padding: 1%; */
}

.invoiceListContainer {
  min-height: 166px;
  background-color: #FFFFFF;
  border: solid 1px #E6E6E6;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.invoiceAmount {
  margin: 10px 0 12px 0px;
  color: #026786;
  font-size: 24px;
  font-weight: 700 !important;
  
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 22px;
  }
}

.invoiceNumber {
  margin: 0;
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 22px;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 17px;
  }
}
.invoiceDescription {
  margin: 0;
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 22px;
  
  @media (--viewportMobile) {
    line-height: 19px;
    font-size: 14px;
  }
}

.invoiceContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.payButton {
  width: 116px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #026786;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.16px;
  padding: 0;
  @media (--viewportMobile) {
    width: 91px;
    height: 39px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.16px;
  }
}

.payButton:hover {
  background: #005A6E;
}

.bottomBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  box-shadow: 0px -3px 6px #00000014;
  background-color: #FFFFFF;
  z-index: 1000;

  @media (--viewportMobile) {
    padding: 0 20px;
  }
}

.bottomBar>p {
  font-size: 22px;
  line-height: 32px;
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 17px;
  }
}

.bottomBar>button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 60px;
  background-color: #FFCD05;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 4px;
  color: #026786;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700 !important;
  margin: 0;
  padding: 0;
  
  @media (--viewportMobile) {
    margin-right: 0;
    margin-left: 10px;
    height: 48px;
    font-size: 18px;
    line-height: 18px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

