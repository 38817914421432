@import '../../marketplace.css';

:root {
  --TopbarMobileMenu_topMargin: 96px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: auto;
}

.space {
  flex-basis: 25%;
}

.content {
  flex-basis: 75%;
  background-color: var(--marketplaceColor);
  display: flex;
  flex-direction: column;
}

.links {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}

.linkWrapper {
  padding: 9px 20px;
}

.link {
  @apply --marketplaceLinkStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
}

.avatarWrapper {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--matterColorNegative);
}

.userName {
  @apply --marketplaceLinkStyles;
  color: var(--marketplaceSecondaryColor);
  font-weight: var(--fontWeightMedium);
  margin-left: 10px;
}

.logout {
  @apply --marketplaceLinkStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  padding: 20px;
  border-top: solid 1px var(--matterColorNegative);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 25%;
}

.signUpLinkWrapper {
  padding: 20px;
  border-bottom: solid 1px var(--matterColorNegative);
}

.authLink {
  color: var(--marketplaceSecondaryColor);
}

.dropdownContent {
  padding: 4px 10px;
  display: block;
  font-size: 16px;
}

.arrowIcon {
  margin-left: 5px;
  width: 10px;
  height: 10px;
  color: var(--matterColorLight);
  transition: transform 0.3s ease; /* Smooth rotation */
}

.arrowIconOpen {
  transform: rotate(180deg); /* Rotate 180 degrees when open */
}

.dropdownLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  gap: 2px;
  margin-bottom: 6px;
}