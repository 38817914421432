@import '../../marketplace.css';

.root {
  padding: 0 24px;
  margin-bottom: 35px;

@media (--viewportMedium) {
  padding: 0;
  margin-bottom: 52px;
}
}

.title {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 13px;
@media (--viewportMedium) {
  margin-top: 0;
  margin-bottom: 20px;
}
}

.noUploadLoadText {
  font-size: 16px;
  color: var(--marketplaceColor);
  cursor: pointer;
}

.placeholderPhoto {
  width: 210px;
  height: 140px;
  background-color: var(--matterColorNegative);
  color: var(--marketplaceColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.inputField {
  display: none;
}

.content {
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;


@media (--viewportMedium) {
  padding: 0px 20px 20px 46px;
}
}

.modalContent {
  padding: 65px 0px;

@media (--viewportMedium) {
  padding: 0px;
}
}

.modalTitle {
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: center;
}

.uploadContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;

@media (--viewportMedium) {
  margin-bottom: 50px;
}
}

.uploadBox {
  flex-basis: 100%;
  border: 1px dashed var(--matterColorAnti);
  cursor: pointer;
  margin-bottom: 20px;

@media (--viewportMedium) {
  margin-bottom: 0px;
  flex-basis: 47%;
}
}

.uploadBoxInner {
  position: relative;
  padding-bottom: calc(100% * (2 / 3));
}

.uploadBoxContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadBoxText {
  font-size: 18px;
}

.uploadBoxFormat {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColorAnti);
}

.uploadInputNote {
  flex-basis: 100%;

@media (--viewportMedium) {
  flex-basis: 47%;
}
}

.inputTextLabel {

}

.imageFromFileWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.addPhotoBtn {
  @apply --marketplaceButtonStylesPrimary;
  margin-top: 20px;
  padding: 0px;
  min-height: 45px;
  width: 150px;
  border-radius: 5px;
  font-size: 18px;
}

.uploadLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--matterColorLightTransparent);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.uploadIcon {
  fill: white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.successUpload {
  font-size: 18px;
  color: var(--successColor);
  margin: 10px 0px;
}

.actionTransitButton {
  margin: 0 auto 30px;

@media (--viewportMedium) {
  margin-bottom: 50px;
}
}

.yourPhotos {
  margin-bottom: 25px;

@media (--viewportMedium) {
  margin-bottom: 40px;
}
}

.pickUpPhotosHolder {
  display: flex;
}

.pickUpPhotosUploadContainer {
  flex: 0 0 60px;
  max-width: 60px;
  height: 60px;
  margin: 0;
  background: #2B6583 ;
  border-radius: 8px;
  margin-right: 5px;

& .uploadBox {
    margin: 0;
    flex: 0 0 100%;
    border: none;
    position: relative;
    cursor: pointer;
  }

& .uploadBoxInner {
    padding-bottom: calc(100% * (2 / 2));
    border-radius: 8px;
    overflow: hidden;
  }
}

.photosContainer.pickUpPhotosContainer {
  flex: 0 0 90%;
  justify-content: flex-start;

& .photoWrapper {
    flex: 0 0 60px;
    max-width: 60px;
    height: 60px;
    border-radius: 8px;
    position: relative;
    margin: 0 5px 0 0;
  }

& .photoWrapperInner {
    padding-bottom: calc(100% * (2 / 2));
    border-radius: 8px;
    overflow: hidden;
  }

& .removePhotoBtn {
    width: 20px;
    height: 20px;
  }
}

.photosContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.photosTitle {
  margin-bottom: 35px;

@media (--viewportMedium) {
  margin-bottom: 30px;
}
}

.photoWrapper {
  margin-bottom: 35px;
  flex-basis: 100%;

@media (--viewportMedium) {
  margin-bottom: 30px;
  flex-basis: 47%;
}
}

.photoWrapperInner {
  position: relative;
  padding-bottom: calc(100% * (2 / 3));
}

.removePhotoBtn {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--matterColorLightTransparent);
  color: var(--matterColor);
  font-size: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
}

.tripPhoto {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--matterColorBright);
  object-fit: cover;
  cursor: pointer;
}

.photoTime {
  margin: 10px 0 5px 0px;
  font-size: 16px;
  font-style: italic;
}

.photoNote {

}

.noTripPhotos {
  color: var(--matterColorAnti);
  font-size: 16px;
  font-style: italic;
  margin-bottom: 35px;

@media (--viewportMedium) {
  margin-bottom: 30px;
}
}

.pickUpReading {
  margin: 10px 0 30px;
}


.sectionPhotoHolder {
  border-top: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 20px 20px 46px;
}

.photoTitle {
  color: #026786;
  font-size: 22px;
  margin-bottom: 10px;
}

.photoDescription {
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}
