@import '../../marketplace.css';

.faqSection {
  width: 100%;
}

.innerWrapper {
  padding: 34px 20px;
  max-width: 100%;
  margin: auto;

  @media (--viewportMedium) {
    padding: 34px 0px;
    margin: 0 7.5vw
  }

  @media screen and (min-width: 1200px) {
    max-width: 1100px;
    margin: auto;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900;
  text-align: left;
  margin-bottom: 24px;
  text-transform: none;
  @media (--viewportMedium) {
    text-align: left;
    margin-bottom: 30px;
  }
}

.listTextBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.subBox {
  flex-basis: 100%;
  margin-bottom: 18px;
  margin-top: 18px;

  @media (--viewportMLarge) {
    flex-basis: 45%;
  }
}

.question {
  border-bottom: 3px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 5px;
  cursor: pointer;
}

.answer {
  font-size: 20px;
  line-height: 30px;
  background-color: var(--marketplaceColor);
  padding: 5px 15px 30px;
  color: var(--matterColorLight);
  margin-top: 2px
}

.helpline {
  width: 300px;
  margin-top: 50px;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    width: 400px;
    margin-left: auto;
  }
}

.link {
  color: white;
  text-decoration: underline;
}
