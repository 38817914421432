@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ContactDetailsForm_infoText: {
    @apply --marketplaceH4FontStyles;
    color: var(--successColor);

    display: inline-block;
    padding-left: 18px;
    background-repeat: no-repeat;
    background-position: top 7px left;

    margin-top: 11px;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 14px;
      margin-bottom: 1px;
    }
  }
}

.root {}

.phoneNumberForm p {
  color: black;
}

.verificationIndication p {
  display: none;
  color: #4A4A4A !important;
  font-size: 18px !important;
  font-weight: 500 !important;

  @media (--viewportMobile) {
    font-size: 15px !important;
  }

}

.phoneNumberSection {
  /* margin-top: 16px; */
  /* margin-bottom: 46px; */
  padding-top: 6px;
  display: inline;

  @media (--viewportMedium) {
    margin-bottom: 56px;
    /* padding-top: 0; */
  }
}

.phoneNumberFieldWrapper {
  /* margin-top: 24px; */
  width: 50%;
  margin-left: 10px;
  display: inline-block;

  @media (--viewportMobile) {
    width: 100% !important;
  }
}

.phoneNumberWrapper {
  display: flex;
  align-items: center;

}

.phoneCodeText {
  flex: 1;
}

.phone {
  flex: 6;
}

.phoneCode {
  margin-top: 24px;
  width: 40%;


  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.newInput {
  flex: 0 0 100%;
  width: 100% !important;
  margin: 0;
  /* margin-top: -8px; */
  color: #4a4a4a;

  @media (--viewportMobile) {
    max-width: 96% !important;
  }
}

.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
}

.phoneNumberLabel {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
}

.newInput input,
.newInput select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
}

.halfInputs {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  justify-content: left;

  @media (--viewportMobile) {
    display: block;
  }
}



.halfInputs .newInput {
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 80%;
  width: 100%;

  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* */
.newInputPhoneCode {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 20px !important;
  margin: 0;
  color: #4a4a4a;
}

.newInputPhoneCode label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
}

.newInputPhoneCode input,
.newInputPhoneCode select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
}



.halfInputs .newInputPhoneCode {
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  flex: 0 0 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 12px;

  @media (--viewportMobile) {
    /* flex: 0 0 100%; */
    max-width: 100%;
  }
}

/* */
.newInputCountry {
  flex: 0 0 100%;
  max-width: 50% !important;
  margin: 0;

  @media (--viewportMobile) {
    max-width: 100% !important;
  }
}

.newInputCountry label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
}

.newInputCountry input,
.newInputCountry select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
}



.halfInputs .newInputCountry {
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 80%;
  width: 100%;

  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.emailVerified {
  @apply --ContactDetailsForm_infoText;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
}

.emailUnverified {
  @apply --ContactDetailsForm_infoText;
  color: var(--matterColorAnti);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.pendingEmailUnverified {
  @apply --ContactDetailsForm_infoText;
  color: var(--failColor);
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.checkInbox {
  color: var(--matterColor);
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.helperLink {
  color: var(--matterColor);
  border-bottom: solid 1px var(--matterColor);
  line-height: 20px;
  margin: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.tooMany {
  color: var(--matterColorAnti);
}

.emailSent {
  color: var(--matterColor);
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  display: none;
  margin-top: 45px;
  width: 40%;


  @media (--viewportMobile) {
    width: 100%;
  }

}

.bottomWrapper button {
  background: #fecd2a;
  min-height: 50px;
  border-radius: 4px;
  text-transform: initial;

  &:hover {
    background: #fecd2a;
    color: #0d6786;
  }

  &:hover {
    background: #fecd2a;
    color: #0d6786;
  }

}


.bottomWrapper button span {
  font-weight: 700;
  font-size: 18px;
  color: #026786;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}