@import '../../marketplace.css';
.root {
  @apply --marketplaceModalFormRootStyles;
  justify-content: flex-start;
  @media (--viewportMobile) {
    margin: 20px 0 0 0 !important;
  }
  --ProfileSettingsForm_avatarSize: 100px;
}

/* .name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
} */

.gender {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.validationReferralError{
  font-size: 16px;
  color: #ff0000;
  line-height: 1.2;
  padding-top: 10px;
}

.genderSelect {
  width: 100%;
}

/* .firstNameRoot {
  width: calc(44% - 9px);
}

.lastNameRoot {
  width: calc(56% - 9px);
} */

.birthDate input {
  text-align: center;
}

.fieldMargin {
  display: flex;
  margin-right: 10px;
}

.birthdayWrapper {
  display: flex;
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.code {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 36px;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.buttonWrapper {
  margin-top: 40px !important;
}

.buttonContainer {
  display: flex;
  margin-top: 30px;
}

.primaryRoot {
  border: 1px solid #00a3ad;
  border-radius: 5px;
  background-color: #ffffff;
  color: #00a3ad;
  width: 30%;
  padding: 0;
  font-size: 18px;
  cursor: pointer;
  @media (--viewportMedium) {
    width: 179px;
  }
  @media screen and (max-width: 375px) {
    width: 100px;
  }
}

.secondaryRoot {
  @apply --marketplaceButtonStylesSecondary;
  margin-left: 10px;
  width: 70%;
  border: none;
  cursor: pointer;
  background: #fecd2a !important;
  color: #0d6786 !important;
  font-size: 18px;
  line-height: 48px;
  min-height: auto;
  text-transform: none;
  border-radius: 5px;
  padding: 0;
  @media (--viewportMedium) {
    width: 331px;
  }
  @media screen and (max-width: 375px) {
    width: 225px;
  }
}

/* .termsText {
  @apply --marketplaceModalHelperText;
} */

.termsLink {
  @apply --marketplaceModalHelperLink;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.invalidPassword {
  margin-top: 40px;
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
  @media (--viewportMobile) {
    display: none;
  }
}

.logo img {
  @media (--viewportMedium) {
    height: 24px;
    width: auto;
  }
}

.title {
  text-align: center;
  color: #026786;
  position: relative;
  margin: 0;
  padding-bottom: 10px;
  @media (--viewportMedium) {
    font-size: 36px;
  }
  @media (--viewportMobile) {
    font-size: 28px;
  }
}

.title::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  background: var(--marketplaceColorDark);
}

.root {
  margin: 20px 0;
}

.formInput {
  @apply --marketplaceModalPasswordMargins;
}

.centerBlock {
  width: 40px;
  @media (--viewportMobile) {
    width: 20px;
  }
}

.name {
  display: flex;
  @media screen and (max-width: 414px) {
    flex-direction: row;
  }
}

.firstName {
  width: calc(44% - 9px);
}

.lastName {
  width: calc(56% - 9px);
}

.bottomWrapper {
  margin: 20px 0 0;
  @media (--viewportMobile) {
    margin: 15px 0 0;
  }
}

.bottomWrapper .signInButton {
  background: #fecd2a !important;
  color: #0d6786 !important;
  font-size: 18px;
  line-height: 48px;
  min-height: auto;
  text-transform: none;
  border-radius: 5px;
}

.bottomWrapper .bottomWrapperText {
  margin-top: 10px;
  margin-bottom: 0;
}

.termsText {
  font-size: 16px;
  line-height: normal;
  color: var(--matterColorAnti);
  @media (--viewportMobile) {
    font-size: 13px;
  }
}

.loginWith {
  text-align: center;
}

.loginWith .text {
  color: #b2b2b2;
  font-size: 18px;
  display: block;
}

.loginWith .text h3 {
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 30px;
  @media (--viewportMobile) {
    margin-bottom: 30px;
  }
}

.loginWith .text h3 span {
  background-color: white;
  position: relative;
  top: 10px;
  padding: 0 10px;
}

.loginWith .loginUsing {
  padding: 12px 20px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.loginUsing:last-child {
  background: #e9413505;
}

.loginWith .loginUsing a {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #0d6786;
  text-decoration: none;
}

.loginWith .loginUsing .btnText {
  width: calc(100% - 24px);
}

.signupButton {
  background: #0267860a !important;
  padding: 12px 20px;
  font-size: 18px !important;
  color: #4a4a4a !important;
  text-transform: initial !important;
  line-height: 22px !important;
  min-height: auto !important;
  font-weight: normal !important;
}

.signupButton a {
  text-decoration: none !important;
}

.sectionTitle {
  font-size: 18px;
  color: var(--marketplaceColorDark);
  margin: 0;
  font-weight: 800;
  margin-bottom: 10px;
}

/* Profile Image */

.uploadAvatarInput {
  display: none;
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
}

.avatarPlaceholder, .avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);
  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: 4px;
  cursor: pointer;
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--marketplaceColorDark);
  border-width: 2px;
  transition: var(--transitionStyleButton);
  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.avatarPlaceholderText {
  display: none;
  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100% !important;
  height: 100% !important;
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: 105px;
  height: 41px;
  padding: 10px 10px 11px 35px;
  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 7px 10px 11px 35px;
  }
  &:hover {
    border: solid 1px var(--matterColorAnti);
  }

  @media (--viewportMobile) {
    right:0;
    bottom:-20px;
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);
  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: 4px;
  overflow: hidden;
  display: block;
  position: relative;
  margin: 0;
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;
  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */

/* Default is 3:2 */

.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.formInputwithLabel select,
.formInputLabel select{
  background-position: calc(100% - 15px);
}

/* SignUp Step2 Form Css */

.formInputwithLabel {
  margin-top: 14px;
}

.dateOfBirthFieldBox, .phoneFieldBox {
  display: flex;
}

.phoneFieldBox .formInput input {
  padding: 13px;
  width: 150px;
}

.formInputwithLabel label,
.dateOfBirthField label,
.phoneNumberWrapper label {
  margin-bottom: 5px;
  color: var(--matterColor);
  font-size: 16px;
  font-weight: 300;
}

.formInputwithLabel input,
.formInputwithLabel textarea,
.formInputwithLabel select {
  border-bottom: none;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 8px;
  font-size: 18px;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.formInputwithLabel input:hover,
.formInputwithLabel input:focus,
.formInputwithLabel textarea:hover,
.formInputwithLabel textarea:focus,
.formInputwithLabel select:hover,
.formInputwithLabel select:focus,
.phoneFieldBox input:focus,
.phoneFieldBox input:hover {
  border-bottom: 1px solid #E6E6E6;
}

.dateOfBirthFieldBox .fieldInlineBlock {
  display: flex;
  &:nth-child(2) {
    margin: 0 10px;
    @media (--viewportMobile) {
      margin: 0 5px;
    }
  }
}

.dateOfBirthFieldBox .fieldInlineBlock input,
.phoneFieldBox .phoneCodeText,
.phoneFieldBox input {
  height: 54px;
  width: 48px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  text-align: center;
  padding: 0;
}

.dateOfBirthFieldBox .fieldInlineBlock input{
  @media (--viewportMobile) {
    width: 33px;
    height:42px
  }
}

.dateOfBirthField .fieldInlineBlock div:first-child input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dateOfBirthField .fieldInlineBlock div:nth-child(2) input,
.dateOfBirthField .fieldInlineBlock div:nth-child(3) input,
.dateOfBirthField .fieldInlineBlock div:nth-child(4) input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.dateOfBirthField .fieldInlineLastBlock div:nth-child(2) input,
.dateOfBirthField .fieldInlineLastBlock div:nth-child(3) input{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.phoneFieldBox div {
  @media (--viewportMobile) {
    width: 100%;
    max-width: 265px;
  }
}

.phoneFieldBox input {
  width: 122px;
  @media (--viewportMobile) {
    width: 100%;
    text-align: left;
    padding: 0 17px;
  }
}

.phoneFieldBox .phoneCodeText {
  height: 54px;
  width: 65px;
  color: var(--matterColorAnti);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nextStepButton {
  margin-top: 40px !important;
  background: #FECD2A !important;
  color: #0D6786 !important;
  font-size: 18px !important;
  line-height: 48px !important;
  min-height: auto !important;
  text-transform: capitalize !important;

  @media (--viewportMobile){
    margin-bottom:15px !important;
  }
}

/* Signup step5 and step6 Form css */
.profileDetail {
  display: flex;
  margin: 10px 0 20px;
}

.profileDetail .profileImageDesc {
  font-size: 14px;
  max-width: 225px;
  margin-left: 10px;
  line-height: 21px;
}

.profileFormInput {
  margin-top: 5px !important;
}

.addressDetail label {
  margin-top: 0;
  font-weight: 300;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 16px;
}

.addressDetail .formInputwithLabel{
  max-width: 100%;
  margin-top: 0;
}

.addressDetail .formInputwithLabel textarea {
  width: 317px;
}

.addressDetail .sectionTitle {
  margin-top: 20px;
}

.addressDetail .profileFormInput div {
  display: none;
}

.addressRow {
  display: flex;
  justify-content: space-between;
  @media (--viewportMobile) {
    flex-direction: column;
  }
}

.addressRow .column {
  margin-top: 10px;
  background: var(--matterColorBright);
  border: 1px solid #E6E6E6;
  padding: 10px;
  color: var(--marketplaceColorDark);
  &:first-child {
    margin-right: 15px;
  }
  &:nth-child(2) {
    margin-left: 15px;
  }
}

.addressRow .columnWidth {
  margin-top: 10px;
  background: var(--matterColorBright);
  border: 1px solid #E6E6E6;
  padding: 10px;
  color: var(--marketplaceColorDark);
  width: 335px;
}

.addressRow .columnWidth label {
  font-size: 18px;
  font-weight: 400;
}

.phoneNumberWrapper {
  display: flex;
}

.phone {
  flex: 6;
}

.phoneCode {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.phoneCodeSelect {
  width: 67px;
  margin-right: 10px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  outline: none;
}

.phoneCodeSelect select {
  background-image: none;
  padding: 11px 12px;
  border-bottom: none;
}

.phoneNumberField {
  width: 150px;
  @media (--viewportMobile) {
    width: 100%
  }
}

.noMargin {
  margin-top: 0 !important;
}

.minYearError {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: -10px;
  color: var(--failColor);
  overflow: hidden;
  /*position: absolute;*/
  line-height: 16px;
  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.displayInlineContainer {
  margin-top: 10px;
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.column6 {
  flex: 0 0 50%;
  max-width: 47%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.addressDetail .nrcFileUpload{
  border: 1px solid #E6E6E6;
  padding: 10px;
  background: #FCFCFC;
  display: block;
  margin-bottom: 0;
  @media (--viewportMobile){
    margin-bottom:10px;
  }
}

.addressDetail .nrcFileUpload label{
  display: block;
  font-size: 18px;
  line-height: normal;
  color: #026786;
  margin-bottom: 10px;
  padding: 0;
}

.addressDetail .nrcFileUpload div{
  width:100%;
}

.addressDetail .nrcFileUploadInputWrapper{
  display: block;
  width: 100%;
}
.nrcFileUploadInputWrapper > div{
  display: block;
  width: 100%;
}

.addressDetail .nrcFileUpload button{
  display: block;
  border: 1px dashed #026786;
  width: 100%;
  height: 100px;
  background: transparent;
  box-shadow: none;
  border-radius: 4px;
}

.LicenseTypeSelect {
  width: 335px;
}

.icon {
  display: none;
}

.addressRow .formInputwithLabel {
  width: 50%;
  margin-top: 20px;
  @media (--viewportMobile) {
    width: 100%;
    margin-top: 10px;
  }
  &:first-child {
    margin-right: 15px;
    @media (--viewportMobile) {
      margin-right: 0;
    }
  }
  &:nth-child(2) {
    margin-left: 15px;
    @media (--viewportMobile) {
      margin-left: 0;
    }
  }
}
/* Sign up step3 css */
.otp {
  display: flex;
  margin: 40px 0 30px;
  @media (--viewportMobile) {
      margin: 40px 0 20px;
      justify-content: space-between;
  }
}

.otp .numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 52px;
  height: 60px;
  border: 1px solid #E6E6E6;
  text-align: center;
  border-radius: 4px;
  font-size: 18px;
  padding: 0;
  @media (--viewportMobile) {
      font-size: 16px;
      margin-right: 5px;
      color:#80B2C2;
  }
}

.otp .numbers input{
  padding: 0;
  height: 60px;
  font-size: 18px;
  color: var(--matterColorAnti);
  border-radius:4px;
  @media (--viewportMobile){
    width: 40px;
    height: 44px;
  }
}

.resendPara {
  font-size: 18px;
  color: var(--marketplaceColorDark);
  margin: 0;
  line-height: 22px;
  @media (--viewportMobile) {
      font-size: 18px;
  }
}

.resendPara .resend {
  color: var(--marketplaceColor);
  cursor: pointer;
}



/* Sign up step7 css */
.profileApprovalContainer{
  margin: 20px 0 30px;
}
.formDesc{
  font-size: 18px;
  color: #4a4a4a;
  margin: 0;
  line-height: 24px;
}

.profileApprovalContainer .nextStepButton{
  margin-top: 10px !important;
  max-width: 500px;
  margin: 0 auto;
}

.profileApprovalContainer .insertLottieContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profileApprovalContainer .insertLottieContainer .content{
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid #707070;
  color:var(--matterColorAnti);
  height: 200px;
  width: 200px;
}

.inputContainer {
  position: relative;
}

.eyeIcon {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.facebook {
  background: #1877F2 0 0 no-repeat padding-box;
  color: #FFFFFF;
}

.google {
  background: #FFFFFF 0 0 no-repeat padding-box;
  color: #000000
}

.googleBorder {
  border: 1px solid #E6E6E6;
}

.apple {
  background: #000000 0 0 no-repeat padding-box;
  color: #FFFFFF;
}

.formInput {
  margin-top: 20px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  @media (--viewportMobile){
    margin-top:15px;
  }
}

.formInput label {
  display: none;
}

.formInput input {
  border-bottom: none;
  padding: 12px 20px;
  font-size: 18px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.errorRoot {
  width: 200px;
}

.fieldLabel {
  font-size:16px;
  font-weight: 300;
  letter-spacing: 0;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 20px;
}

.roleSelect {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  outline: none;
}

.roleSelect select {
  background-position: 95% center;
  padding: 11px 15px;
  border-bottom: none;
}

.birthdaySelect {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  outline: none;
}

.day, .month {
  margin-right: 10px;
  flex: 1;
}

.year {
  flex: 1;
}

.birthdaySelect select {
  background-image: none;
  padding: 11px 15px;
  border-bottom: none;
}

.confirmTitle {
  font-size: 30px;
  color: var(--marketplaceColorDark);
  margin-bottom: 10px;
  margin-top: 0;
  @media (--viewportMobile) {
    font-size: 28px;
  }
}

.confirmText {
  font-size: 20px;
  @media (--viewportMobile) {
    font-size: 18px;
    font-weight: 500;
  }
}

.confirmModalRoot {
  max-width: 520px;
}
