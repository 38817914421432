@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPhotosForm_imageWidthDesktop: calc(50% - 12px);
}

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.fieldSet {
  padding: 40px 0px;
  border-top: 1px solid var(--matterColorNegative);
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
  font-size: 14px;
}

.errorRequired {
  color: var(--failColor);
  margin-bottom: 30px;
  font-size: 14px;
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColorDark);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.editLocation {
  position: absolute;
  right: 0px;
  bottom: 40px;
  width: 50px;
  font-size: 20px;
  color: var(--marketplaceColorDark);
  cursor: pointer;
}

.locationAddress,
.city {
  flex-shrink: 0;
}

.floor {
  flex-shrink: 1;
  flex-grow: 1;
  @media (--viewportMedium) {
    margin-left: 4%;
    flex-basis: 40%;
  }
}

.streetName {
  flex-basis: 70%;
  flex-shrink: 1;
  margin-bottom: 16px;
  flex-grow: 30;
  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
  max-width: 100% !important;
}

.blockNo {
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  @media (--viewportMedium) {
    margin-right: 4%;
    flex-basis: 20%;
    margin-bottom: 32px;
  }
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.submitWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  font-weight: bold;
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 65px;
    display: inline-block;
    width: 280px;
    height: 65px;
    margin-top: 40px;
  }
}

.thankYouTextFirstTime {
  font-size: 20px;
  color: var(--marketplaceColorDark);
  margin-bottom: 25px;
  padding-bottom: 30px;

  @media (--viewportMedium) {
    font-size: 27px;
    padding-bottom: 65px;
    margin-bottom: 40px;
  }
}

.inputField {
  margin-bottom: 30px;
}

.uploadBoxWrapper {
  display: flex;
}

.photoTip {
  font-size: 14px;
  color: var(--marketplaceColorDark);
  font-weight: normal;
  margin-top: 0px;
  line-height: normal;
  text-align: left;

  @media (--viewportMedium) {
    font-size: 18px;
    margin-bottom: 40px;
    text-align: left;
  }
}

.photoIdentify {
  width: 156px;
  height: 156px;
  background-color: var(--matterColorAnti);
  margin-right: 0;

  @media (--viewportMedium) {
    margin-right: 25px;
  }
}

.frontalPhoto {
  width: 156px;
  height: 156px;
}

.bottomField {
  margin-top: 20px;
  margin-bottom: 0px !important;
  justify-content: flex-start;

  @media (--viewportMedium) {
    margin-top: 20px;
    justify-content: flex-start;
  }
}

.readMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorNear);
  margin-bottom: 0px;
}
