@import '../../marketplace.css';

.container {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  flex-basis: 50%;
  min-height: auto;
  height: auto;
  padding: 100px 30px 53px;
}

.title {
  font-size: 24px;
  line-height: 29px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
}

.description {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
  margin-top: 5px;
  margin-block: 20px;
  color: var(--marketplaceColorDark);
}

.listYourCar {
  @apply --marketplaceButtonStylesPrimary;
  margin-top: 20px;
  background-color: var(--marketplaceSecondaryColor);
  border-radius: 6px;
  color: var(--marketplaceColorDark);
}

.error {
  font-size: 14px;
  line-height: 20px;
  color: var(--failColor);
}



.alphabetListing{
  counter-reset: list;
  padding: 0px;
}
.alphabetListing > li{
  list-style: none;
  position: relative;
  padding-left: 40px;
  margin-bottom: 7px;
}

.alphabetListing > li{
  list-style: none;
  position: relative;
  padding-left: 40px;
  margin-bottom: 7px;
}

.alphabetListing > li > ul > li{
  margin-top: 4px;
}

.alphabetListing > li::before{
  counter-increment: list;
  content: "(" counter(list, lower-alpha) ") ";
  position: absolute;
  left: 0;
  font-weight: 500;
}
.romanListing{
  counter-reset: list;
}
.romanListing > li{
  list-style: none;
  position: relative;
  padding-left: 40px;
  margin-bottom: 7px;
}
.romanListing > li::before{
  counter-increment: list;
  content: "(" counter(list, lower-roman) ") ";
  position: absolute;
  left: 0;
  font-weight: 700;
}
.padLeft{
  padding-left: 20px;
}


/* .buttonContainer {
  display: flex;
  justify-content: space-between;
} */

.doNotCancelButton {
  background-color: var(--matterColorLight);
  border: 1pt solid var(--marketplaceColorDark);
  color: var(--marketplaceColorDark);
  text-transform: capitalize;
  flex-basis: 45%;
  font-size: 20px;
  line-height: 24px;
  border-radius: 4 px;

  &:hover {
    color: var(--matterColorLight);
  }
}

.cancelButton {
  /* margin-left: 20px; */
  flex-basis: 45%;
  text-transform: unset;
  font-size: 20px;
  line-height: 24px;
  border-radius: 4 px;
}

.toggleMapButton {
  bottom: 0;
  right: 60px;
  font-size: 20px;
  color: #026786;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: flex-end; */
}

.toggleMapButton span {
  display: block;
  padding-left: 10px;
}

.toggleCss {
  margin-right: 4px;
}