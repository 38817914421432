@import '../../marketplace.css';

.root {
  
  /* @media (--viewportLarge) {
    max-width: 550px;
  } */
}

.inputField {
  margin-bottom: 30px;
}

.readMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorNear);
  margin-bottom: 0px;
}

.learnMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColor);
}

.previewFile {
  margin-left: 0px !important;
  margin-right: 20px;
}

.tripPageForm{
  display: block;
  max-width: 100%;
}

.row{
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.column50{
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 10px;
  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.drivingLicenseUploadButtons label{
  
}
.drivingLicenseUploadButtons{
  border: 1px solid #E6E6E6;
  background: #FCFCFC;
  border-radius: 2px;
  padding: 10px;
}
.tripPageUploadButtonRoot > label{
  display: block;
  font-size: 18px;
  line-height: normal;
  color: #026786;
  font-weight: 500;
  padding: 0;
  margin: 0 0 10px 0;
}
.tripPageUploadButtonRoot > div{
  border-radius: 4px;
  border: 1px dashed #026786;
}
/* .tripPageUploadButtonRoot label{
  display: none;
} */
.tripPageUploadButtonRoot label + div, .tripPageUploadButtonRoot label + div > div {
  display: block;
  margin: 0 !important;
  max-width: 100%;
}
.tripPageUploadButtonRoot label + div > div{
  min-height: 100px;
  position: relative;
}
.tripPageUploadButtonRoot label + div > div > img{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.tripPageUploadButtonRoot .previewFile{
  width: 100%;
  height: 100%;
  min-height: 100px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .tripPageUploadButton > label{
  display: none;
} */
.profileUploadButtons label{
  display: block;
  font-size: 18px;
  color: #026786;
  font-weight: 500;
  padding: 0;
  margin: 0 0 10px 0;
}
.profileUploadButtons{
  border: 1px solid #E6E6E6;
  background: #FCFCFC;
  border-radius: 2px;
  padding: 10px;
}
.profileUploadInputOuter{
  display: flex;
  align-items: flex-start;
}
.profileUploadInputOuter .tripPageUploadButtonRoot{
  max-width: 100px;
  height: 100px;
}
.profileUploadInputOuter p{
  font-size: 14px;
  color: #4A4A4A;
  padding-left: 10px;
  margin: 0;
  line-height: 20px;
  font-weight: 500;
}
.profileUploadButtons .profileUploadInput > label{
  margin: 0;
}

.sectionTitle{
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  margin: 0 0 11px;
  color: #026786;
}