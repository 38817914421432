@import '../../marketplace.css';

p {
  font-weight: 100 !important;
}

a:hover {
  text-decoration: none !important;
}

.backgroundImage{
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/backgroundImg.svg');

  @media (--viewportMobile){
    background-position-x: 60%;
    background-position-y: top;
  }
}

.landingContainer {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  @media (--viewportMobile) {
    padding: 0 20px;
  }
}

.landingRow {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-wrap: wrap;
  @media (--viewportMobile) {
    flex-direction: column-reverse;
  }
}

.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.column12{
  flex: 0 0 100%;
  max-width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

/* Branding */

.row svg {
  margin-right: 10px;
  margin-top: 5px;
}

.BrandHero{
  background-image: #ffffff;
  min-height: 235px;
  display: flex;
  flex-direction: column;
  padding: 70px 0 100px;
  @media (--viewportMedium) {
    background-position: center;
    background-size: 100%;
  }
  @media (max-width: 767px) {
    padding: 30px 0;
    padding-bottom: 0;
  }

  @media (--viewportLarge) {
    background-attachment: fixed;
    background-size: cover;
  }
}

.flexCol{
  display: flex;
  justify-content: flex-start;
}

.rightCol{
  display: flex;
  justify-content: flex-end;
}

.banner_dekstop{
  display: none;
  @media (--viewportMedium){
    display: block;
  }
}
.banner_mobile{
  display: block;
  max-width: 336px;
  margin: 0 auto 30px;
  @media (--viewportMedium){
    display: none;
  }
}

.heroBrandingTitle {
  font-size: 48px;
  line-height: normal;
  color: #026786;
  align-self: flex-end;
  margin: 0 0 20px;
  font-weight: 900;
  max-width: 500px;
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
    margin: 5px 0 20px;
  }
}

.heroTitleDesk{
  display: none;
  @media (--viewportMedium){
    display:block;
  }
}

.heroTitleMobile{
  display: block;
  @media (--viewportMedium){
    display:none;
  }
}

.flexCol .bannerContent p{
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  @media (--viewportMobile){
    font-size: 20px;
    line-height: 26px;
  }
}

.landingRow .bannerContent {
  margin-right: 10px;
  @media (--viewportMobile) {
    margin: 0;
  }
}

.landingRow .bannerContent p {
  font-size: 24px;
  line-height: 28px;
  max-width: 500px;
  margin: 0 0 30px;
  @media (--viewportMobile) {
    max-width: 100%;
    font-size: 20px;
  }
}

.calcTargetButton {
  font-size: 22px;
  color: #0aa3ad;
  font-weight: 700;
  display: inline-block;
  padding: 7px 0;
  border-bottom: 2px solid #0aa3ad;
  cursor: pointer;
  margin-top: 20px;
  @media (--viewportMobile) {
    margin-bottom: 30px;
    font-size: 17px;
  }
}

.calcTargetButton span {
  display: inline-block;
  width: 11px;
  height: 20px;
  margin-left: 10px;
  vertical-align: middle;
  @media (--viewportMobile) {
    height: 11px;
    margin-left: 5px;
  }
}

.calcTargetButton span img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.heroContentBrandText {
  font-size: 24px;
  line-height: 28px;
  font-weight: normal;
  color: #4a4a4a;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.flexCol .bannerContent button{
  max-width:380px;
  line-height: 60px;
  background:#FECD2A;
  color:#0D6786;
  font-size: 24px;
  text-transform: initial;
  margin-top: 30px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
  @media (--viewportMobile){
    max-width:335px;
    font-size:19px;
    margin-top: 20px;
  }
}

.flexCol .bannerContent button:hover,
.flexCol .bannerContent button:focus{
  background:#FECD2A;
  color:#0D6786;
}

/* Banner channel */

.channelOuter {
  padding:0 15px 22px;
  max-width: 1240px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 30px 15px 60px;
  }
}

.channelsGridRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: 767px) {
    padding: 0 15px;
    justify-content: space-between;
  }
}

.channelItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: pointer;
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  &:nth-child(3),
  &:nth-child(4) {
    margin-top: 20px;
  }
  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
  @media (max-width: 767px) {
    height: 40px;
    padding: 0 5px;
    flex: 2;
  }
}

.channelItemImage {
  width: auto;
  height: auto;
  max-width: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

/* Guarantee */

.guaranteeContainer {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/guaranteeBg.svg');
  background-position: left bottom;
  margin-top: 70px !important;
  @media (--viewportLMLarge) {
    margin-top: 185px !important;
  }

  @media (--viewportMobile) {
    background-image: none;
    margin-top: 0 !important;
  }
}

.guaranteeContainer .row {
  display: flex;
  flex-direction: column;
  @media (--viewportLMLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.guaranteeContainer .contentTitle{
  width: 100%;
  @media (--viewportLMLarge) {
    width:37%;
  }
}

.guaranteeContainer .contentTitle h2{
  color: #0D6786;
  font-size: 48px;
  line-height: 57px;
  max-width: 100%;
  font-weight: 900;
  margin-top: 0;
  @media (--viewportLMLarge) {
    max-width: 420px;
  }
  @media (--viewportMobile) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom:10px;
  }
}

.guaranteeContainer .contentTitle p{
  color:#0D6786;
  font-size: 48px;
  line-height: 57px;
  @media (--viewportMobile) {
    font-size: 32px;
    line-height: 40px;
    margin:0 0 35px;
  }
}

.guaranteeContainer .cards {
  width: 100%;
  @media (--viewportLMLarge) {
    width:63%;
  }
}

.guaranteeContainer .cardContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (--viewportLMLarge) {
    justify-content: flex-start;
  }
}

.guaranteeContainer .cardContent .card {
  flex: 0 0 50%;
  align-self: flex-start;
  max-width: 344px;
  padding: 45px 25px;
  background: var(--matterColorLight);
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  -webkit-box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  -moz-box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  margin: 15px;
  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 550px;
    margin: 10px;
  }
}

.guaranteeContainer .cardContent .card:first-child {
  @media (--viewportLMLarge) {
    margin-top: -90px;
    margin-bottom: 30px;
  }
}

.guaranteeContainer .cardContent .card:nth-child(3) {
  @media (--viewportLMLarge) {
    margin-top: -90px;
    margin-bottom: 30px;
  }
}

.guaranteeContainer .cardContent .card:nth-child(5) {
  @media (--viewportLMLarge) {
    margin-top: -210px;
  }
}

.guaranteeContainer .cardContent .card .cardTitle {
  color: #026786;
  font-size: 24px;
  line-height: 28px;
  margin: 30px 0 10px;
  font-weight: 600;
}

.guaranteeContainer .cardContent .card .cardPara {
  color: #4A4A4A;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.guaranteeContainer .cardContent .card .cardPara .coloredPara{
  color:#18A3AD
}

/* Branding */

.sectionBrandingHero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 60px;
  @media (--viewportMLarge) {
    padding: 0;
  }
}

.sectionBrandingHero .SectionHeroBrandingBg {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120vw;
  background-repeat: no-repeat;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets//landingPageGo/shutterstock_3.png');
  background-size: cover;
  background-position: 50% 100%;
  @media (--viewportMobile) {
    background-position: 80% 0%;
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets//landingPageGo/shutterstock_3@2x.png');
    background-size: cover;
    background-position: bottom;
    height: 80vw;
  }
  @media (--viewportLarge) {
    width: 100%;
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets//landingPageGo/shutterstock_3@3x.png');
    height: 70vw;
  }
  @media (--viewportXLarge) {
    background-size: 100% auto;
    height: 50vw;
  }
  @media (--viewportXXLarge) {
    height: 48vw;
  }
}

.sectionBrandingHero .BrandingTitle {
  font-size: 30px;
  line-height: normal;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightBold);
  margin: 0;
  color: var(--matterColorLight);
  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 76px;
    margin: 0;
  }
  @media (--viewportMobile) {
    text-align: left;
    width: 100%;
  }
}

.sectionBrandingHero .brandingDescription {
  font-size: 16px;
  line-height: 22px;
  font-weight: var(--fontWeightNormal);
  margin-top: 20px;
  text-align: center;
  color: var(--matterColorLight);
  font-weight: 100;
  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (--viewportMobile) {
    text-align: left;
    width: 100%;
  }
}

.sectionBrandingHero .sectionHeroDescription {
  @media (--viewportMedium) {
    padding: 0 48px 0;
    max-width: 520px;
  }
  @media (--viewportLarge) {
    padding: 0;
    max-width: 590px;
  }
}

.sectionBrandingHero .brandingButton {
  margin-top: 10px;
  background: transparent;
  color: #FECD2F;
  font-size: 22px;
  line-height: 28px;
  font-weight: 100;
  margin-bottom: 110px;
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    margin-bottom: 60px;
    font-size: 18px;
    text-align: left;
    width: 100%
  }
}

.sectionBrandingHero .brandingButton svg {
  transform: rotate(-90deg);
  margin-left: 10px;
}

.sectionBrandingHero .cards {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    margin-top: -220px;
  }
  @media (--viewportMobile) {
    margin: -88px 20px 0;
  }
  @media (--viewportMLarge) {
    justify-content: space-between;
    margin-bottom: 145px;
    flex-direction: row;
  }
}

.sectionBrandingHero .cards .card {
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 30px 35px;
  margin: 0 0 20px;
  box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  -webkit-box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  -moz-box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  @media (--viewportMLarge) {
    margin: 0 22px;
  }
  @media (--viewportMedium) {
    max-width: 575px;
    padding: 65px 35px 45px;
  }
  @media (--viewportMobile) {
    max-width: 550px;
    align-items: flex-start;
    padding:30px 15px 35px
  }
}

.sectionBrandingHero .cards .card .content .contentTitle {
  color: #0D6786;
  font-size: 34px;
  text-align: center;
  margin: 50px 0 20px;
  line-height: 35px;
  font-weight: 900;
  @media (--viewportMobile) {
    margin: 35px 0 15px;
    font-size: 30px;
    text-align: left;
  }
}

.sectionBrandingHero .cards .card .content .contentPara {
  color: #0D6786;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  font-weight: 100;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
}

/* Calculator */

.col6:first-child {
  padding-left: 10px;
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.col6:nth-child(2) {
  padding-right: 10px;
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.heroSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/hostLanding/calculator-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 68px 0;
  @media (max-width: 767px) {
    padding: 0;
  }
  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/hostLanding/calculator-bg.png');
    background-image: image-set( url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/hostLanding/calculator-bg.png') 1x, url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/hostLanding/calculator-bg@2x.jpg') 2x);
    flex-direction: row;
  }
  @media (--viewportMobile) {
    background-position: 85% 0%;
    background-size: initial;
  }
}

.mobileHero {
  display: block;
  height: 150px;
  width: 100%;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/becomeAHost/become-a-host-bg-min.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (--viewportMedium) {
    display: none;
  }
}

.searchBox {
  border-radius: 5px;
  background-color: rgb(10 163 173 / 90%);
  padding: 30px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  @media (--viewportMedium) {
    max-width: 600px;
    width: 100%;
    padding: 40px;
  }
  @media (max-width: 767px) {
    padding: 30px 20px;
    border-radius: 0;
    margin: 0 -15px;
    box-shadow: none;
  }
  @media (--viewportMobile) {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: 120px;
    margin-right: -20px;
    margin-left: -20px;
  }
}

.searchBoxTitle {
  font-size: 34px;
  line-height: normal;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

.searchBoxDesc {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColor);
  margin-bottom: 20px;
  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 24px;
  }
}

.fieldsWrapper {
  width: 100%;
}

.inputField {
  position: relative;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/arrow-down-white.svg');
  background-position: 98%;
  background-position-y: center;
  background-size: 16px 16px;
}

.inputField input+div {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}

.inputField:focus {
  outline: none !important;
}

.inputField label {
  position: absolute;
  padding: 5px 10px;
  left: 0;
  top: 0;
  font-size: 12px;
  color: #ffffff;
  font-weight: 100;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.yearField {
  flex-basis: 100%;
  @media (--viewportMedium) {
    flex-basis: 45%;
  }
}

.milesField {
  flex-basis: 50%;
  @media (--viewportMedium) {
    flex-basis: 45%;
  }
}

.input, .selectField {
  height: 60px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 20px 10px 3px;
  font-size: 18px;
  color: #ffffff;
  border: 0;
  margin-bottom: 20px;
  font-weight: 500;
  &::placeholder {
    color: #FFFFFF;
    font-weight: 500;
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.input+div {
  position: relative;
}

.input:hover, .input:focus, .selectField:hover, .selectField:focus {
  border: 0 !important;
  outline: none !important;
}

.footerBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  @media (max-width: 767px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.perValue {
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: -17px;
  font-weight: 300;
}

.valueInfo {
  flex: 0 0 100%;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 5px;
}

.breakDown {
  font-size: 36px;
  height: 40px;
  font-weight: 900;
  color: #FECD2A;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &::after {
    content: '/ month';
    font-weight: 100;
    position: static;
    font-size: 16px;
    line-height: 20px;
    bottom: -20px;
    display: inline-block;
    width: auto;
    min-width: 75px;
    display: none;
  }
  @media (max-width: 767px) {
    position: static;
    margin: 20px 0;
    height: auto;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (--viewportMedium) {
    font-size: 36px;
    margin-bottom: 0px;
    &::after {
      left: 0;
    }
  }
}

.listYourCar {
  @apply --marketplaceButtonStyles;
  border-radius: 5px;
  padding: 7px 15px;
  display: inline-flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-transform: none;
  max-width: 300px;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}

.explaination {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: var(--matterColorAnti);
  cursor: pointer;
  &::before {
    content: '?';
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    font-weight: bold;
    left: 0;
    border-radius: 50%;
    border: 1px solid var(--matterColorAnti);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.explaination:hover .explainationText {
  display: block;
}

.explainationText {
  display: none;
  position: absolute;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  padding: 15px 25px;
  left: -112px;
  bottom: 18px;
  margin-bottom: 25px;
  font-weight: normal;
  width: 250px;
  box-shadow: 3px 3px 10px var(--matterColorNegative);
  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}

/* car sharing */

.sectionBrandHero {
  padding: 0;
  @media (--viewportMobile) {
    background-image: linear-gradient(#18A3AD, #55C7D0);
    padding-bottom: 45px;
    margin-bottom: 40px;
  }
}

.sectionBrandHero .SectionHeroBrandingBg {
  background-image: linear-gradient(#18A3AD, #55C7D0);
  height: 390px;
  @media (--viewportMobile) {
    background-image: none;
  }
}

.sectionBrandHero .BrandingTitle {
  font-size: 48px;
  line-height: 56px;
  color: var(--matterColorLight);
  padding: 55px 0 60px;
  text-align: center;
  font-weight: 900;
  @media (--viewportMobile) {
    font-size: 35px;
    line-height: 40px;
    text-align: left;
    padding: 40px 20px 35px;
  }
}

.sectionBrandHero .cards {
  display: flex;
  justify-content: center;
  margin-top: -195px;
  margin-bottom: 130px;
  flex-direction: column;
  @media (--viewportMobile) {
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    margin-bottom: 10px;
  }
  @media (--viewportMLarge) {
    flex-direction: row;
  }
}

.sectionBrandHero .cards .card {
  position: relative;
  background: var(--matterColorLight);
  max-width: 365px;
  margin: 0 auto 60px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  -webkit-box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  -moz-box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 70px 35px 35px;
  @media (--viewportMLarge) {
    margin: 0 25px;
  }
  @media (--viewportMobile) {
    max-width: 550px;
    padding: 70px 15px 30px;
  }
}

.sectionBrandHero .cards .card:last-child {
  margin-bottom: 0;
}

.sectionBrandHero .cards .card .stepWrapper {
  position: absolute;
  background: var(--matterColorLight);
  height: 84px;
  width: 84px;
  border-radius: 100%;
  top: -43px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  -webkit-box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
  -moz-box-shadow: 0px 3px 20px 0px rgba(24, 163, 173, 0.20);
}

.sectionBrandHero .cards .card .stepWrapper .step {
  height: 74px;
  width: 76px;
  border-radius: 100%;
  top: -43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #18A3AD;
  font-size: 30px;
  font-weight: 900;
  color: #18A3AD;
}

.sectionBrandHero .cards .card .cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (--viewportMobile) {
    align-items: baseline;
  }
}

.sectionBrandHero .cards .card .cardTitle {
  /* text-align: center; */
  color: #0D6786;
  font-size: 30px;
  margin: 35px 0 15px;
  font-weight: 900;
  line-height: 35px;
}

.sectionBrandHero .cards .card .cardPara {
  text-align: center;
  color: #0D6786;
  font-size: 22px;
  line-height: 26px;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
}

/* Help section */

.sectionHelpBLanding {
  padding: 75px 30px 50px;
}

.helpQuestionsBLanding {
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 100px;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 50px;
  }
}

.sectionHelpTitleBLanding {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  white-space: pre-wrap;
  @media (--viewportLarge) {
    white-space: nowrap;
    text-align: center;
    font-size: 60px;
    line-height: 72px;
  }
}

.questionBLanding {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  padding-bottom: 20px;
  border-bottom: solid 1px var(--matterColorNegative);
  margin-top: 20px;
  letter-spacing: -0.4px;
  user-select: none;
  max-width: 500px;
}

.answerBLanding {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  display: none;
  white-space: pre-wrap;
  margin-top: 20px;
  user-select: none;
  max-width: 500px;
}

.showAnswerBLanding {
  display: block;
}

.searchHelpBLanding {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--marketplaceColor);
  padding-bottom: 20px;
  margin-top: 30px;
  @media (--viewportLarge) {
    max-width: 570px;
    width: 570px;
    margin: 30px auto 0;
    padding-left: 102px;
  }
}

.searchHelpBLanding input::placeholder {
  color: #026786;
  opacity: 20%;
}

.searchIconBLanding {
  width: 16px;
  height: 16px;
}

.searchHelpInputBLanding {
  border: none;
  margin-left: 10px;
  color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 30px;
  @media (--viewportLarge) {
    font-size: 32px;
    line-height: 32px;
  }
}

/* Banner stories */
  
.sectionTestimonials {
  /* padding-top: 40px; */
  padding-bottom: 25px;
  padding-top: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
}

.sectionTestimonialsDescription {
  display: none;

  @media (--viewportLarge) {
    color: var(--marketplaceColorDark);
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    display: block;
  }
}

.sectionTestimonialsTitleWrapper {
  padding: 0 30px;

  & > .sectionTestimonialsTitle {
    white-space: pre-wrap;
    margin-bottom: 10px;
  }

  @media (--viewportLarge) {
    text-align: center;
    margin: 0 auto;
    & > .sectionTestimonialsTitle {
      white-space: nowrap;
    }
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.sectionTestimonialsTitle {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  @media (--viewportLarge) {
    text-align: center;
  }

  @media (--viewportMLarge) {
    font-size: 48px;
    line-height: normal;
    text-align: center;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

.sliderWrapper {
  margin-top: 50px;
  margin: 50px auto 0;
  padding: 0 50px;
  max-width: 1240px;
  width: 100%;
  @media (--viewportLarge) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    max-width: 100%;
    margin: 0;
    margin-top: 20px;
  }
}
/* Banner stories Ends */
