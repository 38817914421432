@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  color: var(--matterColorDark);
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.submitButton {
  @apply --marketplaceButtonStylesPrimary;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.invisible {
  visibility: hidden;
}

.field {
  /* margin-top: 15px; */
}

.phoneNumberWrapper {
  display: flex;
  align-items: center;
}

.phonenumberLabel {
  margin-top: 15px;
}

.phoneCode {
  flex: 1;
  margin-right: 20px;
}

.phoneNumber {
  flex: 6;
}
