@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;
}

.root {
  padding: 0;
}

/* Dummy Topbar */

.topbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--marketplaceColor);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
    justify-content: center;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;

  & path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.hideElementDesktop {
  display: none !important;
  @media (--viewportMobile) {
    display: flex !important;
  }
}



.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  max-width: 1230px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 150px;
  @media (--viewportMobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-right: 0;
  }
}

.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  @media (--viewportLarge) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  /* display: block; */
  display: none;

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}

.avatarWrapperLeft {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  /* display: block; */
  display: none;

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}


.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  margin-top: 29px;
  margin-bottom: 34px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 27px;
    margin-bottom: 30px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 54px;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}
.duetitle {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}
.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceBreakdownContainer {
  padding: 10px 24px;
  margin-bottom: 37px;
  border: 1px solid #e6e6e6;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 7px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
    display: none;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownContainerModify {
  margin-left: 10px;
  margin-right: 10px;

  @media (--viewportMedium) {
    display: none;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }

}

.priceBreakdownContainer h3 {
  color: #026786;;
}

.cancellationDepositContainer {
  padding: 10px 24px;
  margin-bottom: 37px;
  border: 1px solid #e6e6e6;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 7px;

  @media (--viewportMedium) {
    display: none;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.cancellationDepositContainer h3 {
  color: #026786;;
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 35px;
  border: 1px solid #e6e6e6;
  border-radius: 7px;

  @media (--viewportMedium) {
    /* margin-top: 2px; */
    margin-top: 0;
  }

  @media (--viewportMobile) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
    padding: 0;
  }

  @media (--viewportMobile) {
    padding: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  color: var(--failColor);
}
.notFoundError {
  color: var(--attentionColor);
}

.speculateError {
  color: var(--failColor);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}

.paymentForm {
  flex-grow: 1;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  background-color: var(--matterColorLight);
  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 100%;
    padding-bottom: 0;

    /* Position */
    margin: 0;

    /* Coloring */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 4px;
  }
}

.detailsContainerDesktopCancellation {
  background-color: var(--matterColorLight);
  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 100%;
    padding-bottom: 0;

    /* Position */
    margin: 0;
    margin-top: 20px;

    /* Coloring */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 4px;
  }
}

.detailsContainerTop {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e6e6e6;
  @media (--viewportMobile) {
    padding: 0 0 10px;
    border: 0;
  }
}

.detailsContainerLeft {
  border: 1px solid #e6e6e6;
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  border-radius: 7px;
  background: white;
  align-items: flex-start;
  @media (--viewportMobile) {
    margin-left: 10px;
    margin-right: 10px
  }
}

.hideElementDesktop {

}

.detailsAddonContainerMain {
  border: 1px solid #e6e6e6;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 7px;
  background: white;
  @media (--viewportMobile) {
    margin-left: 10px;
    margin-right: 10px
  }
}


.detailsAddonContainerMain h3 {
  margin: 0;
  color: #026786;
  margin-bottom: 20px;
  @media (--viewportMobile) {
    margin-bottom: 10px;
  }
}

.addonBreakdown {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.addonLineItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 6px;
  padding-bottom: 2px;
  /* font-weight: 500;
  font-size: 16px;
  line-height: 24px; */
  letter-spacing: 0;
}
.distanceLineItem {
  padding-top: 6px;
  padding-bottom: 2px;
  /* font-weight: 500;
  font-size: 16px;
  line-height: 24px; */
  letter-spacing: 0;
}
.addonLineItemLabel {
  font-family: 'sofiapro', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  flex: 1 1;
  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 24px;
  }
}
.distanceLineItemLabel {
  display: flex;
  font-family: 'sofiapro', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin:10px 0 0 0;

  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 24px;
  }
}
.fuelIcon{
  margin: 0 5px 0 0;
}
.addonLineItemValue
{
  font-weight: 500;
  margin-left: 10px;
  font-size: 18px;
  @media (--viewportMobile) {
    font-size: 15px;
  }
}

.editSingleAddon {
  font-size: 18px;
  color: #00a3ad;
  cursor: pointer;
  position: relative;
  @media (--viewportMobile) {
    font-size: 14px;
  }
}
.editSingleAddon::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #00a3ad;
  left: -2px;
}

.detailsAspectWrapper {
  position: relative;
  /* padding-bottom: 66.6667%; 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
  flex: 1;
  max-width: 88px;
  padding-bottom: 88px;
  border-radius: 4px;
  overflow: hidden;
  @media (--viewportMobile) {
    max-width: 71px;
    padding-bottom: 71px;
  }
}

.detailsLeftAspectWrapper {

  position: relative;
  /* padding-bottom: 66.6667%; 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
  flex: 1;
  max-height: 88px;
  max-width: 88px;
  padding-bottom: 88px;
  border-radius: 4px;
  overflow: hidden;
  @media (--viewportMobile) {
    max-width: 71px;
    padding-bottom: 71px;
  }
}

.detailsAspectWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.detailsHeadings {
  margin: 0;
  flex: 1;
  padding: 0 0 0 10px;
}

.detailsLeftAspectWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.detailsHeadings {
  margin: 0;
  flex: 1;
  padding: 0 0 0 10px;
}

.detailsTitle {
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 1.333;
  color: #026786;
  @media (--viewportMobile) {
    margin: 0 0 5px;
    font-size: 20px;
    line-height: normal;
  }
}

.detailsSubtitle {
  @apply --marketplaceH5FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  /* Layout spacing */
  margin: 5px 24px 25px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
  }
}

.bookingBreakdown {
  @media (--viewportLarge) {
    margin: 0 48px;
  }
}

.securityPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: left;
  margin: 20px 0px 0px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin: 25px 0px 20px;
  }

  @media (--viewportLarge) {
    margin: 25px 48px 21px;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 10px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media (--viewportLarge) {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.cancellationPolicy {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 21px;
  }
}

.insuranceWrapper {
  margin: 20px 0px 0px;

  @media (--viewportMedium) {
    margin: 25px 0px 20px;
  }

  @media (--viewportLarge) {
    margin: 4px 48px 21px;
  }

  @media (--viewportMobile) {
    margin: 0;
  }

}

.dateTimeWrapper input {
  flex-basis: 46%;
  margin-bottom: 20px;
}

.dateTimeWrapper label {
  flex-basis: 46%;
  color: var(--matterColorAnti);
}

.loading {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirmPopup {
  margin-bottom: -100px;
  & .message {
    margin: 1rem 0;
  }

  & .buttonWrapper {
    width: 100%;
    display: flex;
    & .buttonConfirmLeave {
      margin: 0 20px;
    }
  }
}

.priceBreakdownLongTermTitle {
  /* Font */
  color: var(--marketplaceColor);
  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
  @media (--viewportLarge) {
    margin: 0 48px;
  }
}

.contentRow {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @media (--viewportMobile) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.checkoutPageTitles {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 33px 15px 0;
  margin-bottom: 30px;
  @media (--viewportMobile) {
    padding: 30px 20px 0;
    margin-bottom: 20px;
  }
}
.checkoutPageTitles .checkoutBreadcrumbs {
  color: #4a4a4a;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
  font-weight: 900;
  letter-spacing: 2.8px;
  @media (--viewportMobile) {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.checkoutPageTitles .stepTitle {
  color: #026786;
  font-size: 36px;
  line-height: 1;
  font-weight: 900;
  @media (--viewportMobile) {
    font-size: 28px;
    line-height: normal;
    margin-bottom: 10px;
  }
}
.checkoutPageTitles p {
  color: #b2b2b2;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 300;
  max-width: 750px;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.checkoutSectionLeft {
  flex: 0 0 60%;
  max-width: 60%;
  padding-left: 15px;
  padding-right: 15px;
  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.checkoutSectionRight {
  flex: 0 0 40%;
  max-width: 40%;
  padding-left: 15px;
  padding-right: 15px;
  @media (--viewportMobile) {
    display:none;
    flex: 0 0 100%;
    max-width: 100%;
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    /* margin-top: -30px; */
  }
}
.bookingPeriodTime {
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
  font-weight: 500;
}
.detailsContainerMain {
  padding: 20px;
  @media (--viewportMobile) {
    padding: 20px 0;
  }
}

.cancellationContainerMain {
  padding: 10px 10px;
}

.cancellationContainerText {
  padding: 10px 20px;
}


.detailsContainerMain .bookingBreakdown {
  margin: 0;
}
.detailsContainerMain h3 {
  margin: 0;
  color: #026786;
  font-weight: 500;
  margin-bottom: 20px;
  @media (--viewportMobile) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.submitSection {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  background: #ffffff;
  z-index: 99;
}
.submitSectionInner {
  max-width: 1230px;
  margin: 0 auto;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submitSectionPrice {
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: #b2b2b2;
}
.submitSectionPrice .totalAmount {
  text-align: center;
  display: block;
  font-size: 28px;
  line-height: normal;
  color: #00a3ad;
  font-weight: 900;
}
.submitSectionPrice button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 300px;
  font-size: 20px;
  line-height: 1;
  color: #026786;
  border-radius: 4px;
  background-color: #ffcd05;
  text-transform: capitalize;
}
.submitSectionPrice button:hover {
  opacity: 0.85;
  background-color: #ffcd05;
}
.cardOuter {
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  /* margin-bottom: 30px; */
  padding: 20px;
  position: relative;
  margin-top: 30px;
}
.cardOuter .cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardOuter .cardTitle .title {
  font-size: 20px;
  line-height: normal;
  color: #026786;
  margin: 0;
}
.cardOuter .cardTitle h3 {
  margin: 0;
}
.cardOuter .cardTitle button {
  font-size: 16px;
  line-height: normal;
  color: #026786;
  border: 0;
  appearance: none;
  cursor: pointer;
}
.addOnList {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addOnList .addOnListName {
  font-size: 16px;
  line-height: normal;
  color: #4a4a4a;
}
.addOnList .addOnListPrice {
  font-size: 20px;
  line-height: normal;
  color: #4a4a4a;
}

.cancelText {
  display: flex;
  align-items: center;
}

.cancelText img {
  flex: 0 0 auto; /* Prevent the image from growing or shrinking */
  margin-right: 10px; /* Adjust the spacing between the image and text */
  height: 18px;
  width: 18px;
}

.cancelText p {
  flex: 1; /* Allow the paragraph to grow and fill the remaining space */
  font-size: 16px;
  color: #4A4A4A;
  line-height: normal;
  margin: 0;
}

.cancelContainer {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e6e6e6;
  @media (--viewportMobile) {
    padding: 10px 24px;
    margin-bottom: 10px;
    border: 1px solid #e6e6e6;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 7px;
  }
}

.errorForFailedTransaction {
  border: solid 2px #E6E6E6;
  border-radius: 4px;
  background-color: #FFFFFF;
  padding: 20px;
  margin-bottom: 40px;
  @media (--viewportMobile) {
    border: solid 1px #E6E6E6;
    margin: auto;
    width: 95%;
    margin-bottom: 40px;
  }
}

.errorForFailedTransaction > p:nth-child(1) {
  /* border: solid 2px red; */
  margin: 0;
  margin-bottom: 20px;
  color: #026786;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.11px;
  font-weight: 700 !important;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.11px;
    font-weight: 700 !important;
  }
}

.errorForFailedTransaction > p:nth-child(2) {
  border: dashed 1px #D91320;
  background-color: #FDF3F3;
  margin: 0;
  border-radius: 5px;
  padding: 10px;
}

.errorForFailedTransaction > p:nth-child(2) > p:nth-child(1) {
  /* border: solid 2px blue; */
  margin: 0;
  margin-bottom: 10px;
  color: #026786;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500 !important;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.11px;
  }
}

.errorForFailedTransaction > p:nth-child(2) > p:nth-child(2) {
  /* border: solid 2px green; */
  margin: 0;
  color: #4A4A4A;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500 !important;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.11px;
  }
}

.afterTripDistanceChargeDetails{
  /* border: solid 2px black; */
  border: 1px solid #e6e6e6;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 7px;
  background: white;
  @media (--viewportMobile) {
    margin-left: 10px;
    margin-right: 10px
  }
}

.afterTripDistanceChargeDetails > h3{
  /* border: solid 2px black; */
  margin: 0;
  margin-bottom: 14px;
  color: #026786;
}

.afterTripDistanceChargeDetails .distanceLineItemLabel{
  /* border: solid 2px black; */
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
}

.distanceLineItemLabel > .fuelIcon{
  /* border: solid 2px black; */
  margin-right: 10px;
}

.distanceLineItemLabel > p{
  /* border: solid 2px black; */
  font-size: 16px;
  line-height: 24px;
  font-weight: 500 !important;
  margin: 0;
}

.distanceLineItemLabel > p > span{
  /* border: solid 2px black; */
  display: inline-block;
  color: #7C7C7C;
  font-size: 14px;
  line-height: 16px;
}

