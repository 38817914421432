.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);
  position: static;
  top: 0;
  left: 0;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: transparent;

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

.topNav {
  /* border: solid 2px black; */
  background-color: #00a3ad;
}

.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 21px 24px 21px 24px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 21px 36px 21px 36px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

.outerContainer {
  /* border: solid 2px black; */
  width: 80%;
  margin: auto;
}

.outerContainer>p {
  /* border: solid 2px blue; */
  font-size: 36px;
  line-height: 56px;
  font-weight: 900 !important;
  color: #026786;

  @media (--viewportMobile) {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700 !important;
  }
}

.verificationProcessContainer {
  margin: 0.5%;
  padding: 1%;
}

.verificationProcessContainer>p {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500 !important;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
  }
}

.verificationFlowContainer {
  background-color: #FFFFFF;
  border: solid 1px #E6E6E6;
  border-radius: 4px;
  padding: 2%;
}

.verificationFlowContainer>div:first-child>p {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500 !important;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
  }
}

.listOfVerifiations>p {
  /* border: solid 2px black; */
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 500 !important;

  @media (--viewportMobile) {
    font-size: 16px;
    width: 80%;
  }
}

.listOfVerifiations>p>span:nth-child(2) {
  margin-left: 5%;
}

.listOfVerifiations>p>span:nth-child(3) {
  margin-left: auto;
}

.bottomBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  box-shadow: 0px -3px 6px #00000014;
  background-color: #FFFFFF;
  z-index: 1000;

  @media (--viewportMobile) {
    justify-content: center;
  }
}

.bottomBar>button {
  width: 300px;
  height: 60px;
  background-color: #FFCD05;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 4px;
  color: #026786;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700 !important;
  margin-right: 10%;

  @media (--viewportMobile) {
    margin-right: 0;
    width: 90%;
  }
}
.bottomBar > .bottomButtonClass {
  width: 300px;
  height: 60px;
  background-color: #FFCD05;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 4px;
  color: #026786;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700 !important;
  margin-right: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMobile) {
    margin-right: 0;
    width: 90%;
  }
}