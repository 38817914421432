@import '../../marketplace.css';

:root {
  background: #FFFFFF !important;
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }
  
}

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: 88px;
  position: static;
  top: 0;
  left: 0;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;

  /* fill */
  background-color: #fff;
  border-bottom: 1px solid  #E8E8E8;
  position: fixed;

  /* shadows */
  /* box-shadow: var(--boxShadowLight); */

  @media (--viewportLarge) {
    padding: 16px 80px;
  }

}

/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 10px 24px 10px 24px;
  height: 100%;
  display: flex;
  align-items: center;
  @media (--viewportLarge) {
    padding: 10px 24px 10px 24px;
  }
}

.logoLink img {
  width: 140px;
  @media (--viewportMobile) {
    width: 125px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--marketplaceColor);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.noSearchBox {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;

  @media (min-width: 1024px) {
    padding-left: 0;
  }
}

.topbarSearchWithLeftPadding {
  /* padding-left: 24px; */
  padding-left: 0;
  height: var(--topbarHeightDesktop);
  display: none;

  @media (min-width: 1024px) {
    /* padding-left: 34px; */
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--matterColorBright);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH4FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
  color: var(--matterColorLight);

  &:hover {
    color: var(--matterColorDark);
  }
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

.learnMoreMenuContent {
  min-width: 250px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  left: 0 !important;
  right: unset !important;
}

/* Authentication */
.Link {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.learnMoreLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  font-size: 16px;
  padding: 0 12px 0 12px;

  color: var(--matterColorLight);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.learnMoreLink span {
  display: block;
  margin: 24px 0px;
}

.signup,
.login {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.referralPageLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH4FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}


.signupButtonTopBar {
  width: auto;
  height: auto;
  border: 1px solid #FFCD05;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  gap: 4px;
  font-size: 20px;
  line-height: normal;
  color: #296683;
  border-radius: 40px;
  background: #F8CE47;
  text-decoration: none;
  cursor: pointer;
  margin: 16px 0px;
  font-family: 'Inter', sans-serif !important;
  transition: 0.3s all cubic-bezier(0.18,0.52,0.49,0.85);
  font-weight: 500;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
}
.signupButtonTopBar {
  background: #FFC60F;
  border: 1px solid #ffcd2d;
}
.signupButtonTopBar:hover, .signupButtonTopBar:focus {
  background: #F8CE47;
}