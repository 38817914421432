.customersReviewSection {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 100px 80px;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

@media only screen and (max-width: 767px) {
    .customersReviewSection {
        padding: 50px 16px;
        gap: 24px;
    }
}

.sectionTitle {
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .sectionTitle {
        margin-bottom: 24px;
    }
}

.heading {
    color: #222;
    font-family: 'Inter', sans-serif;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 3.1rem;
    letter-spacing: -2px;
    margin: 0;
    padding: 0;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .heading {
        width: 100%;
        font-size: 1.4rem;
        font-style: normal;
        line-height: 1.9rem;
        letter-spacing: -0.078px;
    }
}

.reviewSlider {
    display: flex;
    width: 100%;
    gap: 30px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 767px) {
    .reviewSlider {
        width: 100%;
        gap: 21px;
    }
}

.reviewCard {
    overflow: hidden;
    min-width: 14.9rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    margin: 0;
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 20px;
}

@media only screen and (max-width: 767px) {
    .reviewCard {
        flex: 0 0 60%;
        width: auto;
        border-radius: 0 0 14px 14px;
        border: 0.7px solid #E8E8E8;
    }
}

.carImage {
    height: 13rem;
    width: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}

@media only screen and (max-width: 767px) {
    .carImage {
        width: 100%;
        height: 9.1rem;
        align-self: stretch;
        border-radius: 14px 14px 0 0;
    }
}

.reviewContent {
    display: flex;
    min-width: 14.9rem;
    width: 100%;
    height: 10.7rem;
    padding: 1.2rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 20px 20px;
    background: #FFF;
}

@media only screen and (max-width: 767px) {
    .reviewContent {
        width: 10.45rem;
        height: 8rem;
        padding: 0.84rem;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}

.reviewContent p {
    margin: 0;
}

.reviewContainer{
    margin: 0;
    padding: 0;
}

.stars {
    display: flex;
}

.star {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
    .star {
        width: 1.24rem;
        height: 1.24rem;
    }
}

.reviewText {
    color: var(--Text-Heading, #222);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 0.9rem;
    font-weight: 500 !important;
    line-height: 1.3rem;
    letter-spacing: -0.078px;
}

@media only screen and (max-width: 767px) {
    .reviewText {
        font-size: 0.6rem;
        font-weight: 400 !important;
        line-height: 1rem;
    }
}

.reviewAuthor {
    color: var(--Primary-Main-Darkest, #026786);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500 !important;
    line-height: 1.2rem;
}

@media only screen and (max-width: 767px) {
    .reviewAuthor {
        font-size: 0.65rem;
        font-weight: 500;
        line-height: 0.8rem;
    }
}