@import '../../marketplace.css';

.switch {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 2px solid #026786;
  -webkit-transition: .3s;
  transition: .3s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border-radius: 2px;
  opacity: 0;
  -webkit-transition: .3s;
  transition: .3s;
}

input:checked + .slider {
  background-color: var(--marketplaceColorDark); 
}

input:checked + .slider:before {
  opacity: 1;
}

input:checked + .slider::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  top: 2px;
  left: 7px;
  transform: rotate(45deg); /* Rotate to create the checkmark shape */
}

.slider.round {
  border-radius: 4px; /* Slightly rounded corners for the checkbox */
}

.slider.round:before {
  border-radius: 2px; /* Slightly rounded corners for the inner box */
}