
.headingContainer {
  /* border: solid 2px blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.separatorHR {
  border: 1px solid #E6E6E6;
  width: calc(100% - 40px);
}

.headingContainer > p {
  /* border: solid 2px red; */
  font-size: 18px;
  color: #026786;
  font-weight: 700 !important;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.headingContainer > p:first-child {
  font-size: 24px;
  @media (--viewportMobile) {
    font-size: 18px;
  }
}

.headingContainer > p:last-child {
  background: #E6E6E6;
  border-radius: 4px;
  padding: 8px;
}

.detailsContainer {
  padding: 0px 20px;
}

.detailsContainer > p {
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 500 !important;
  @media (--viewportMobile) {
    font-size: 14px;
  }
}

.listOfVerifiations > p {
  /* border: solid 2px black; */
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 500 !important;
  @media (--viewportMobile) {
    font-size: 16px;
    width: 80%;
  }
}

.listOfVerifiations > p > span:nth-child(2) {
  margin-left: 5%;
}

.listOfVerifiations > p > span:nth-child(3) {
  margin-left: auto;
}